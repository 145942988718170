import Vue from 'vue'
import App from './selection_order/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const selectionBuilder = document.getElementById('selections-index');

  if (selectionBuilder) {
    new Vue({
      el: '#selections-index',
      render: h => h(App)
    }).$mount();
  }
});