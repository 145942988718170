document.addEventListener('turbo:load', () => {
  const sendMessageForm = document.getElementById('send-message-form');

  if (sendMessageForm) {
    const sendMessageButton = document.getElementById('send-message-button');

    // Check for an Excel file to warn the user before sending
    sendMessageForm.addEventListener('submit', e => {
      const attachments = document.getElementsByName('message[temp_attachments][]');
      const isClientChat = document.getElementById('client-chat');

      if (isClientChat && Array.from(attachments).some(doc => doc.dataset.hasSpreadsheet)) {
         if (confirm('Are you sure you want to send the attached Excel spreadsheet to the client chat?')) {
          setButtonLoad();
          return true;
         } else {
          e.preventDefault();
         }
      } else {
        setButtonLoad();
      }
    });

    function setButtonLoad() {
      sendMessageButton.classList.replace('bg-brand-light', 'bg-gray-600');
      sendMessageButton.classList.add('cursor-wait');
      sendMessageButton.disabled = true;
      sendMessageButton.innerHTML = `
        <svg class="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Sending Message
      `;
    } 
  }
});