// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

window.Rails = Rails;
Rails.start();

require('fslightbox');
const Trix = require("trix")
require("@rails/actiontext")
// require("turbolinks").start()
import * as ActiveStorage from "@rails/activestorage"
require("channels")
import 'alpine-turbo-drive-adapter'
import 'alpinejs'

ActiveStorage.start();

const axios = require('axios');

import "@hotwired/turbo-rails";

// Vue application imports
import '../components/client_document_order'
import '../components/funnel_order'
import '../components/funnel_stages'
import '../components/client_order'
import '../components/client_stages'
import '../components/opportunity'
import '../components/opp_client_progress'
import '../components/documents'
import '../components/calendar'
import '../components/selections_order'
import '../components/trades'
import '../components/purchase_order'
import '../components/nav_search'
import '../components/schedule'
import '../components/client_bookings'
import '../components/document_categories'
import '../components/schedule_template'
import '../components/learning_steps'
import '../components/learning_categories'
import '../components/learning_centres'
import '../components/workflow_stage'
import '../components/opp_learning_centre'
import '../components/client_note_types'
import '../components/client_documents'

// Page-specific imports
import '../pages/utilities'
import '../pages/opportunity'
import '../pages/projects'
import '../pages/calendar'
import '../pages/action_items'
import '../pages/chat_rooms'
import '../utilities/add_fields'
import '../pages/selection_room'
import '../pages/opp_booking'
import '../pages/handy_contacts'
import '../pages/sales'
import '../pages/client_documents'
import '../pages/opportunity_bookings'
import '../pages/learning_embed'
import '../pages/opportunity_clients_navbar'
import '../pages/photo_gallery'
import '../pages/mutiple_container_draggable'
import '../pages/user_documents'
import '../pages/docusign_log'
import '../pages/video_modals'

// Stimulus Import
import "controllers"

// Libraries
import '../custom/frappe-gantt'

document.addEventListener("turbo:load", ()=> {
  const chat = document.querySelector("[data-chat-scroll]")

  if (chat) {
    chat.scrollIntoView(false);
  }

  const formSubmission = Object.values(document.getElementsByClassName('submit-form'));
  formSubmission.forEach(input => {
    input.addEventListener('change', e => {
      e.target.form.submit();
    });
  });
});

Trix.config.textAttributes.red = {
	style: { color: "red" },
  parser: function(element) {
  	return element.style.color === "red"
  },
  inheritable: true
 }

document.addEventListener("trix-initialize", function(event) {
  let buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="red">RED</button>';

  event.target.toolbarElement.
  	querySelector(".trix-button-group").
		insertAdjacentHTML("beforeend", buttonHTML)
})


import 'dropzone/dist/dropzone.css';
import '../stylesheets/application.scss'
