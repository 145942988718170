import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "inputField", "label", "inputGroup", "labelGroup",];

    connect() {
    }

    toggleInputOn() {
        this.labelGroupTarget.classList.add('hidden');
        this.inputGroupTarget.classList.remove('hidden');
        this.inputFieldTarget.focus();
        this.inputFieldTarget.select();
    }

    toggleInputOff() {
        this.labelGroupTarget.classList.remove('hidden');;
        this.inputGroupTarget.classList.add('hidden');
    }

    cancel() {
        this.toggleInputOff();
    }

    loseFocus() {
        setTimeout(()=>{
            this.toggleInputOff();
        }, 100)
    }


    async addChecklist() {
        this.toggleInputOff();

        const request = new FetchRequest("POST", `/staff/action_items/add_checklist?checklist_name=${this.inputFieldTarget.value}`,{responseKind: "turbo-stream"});
        const response = await request.perform();
        if (response.ok) {
            setTimeout(() => this.loading = false, 200)
        }
    }


}
