var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "duration-300",
        "leave-active-class": "duration-200"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.signingModalOpen,
              expression: "signingModalOpen"
            }
          ],
          staticClass: "relative z-10",
          attrs: {
            "aria-labelledby": "modal-title",
            role: "dialog",
            "aria-modal": "true"
          }
        },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "duration-300 ease-out",
                "enter-from-class": "opacity-0",
                "enter-to-class": "opacity-100",
                "leave-active-class": "duration-200 ease-in",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.signingModalOpen,
                    expression: "signingModalOpen"
                  }
                ],
                staticClass:
                  "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fixed z-10 inset-0 overflow-y-auto" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
              },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      "enter-active-class": "duration-300 ease-out",
                      "enter-from-class":
                        "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                      "enter-to-class":
                        "opacity-100 translate-y-0 sm:scale-100",
                      "leave-active-class": "ease-in duration-200",
                      "leave-from-class":
                        "opacity-100 translate-y-0 sm:scale-100",
                      "leave-to-class":
                        "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.signingModalOpen,
                            expression: "signingModalOpen"
                          }
                        ],
                        staticClass:
                          "relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-green-500",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-6 h-6",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-3 sm:mt-5" }, [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-lg leading-6 font-medium text-gray-900",
                                  attrs: { id: "modal-title" }
                                },
                                [_vm._v("Request Document Signing")]
                              ),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-lg leading-6 font-medium text-gray-900",
                                  attrs: { id: "modal-title" }
                                },
                                [_vm._v(_vm._s(_vm.documentSummary))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2" }, [
                                _c("p", { staticClass: "text-gray-500" }, [
                                  _vm._v(
                                    "Send this document for electronic signing to both clients and The Shed House team using DocuSign."
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("The Shed House Signers"),
                                  _c("sup", [_vm._v("*")])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "input full sm:text-sm sm:leading-5 form-select",
                                    domProps: { value: _vm.staffSelected },
                                    on: { change: _vm.selectStaff }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("Leave Blank If Not Required")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.staffUsers, function(user) {
                                      return _c(
                                        "option",
                                        {
                                          key: user.id,
                                          domProps: { value: user.id }
                                        },
                                        [_vm._v(_vm._s(user.name))]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.signingStaffContacts, function(
                                  staff
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: staff.id,
                                      staticClass:
                                        "w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center"
                                    },
                                    [
                                      _c("h4", [_vm._v(_vm._s(staff.name))]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-white",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeStaffSigner(
                                                staff.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "w-5 h-5",
                                              attrs: {
                                                fill: "none",
                                                stroke: "currentColor",
                                                viewBox: "0 0 24 24",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "stroke-width": "2",
                                                  d: "M6 18L18 6M6 6l12 12"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "mt-1 text-sm text-gray-400" },
                                  [
                                    _vm._v(
                                      "Remove all staff signers (if any) above if no staff signing is required for this document."
                                    )
                                  ]
                                )
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.signingBuilderContacts.length == 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c("label", { staticClass: "label" }, [
                                      _vm._v("Client Signers"),
                                      _c("sup", [_vm._v("*")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "input full sm:text-sm sm:leading-5 form-select",
                                        domProps: { value: _vm.clientSelected },
                                        on: { change: _vm.selectClient }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [
                                            _vm._v(
                                              "Nominate clients for signing"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(_vm.clients, function(client) {
                                          return _c(
                                            "option",
                                            {
                                              key: client.id,
                                              domProps: { value: client.id }
                                            },
                                            [_vm._v(_vm._s(client.name))]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.signingClientContacts, function(
                                      client
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: client.id,
                                          staticClass:
                                            "w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center"
                                        },
                                        [
                                          _c("h4", [
                                            _vm._v(_vm._s(client.name))
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-white",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeClientSigner(
                                                    client.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "w-5 h-5",
                                                  attrs: {
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    viewBox: "0 0 24 24",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                      "stroke-width": "2",
                                                      d: "M6 18L18 6M6 6l12 12"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mt-1 text-sm text-gray-400"
                                      },
                                      [
                                        _vm._v(
                                          "Only client contacts with emails are shown. Please edit the project's client contact list to see more options."
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.signingClientContacts.length == 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-4" },
                                  [
                                    _c("label", { staticClass: "label" }, [
                                      _vm._v("Builder Signers"),
                                      _c("sup", [_vm._v("*")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "input full sm:text-sm sm:leading-5 form-select",
                                        domProps: {
                                          value: _vm.builderSelected
                                        },
                                        on: { change: _vm.selectBuilder }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [
                                            _vm._v(
                                              "Nominate builders for signing"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(_vm.builders, function(builder) {
                                          return _c(
                                            "option",
                                            {
                                              key: builder.id,
                                              domProps: { value: builder.id }
                                            },
                                            [_vm._v(_vm._s(builder.name))]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.signingBuilderContacts, function(
                                      builder
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: builder.id,
                                          staticClass:
                                            "w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center"
                                        },
                                        [
                                          _c("h4", [
                                            _vm._v(_vm._s(builder.name))
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-white",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeBuilderSigner(
                                                    builder.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "w-5 h-5",
                                                  attrs: {
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    viewBox: "0 0 24 24",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                      "stroke-width": "2",
                                                      d: "M6 18L18 6M6 6l12 12"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-4" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Email Subject"),
                                _c("sup", [_vm._v("*")])
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "input w-full",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "Email subject line sent to all recipients"
                                },
                                domProps: { value: _vm.emailSubject },
                                on: {
                                  change: function(e) {
                                    return _vm.setEmailSubject(e.target.value)
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-4" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Email Body Blurb")
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                staticClass: "input w-full",
                                attrs: {
                                  placeholder:
                                    "Optional blurb added to the signing request emails"
                                },
                                domProps: { value: _vm.emailBody },
                                on: {
                                  change: function(e) {
                                    return _vm.setEmailBody(e.target.value)
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "relative flex items-start" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center h-5" },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "focus:brand-dark h-4 w-4 text-brand-dark border-gray-300 rounded",
                                        attrs: {
                                          id: "confirm-for-signing",
                                          name: "confirm_for_signing",
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: _vm.signingDocumentFinalised
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.setDocumentFinalised(
                                              e.target.checked
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-3 text-sm" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-medium text-gray-700",
                                        attrs: { for: "confirm-for-signing" }
                                      },
                                      [_vm._v("Document Is Finalised")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-gray-500",
                                        attrs: {
                                          id: "confirm-for-signing-description"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Document is approved and ready for e-signing with client and staff signing areas correctly nominated"
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.outstandingReview,
                                    expression: "outstandingReview"
                                  }
                                ],
                                staticClass: "mt-4"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "relative flex items-start" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center h-5" },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "focus:brand-dark h-4 w-4 text-brand-dark border-gray-300 rounded",
                                          attrs: {
                                            id: "confirm-no-review",
                                            name: "confirm_for_signing",
                                            type: "checkbox"
                                          },
                                          domProps: {
                                            checked:
                                              _vm.signingReviewNotRequired
                                          },
                                          on: {
                                            change: function(e) {
                                              return _vm.setReviewNotRequired(
                                                e.target.checked
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-3 text-sm" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-medium text-gray-700",
                                          attrs: { for: "confirm-no-review" }
                                        },
                                        [
                                          _vm._v(
                                            "Document Does NOT Require Review"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "text-gray-500",
                                          attrs: {
                                            id: "confirm-no-review-description"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "This document has not been reviewed. Are you sure you want to send it for signing?"
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                          },
                          [
                            _vm.signingLoading
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-light text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm",
                                    attrs: { disabled: "", type: "button" }
                                  },
                                  [_vm._v("Sending...")]
                                )
                              : _vm.signingCompleted
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm",
                                    attrs: { disabled: "", type: "button" }
                                  },
                                  [_vm._v("Request Sent!")]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm",
                                    class: _vm.canSend
                                      ? "text-white bg-brand-dark"
                                      : "text-gray-500 bg-gray-300 cursor-not-allowed",
                                    attrs: {
                                      disabled: !_vm.canSend,
                                      type: "button"
                                    },
                                    on: { click: _vm.sendSigningRequest }
                                  },
                                  [_vm._v("Send For Signing")]
                                ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:mt-0 sm:col-start-1 sm:text-sm",
                                attrs: { type: "button" },
                                on: { click: _vm.closeSigningModal }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }