document.addEventListener('turbo:load', () => {
  const salesPage = document.getElementById('sales-statistics');
  const userInput = document.getElementById('sales_id');

  if (salesPage && userInput) {
    userInput.addEventListener('change', function(e) {
      var location = `${window.location.origin}${window.location.pathname}?user_id=${e.target.value}`;

      Turbo.visit(location, { action: 'replace' });
    });
  }
})