import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["slave", "master"];
    static values = {
        show: String
    }
    showValues = this.showValue.split(',').map(i=>i.trim())

    connect() {
        this.toggleHide();
    }

    toggleHide() {
        if (this.showValues.includes(this.masterTarget.value)) {
            this.slaveTarget.hidden = false;
        } else {
            this.slaveTarget.hidden = true;
        }
    }
}
