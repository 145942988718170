import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

const URL = "/staff/template_room_parts/:id"

export default class extends Controller {

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            animation: 150,
            handle: '.item-handle',
            group: {
                name: 'shared',
                put: 'shared'
            },
        })
    }

    end(event) {
        let partContainer = event.item.closest('.template-room-part-container');
        if (partContainer === null) return;
        let id = event.item.dataset.id;

        let data = new FormData();
        data.append('template_room_part[position]', event.newIndex + 1);

        Rails.ajax({
            url: URL.replace(':id',id),
            type: 'PATCH',
            data: data
        });
    }
}