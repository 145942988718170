import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { FetchRequest } from "@rails/request.js";

const URL = "/staff/inventory_categories/:id/move?record_type=:record_type"

export default class extends Controller {
    static values = {
        recordType: String,
    }

    connect() {
       this.sortable = Sortable.create(this.element, {
           onEnd: this.end.bind(this),
           animation: 150,
           handle: '.category-handle',
           group: {
               name: 'root',
               put: 'root'
           },
       })
    }

    async end(event) {
        let id = event.item.dataset.id;
        let data = new FormData();
        data.append('position', event.newIndex + 1);

        const request = new FetchRequest("patch", URL.replace(':id',id).replace(':record_type', this.recordTypeValue),{responseKind: "turbo-stream", body: data});
        const response = await request.perform();

    }
}