<template>
  <div>
    <div class="mb-4" id="category-client-uploaded-documents">
      <div class="">
        <h3 class="text-xl font-bold text-gray-800">Client Shared Documents</h3>
      </div>
      <DocumentTable :documents="clientDocuments"></DocumentTable>
    </div>
    <div class="mt-2" id="staff-client-documents">
      <Category
        v-for="(category, index) in categoriesWithDocuments"
        :key="category.id"
        :category="category"
        :index="index"
      ></Category>
    </div>
    <ReviewModal></ReviewModal>
    <SigningModal></SigningModal>
    <RetractModal></RetractModal>
    <ManuallySigningModal></ManuallySigningModal>
  </div>
</template>
<script>
import Document from "./Document.vue";
import Category from "./Category.vue";
import DocumentTable from "./DocumentTable.vue";
import ReviewModal from './ReviewModal.vue';
import SigningModal from './SigningModal.vue';
import ManuallySigningModal from "./ManuallySigningModal";
import draggable from 'vuedraggable'
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import RetractModal from './RetractModal.vue';

export default {
  components: { Document, Category, DocumentTable, draggable, ReviewModal, SigningModal, RetractModal, ManuallySigningModal },
  mounted() {
    this.setOpportunityId(document.getElementById("staff-opportunity-management").dataset.opportunityId);
    this.setUserRole(document.getElementById("staff-opportunity-management").dataset.userRole);
    this.setUserId(document.getElementById("staff-opportunity-management").dataset.userId)
    const csrfToken = document.getElementsByName('csrf-token')[0].content;

    this.loadDocuments();
    this.setCsrf(csrfToken);

    // SCROLL TO ANCHOR
    if (location.hash) {
      window.setTimeout(() => {
        document.getElementById(location.hash.slice(1)).scrollIntoView();
        document.getElementById(location.hash.slice(1)).style.backgroundColor = "yellow";
      }, 300);
    }

  },
  computed: {
    ...mapState([
      'opportunityId',
      'clientDocuments'
    ]),
    ...mapGetters([
      'categoriesWithDocuments'
    ])
  },
  methods: {
    ...mapMutations([
      'setOpportunityId',
      'setCsrf',
      'setUserRole',
      'setUserId'
    ]),
    ...mapActions([
      'loadDocuments'
    ]),
    log(evt) {
      console.log(evt);
    }
  },
}
</script>