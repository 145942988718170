var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ml-8 px-3 py-1 rounded", class: _vm.discardedStyle },
    [
      _c("div", { staticClass: "grid grid-cols-12 space-x-1 items-center" }, [
        _vm.document.current
          ? _c("p", { staticClass: "font-bold col-span-2" }, [
              _vm._v("Displayed To Client")
            ])
          : _vm.document.discarded_at
          ? _c("p", { staticClass: "font-bold col-span-2" }, [
              _vm._v("Archived")
            ])
          : _c("p", { staticClass: "font-medium col-span-2" }, [
              _vm._v("Superseded V" + _vm._s(_vm.document.version))
            ]),
        _vm._v(" "),
        _c("h4", { staticClass: "col-span-4 font-light text-sm" }, [
          _vm._v(_vm._s(_vm.document.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-span-6 flex items-center justify-end space-x-2" },
          [
            _vm.document.user && _vm.document.user.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "bg-brand-dark rounded-full h-8 w-8 p-1 text-white text-sm flex items-center justify-center"
                  },
                  [_vm._v(_vm._s(_vm.document.user))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "text-sm" }, [
              _vm._v(_vm._s(_vm.document.uploaded))
            ]),
            _vm._v(" "),
            _vm.document.current
              ? _c(
                  "a",
                  {
                    staticClass:
                      "ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.archive($event)
                      }
                    }
                  },
                  [_vm._v("\n        Archive\n      ")]
                )
              : _vm.document.discarded_at
              ? _c(
                  "div",
                  { staticClass: "flex-items-center space-x-2 justify-end" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.unarchive($event)
                          }
                        }
                      },
                      [_vm._v("\n          Unarchive\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "ml-4 px-4 py-1 rounded-lg bg-brand-dark text-red font-semibold",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.deleteDoc($event)
                          }
                        }
                      },
                      [_vm._v("\n          Delete\n        ")]
                    )
                  ]
                )
              : _c(
                  "a",
                  {
                    staticClass:
                      "ml-4 px-4 py-1 rounded-lg bg-brand-dark text-white font-semibold",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.makeActive($event)
                      }
                    }
                  },
                  [_vm._v("\n        Make Active\n      ")]
                )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }