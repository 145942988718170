import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
// pass 'default_close: true' to the modal to prevent the entire modal from closing on a button press.
// pass data:{close: true} in the form element to stop only that for from closing after submit
export default class extends Controller {
    static values = {
        update: Boolean
    }

    connect() {
    }

    closeModal() {
        this.element.remove();
    }

    delayCloseModal(event) {
        if (this.updateValue || event.target.dataset.close == 'false') { return };
        setTimeout(() => this.closeModal(), 500);
    }

    submit(event) {
        if(!this.updateValue || event.target.dataset.close == 'false') {
            setTimeout(() => this.closeModal(), 200);
        }
    }

    submitClose(e) {
        e.preventDefault();
        Rails.fire(e.target.form, 'submit');

        setTimeout(() => this.closeModal(), 200);
    }
}