import Vue from 'vue';
import App from './purchase_order/app.vue';
// import store from './trades/store';
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const trades = document.getElementById('purchase-order-vue');

  if (trades) {
    new Vue({
      el: '#purchase-order-vue',
      // store: store,
      render: h => h(App)
    }).$mount();
  }
});