var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass:
        "table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "divide-y divide-gray-200" },
        [
          _c(
            "draggable",
            {
              staticClass: "flex flex-col",
              on: {
                start: function($event) {
                  _vm.drag = true
                },
                end: function($event) {
                  _vm.drag = false
                  _vm.saveTypes()
                }
              },
              model: {
                value: _vm.note_types,
                callback: function($$v) {
                  _vm.note_types = $$v
                },
                expression: "note_types"
              }
            },
            _vm._l(_vm.note_types, function(noteType) {
              return _c(
                "tr",
                {
                  key: noteType.id,
                  staticClass: "flex flex-auto justify-between"
                },
                [
                  _c("td", { staticClass: "p-3 whitespace-no-wrap" }, [
                    _c(
                      "a",
                      {
                        staticClass: "font-semibold text-gray-800",
                        attrs: {
                          href: "/staff/funnel_stages/" + noteType.id + "/edit"
                        }
                      },
                      [_vm._v(_vm._s(noteType.name))]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "p-3 whitespace-no-wrap text-gray-500" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/staff/funnel_stages/" + noteType.id,
                            "data-method": "delete",
                            "data-confirm":
                              "Are you sure you want to delete this note type?"
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "border-b-gray-200" }, [
        _c(
          "th",
          {
            staticClass:
              "p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md"
          },
          [_vm._v("Name")]
        ),
        _vm._v(" "),
        _c("th", {
          staticClass:
            "p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }