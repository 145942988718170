<template>
  <div class="p-4 bg-gray-50 rounded">
    <a :href="`/staff/purchase_orders/${po.id}`" class='text-brand-dark font-semibold mr-4'>
      <span v-if="po.name || po.request_date">{{ po.name ? `${po.name} - ` : '' }}{{ po.request_date || 'No Date'}}</span>
      <span v-else>{{ po.id }} - {{ doc_type_labels[po.doc_type] }}</span>
    </a>
    <div class="flex items-center flex-auto space-x-4 mt-2">
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Quote Status</h4>
        <select v-model="quote_status" class="form-select w-full">
          <option v-for="(val, key) in quote_statuses" :key="`quote-status-${key}`" :value="key">
            {{ val }}
          </option>
        </select>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">TSH Estimate</h4>
        <p v-if="po.estimated_total">${{ po.estimated_total }}</p>
        <p v-else>No estimates</p>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Quote Total</h4>
        <p v-if="po.quote_total">${{ po.quote_total }}<span v-html="quoteDiff"></span></p>
        <p v-else>No quotes</p>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Actual Price</h4>
        <p v-if="po.actual_total">${{ po.actual_total }}<span v-html="estimatedDiff"></span></p>
        <p v-else>No invoices/actuals</p>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Job Status</h4>
        <select v-model="job_status" class="form-select w-full">
          <option v-for="(val, key) in job_statuses" :key="`quote-status-${key}`" :value="key">
            {{ val }}
          </option>
        </select>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Invoice Status</h4>
        <select v-model="invoice_status" class="form-select w-full">
          <option v-for="(val, key) in invoice_statuses" :key="`quote-status-${key}`" :value="key">
            {{ val }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['po', 'supplierIndex', 'supplierId'],
  data() {
    return {
      quote_statuses: { na: 'Not Requested', requested: 'Requested', received: 'Received', approved: 'Approved', declined: 'Declined' },
      job_statuses:{ na: 'Not Applicable', not_started: 'Not Started', booked: 'Booked', in_progress: 'In Progress', completed: 'Completed' },
      invoice_statuses: { na: 'Not Applicable', pending: 'Pending', approved: 'Approved', paid: 'Paid' },
      doc_type_labels: {
        'quote_request': 'Quote Request',
        'purchase_order': 'Purchase Order'
      },
      trade_attachments: [],
    }
  },
  computed: {
    estimatedDiff() {
      if (this.po.actual_total && this.po.estimated_total) {
        const diff = (this.po.actual_total - this.po.estimated_total).toFixed(2);

        if (diff > 0) {
          return `<span class="text-sm text-red-500 ml-2">+$${diff}</span>`;
        } else {
          return `<span class="text-sm text-green-500 ml-2">$${diff}</span>`;
        }
      }
    },
    quoteDiff() {
      if (this.po.actual_total && this.po.quote_total) {
        const diff = (this.po.quote_total - this.po.estimated_total).toFixed(2);

        if (diff > 0) {
          return `<span class="text-sm text-red-500 ml-2">+$${diff}</span>`;
        } else {
          return `<span class="text-sm text-green-500 ml-2">$${diff}</span>`;
        }
      }
    },
    quote_status: {
      get() {
        return this.po.quote_status;
      },
      set(value) {
        this.$store.dispatch('updatePurchaseOrder', { supplierId: this.supplierId, id: this.po.id, attr: 'quote_status', value })
      }
    },
    job_status: {
      get() {
        return this.po.job_status;
      },
      set(value) {
        this.$store.dispatch('updatePurchaseOrder', { supplierId: this.supplierId, id: this.po.id, attr: 'job_status', value })
      }
    },
    invoice_total: {
      get() {
        return this.po.invoice_total;
      },
      set(value) {
        this.$store.dispatch('updatePurchaseOrder', { supplierId: this.supplierId, id: this.po.id, attr: 'invoice_total', value })
      }
    },
    invoice_status: {
      get() {
        return this.po.invoice_status;
      },
      set(value) {
        this.$store.dispatch('updatePurchaseOrder', { supplierId: this.supplierId, id: this.po.id, attr: 'invoice_status', value })
      }
    },
  }
}
</script>