<template>
  <div v-show="documents.length > 0" class="shadow-xl">
    <table class="table">
      <thead>
        <tr>
          <th class="th w-1/3">Document Name</th>
          <th class="th w-1/6">Date Uploaded</th>
          <th class="th w-1/6">Internal Status</th>
          <th class="th w-1/6">Signing Status</th>
          <th class="th w-1/6">Actions</th>
        </tr>
      </thead>
      <draggable tag="tbody" class="divide-y divide-gray-200" :list="shownDocs" group="documents" @change="handleDrop" handle=".draggable-handle">
        <Document
          v-for="document in shownDocs"
          :key="document.id"
          :document="document"
          :toggleVersions="toggleVersions"
          :showing-all="showAll"
        ></Document>
      </draggable>
    </table>
  </div>
</template>
<script>
import Document from './Document.vue';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
  components: { Document, draggable },
  props: [
    'documents',
    'multipleAllowed',
    'categoryId',
    'subCategoryId',
    'showAll'
  ],
  computed: {
    shownDocs() {
      return this.documents.filter(d => {
        // Only show the versions of each document if toggled on
        if (this.multipleAllowed) {
          // If this document is current, show it
          if (d.current) return true;

          // If this document has a parent, but the parent has showVersions on, show it
          if (d.parent_id) {
            const parentDoc = this.documents.find(doc => doc.id === d.parent_id);

            if (parentDoc.showVersions) return true;
          }
        } else {
          if (this.showAll || this.documents.length === 1 || d.current) {
            return true;
          }

          const parentDoc = this.documents.find(doc => doc.current);

          if (parentDoc && parentDoc.showVersions) return true;
        }
      }).sort((a, b) => {
        if (b.current > a.current) {
          return 1;
        } else {
          return a.version - b.version;
        }
      });
    },
    categoryIds() {
      return {
        categoryId: this.categoryId,
        subCategoryId: this.subCategoryId
      }
    }
  },
  methods: {
    ...mapActions([
      'dropDocument',
      'moveDocument'
    ]),
    toggleVersions(documentId) {
      const documentIndex = this.documents.findIndex(documentId);

      Vue.set(this.documents[documentIndex], showVersions, !this.documents[documentIndex].showVersions);
    },
    handleDrop(e) {
      if (e.added) {
        this.dropDocument({
          documentId: e.added.element.id,
          categoryIds: this.categoryIds,
          index: e.added.newIndex
        });
      } else {
        this.moveDocument({
          documentId: e.removed.element.id,
          categoryIds: this.categoryIds
        });
      }
    }
  }
}
</script>