import Vue from 'vue'
import App from './funnel_stages/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const funnelBuilder = document.getElementById('funnel-builder');

  if (funnelBuilder) {
    new Vue({
      el: '#funnel-builder',
      render: h => h(App)
    }).$mount();
  }
});