import Tagify from "@yaireo/tagify";

document.addEventListener('turbo:load', () => {
  const eventForm = document.getElementById('event-form');

  if (eventForm) {
    const staffElem = document.getElementById('staff-data');
    const staffData = JSON.parse(staffElem.dataset.data);
    const usersInput = document.getElementById('user_ids');

    tagify(usersInput, staffData);

    eventForm.addEventListener('submit', (e) => {
      e.preventDefault();
      if (usersInput.value) {
        const formattedUserObj = JSON.parse(usersInput.value);
        usersInput.value = formattedUserObj.map(u => u.id).join(',');
      }

      eventForm.submit();
    });

    function tagify(elem, whitelist) {
      const sortedWhitelist = whitelist.sort((a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameB > nameA) {
          comparison = -1;
        }

        return comparison;
      });

      new Tagify(elem, {
        whitelist: sortedWhitelist,
        enforceWhitelist: true,
        dropdown: {
          enabled: 0,
          maxItems: 100
        }
      });
    }
  }
});