document.addEventListener('turbo:load', function() {
  const docusignIndex = document.getElementById('docusign-index');

  const filterToQueryString = (filterObj, overrides) => {
    const copy = Object.assign(filterObj, overrides || {}, );
    const searchParams = new URLSearchParams();

    Object.entries(copy).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length) {
        value.forEach(v => searchParams.append(key, v));
      } else if (value) {
        searchParams.set(key, value)
      }
    });

    searchParams.sort()
    return searchParams.toString()
  }

  const getFilters = () => {
    let filters = {};
    let permittedFilters = ['project_name', 'category', 'document_name', 'log', 'date_sent'] ;
    var url = new URL(window.location);
    var searchParams = new URLSearchParams(url.search);

    permittedFilters.forEach(f => {
      const activeFilter = searchParams.get(f);

      if (activeFilter) filters[f] = activeFilter;
    });

    return filters;
  }

  if (docusignIndex) {
    // Table heading orders
    const sorts = ['name', 'category', 'document_name', 'docusign_sent'];

    sorts.forEach(s => {
      const el = document.getElementById(`${s}_sort`);

      el.addEventListener('click', function(e) {
        const filters = getFilters();
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        let order = searchParams.get('order');
        let orderParts = [];
        if (order) {
            orderParts = order.split('_');
        }
        const orderDirection = order && orderParts[orderParts.length - 1] === 'asc' ? 'desc' : 'asc';
        let newSearch = { order: `${s}_${orderDirection}` };

        var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, newSearch)}`;

        order === 'asc' ? order = 'desc' : order = 'asc';
        Turbo.visit(location, { action: 'replace' });
      });
    })
  }
});



