import { Controller } from "@hotwired/stimulus";
import { get, post, put, patch, destroy } from '@rails/request.js'
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import {isValidDate} from "@fullcalendar/vue";

export default class extends Controller {
  static targets = ["calendar"];
  static values = {
    id: String,
    initialDate: String
  }

  calendar = null;

  connect() {
    var tempDate = new Date(this.initialDateValue)
    let initialDate = null;

    if ( isValidDate(tempDate) ) {
      initialDate = tempDate.toISOString().slice(0,10);
    }

    let opportunityId = this.idValue;
    let calendar = new Calendar(this.calendarTarget, {
      plugins: [listPlugin, interactionPlugin, momentPlugin],
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'rollingYear listDay listMonth prev,next'
      },
      initialDate: initialDate,
      initialView: 'listDay',
      eventClick: function(info) {
        const eventInfo = info.event._def;

        let url = `/staff/opportunities/${opportunityId}/bookings/${eventInfo.publicId}/edit`;
        const modal = document.getElementById("modal");
        modal.src = url;

      },
      eventDidMount: this.eventContent,
      events: `/staff/opportunities/${opportunityId}/bookings.json`,
      views: {
        rollingYear: {
          type: 'list',
          duration: { days: 365 },
          buttonText: 'Rolling Year View'
        },
        listDay: {
          type: 'listDay',
          buttonText: 'Today'
        },
        listMonth: {
          type: 'listMonth',
          buttonText: 'Month View'
        }
      }
    });

    this.calendar = calendar;
    this.calendar.render()

    // MUTATION OBSERVER TO UPDATE CALENDER AFTER TURBO-STREAM UPDATE
    const targetNode = document.getElementById('turbo-update');
    const observer = new MutationObserver(() => {
      this.calendar.refetchEvents();
      this.calendar.render();
    }).observe(targetNode, { childList: true});

  }

  disconnect() {
    this.calendar.destroy();
  }

  eventContent(info) {
    let background, icon;

    info.el.classList.add('cursor-pointer');
    info.el.classList.add('hover:text-gray-800');
    info.el.setAttribute('id',`calendar_booking_${info.event._def.publicId}`);


    switch(info.event._def.extendedProps.status) {
      case 'confirmed':
        background = 'bg-green-100';
        icon = '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center mr-2"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        break;
      case 'complete':
        info.el.classList.add('text-white');
        background = 'bg-brand-dark';
        icon = '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center mr-3"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        break;
      default: // Pending
        background = 'bg-red-100';
        icon = '<span class="bg-red-400 h-6 w-6 rounded-xl flex items-center justify-center mr-2"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>';
    }

    info.el.getElementsByClassName('fc-list-event-graphic')[0].innerHTML = icon;
    info.el.classList.add(background);
  }

}
