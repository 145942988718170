var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "draggable-item" }, [
    _c("td", { staticClass: "td flex items-center space-x-2" }, [
      _c(
        "div",
        { staticClass: "flex-shrink-0 text-gray-300 draggable-handle" },
        [
          _c(
            "svg",
            {
              staticClass: "w-7 h-7",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M4 6h16M4 10h16M4 14h16M4 18h16"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "rounded-full flex items-center flex-shrink-0 justify-center h-7 w-7 my-2 bg-brand-dark text-white"
        },
        [
          _c("span", { staticClass: "font-semibold text-sm" }, [
            _vm._v(_vm._s(_vm.document.uploaded_by_initials))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "flex space-x-1 items-center",
          staticStyle: { "scroll-margin": "125px" },
          attrs: {
            id: _vm.document.id,
            href: _vm.document.file_path,
            target: "_blank"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.document.file_name) + "\n        "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showingAll,
                  expression: "showingAll"
                }
              ],
              staticClass: "text-gray-500 ml-2"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.document.current
                    ? "Current"
                    : "Superceded - V" + _vm.document.version
                )
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "td" }, [_vm._v(_vm._s(_vm.document.created_at))]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "td flex items-center space-x-2" },
      [
        _c(
          "popper",
          {
            attrs: {
              options: {
                placement: "top",
                modifiers: { offset: { offset: "0,10px" } }
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "bg-white px-4 py-2 shadow-lg rounded-full" },
              [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.document.show_client
                        ? "Shown to Client"
                        : "Hidden from Client"
                    )
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.shownClientClass,
                attrs: { slot: "reference" },
                slot: "reference"
              },
              [
                _vm.document.show_client
                  ? _c(
                      "svg",
                      {
                        staticClass: "w-6 h-6",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          }
                        })
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        staticClass: "w-6 h-6",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          }
                        })
                      ]
                    )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "popper",
          {
            attrs: {
              options: {
                placement: "top",
                modifiers: { offset: { offset: "0,10px" } }
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "bg-white px-4 py-2 shadow-lg rounded-full" },
              [_c("p", [_vm._v(_vm._s(_vm.document.review_status))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.reviewStatusClass,
                attrs: { slot: "reference" },
                slot: "reference"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "w-6 h-6",
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d:
                          "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "popper",
          {
            attrs: {
              options: {
                placement: "top",
                modifiers: { offset: { offset: "0,10px" } }
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "bg-white px-4 py-2 shadow-lg rounded-full" },
              [_c("p", [_vm._v(_vm._s(_vm.signingStatus))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.signStatusClass,
                attrs: { slot: "reference" },
                slot: "reference"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "w-6 h-6",
                    attrs: {
                      fill: "currentColor",
                      stroke: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M18.404 2.998c-.757-.754-2.077-.751-2.828.005l-1.784 1.791L11.586 7H7a.998.998 0 0 0-.939.658l-4 11c-.133.365-.042.774.232 1.049l2 2a.997.997 0 0 0 1.049.232l11-4A.998.998 0 0 0 17 17v-4.586l2.207-2.207v-.001h.001L21 8.409c.378-.378.586-.881.585-1.415 0-.535-.209-1.038-.588-1.415l-2.593-2.581zm-3.111 8.295A.996.996 0 0 0 15 12v4.3l-9.249 3.363 4.671-4.671c.026.001.052.008.078.008A1.5 1.5 0 1 0 9 13.5c0 .026.007.052.008.078l-4.671 4.671L7.7 9H12c.266 0 .52-.105.707-.293L14.5 6.914 17.086 9.5l-1.793 1.793zm3.206-3.208-2.586-2.586 1.079-1.084 2.593 2.581-1.086 1.089z"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "td" },
      [
        _c(
          "popper",
          {
            attrs: {
              options: {
                placement: "top",
                modifiers: { offset: { offset: "0,10px" } }
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "bg-white px-4 py-2 shadow-lg rounded space-y-1 border"
              },
              [
                _vm._l(_vm.signingUsers, function(log, index) {
                  return _c("p", { key: index }, [
                    _vm._v("\n          " + _vm._s(log) + "\n        ")
                  ])
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm._l(_vm.signingEvents, function(log, index) {
                  return _c("p", { key: index }, [_vm._v(_vm._s(log))])
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
              _vm._v(_vm._s(_vm.signingStatus) + " ")
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "td" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.userPermissions.canEdit,
              expression: "userPermissions.canEdit"
            },
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickOutsideMenu,
              expression: "clickOutsideMenu"
            }
          ],
          staticClass: "relative inline-block text-left"
        },
        [
          _c("div", [
            _c(
              "button",
              {
                staticClass:
                  "bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 p-0.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-dark",
                attrs: {
                  type: "button",
                  id: "menu-button",
                  "aria-expanded": "true",
                  "aria-haspopup": "true"
                },
                on: {
                  click: function($event) {
                    _vm.open = !_vm.open
                  }
                }
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v("Open options")
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "w-5 h-5",
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d:
                          "M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      }
                    })
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open"
                }
              ],
              staticClass:
                "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 divide-y divide-gray-100",
              attrs: {
                role: "menu",
                "aria-orientation": "vertical",
                "aria-labelledby": "menu-button",
                tabindex: "-1"
              }
            },
            [
              _vm.currentDocument
                ? _c("div", { staticClass: "py-1", attrs: { role: "none" } }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openReviewModal(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Send Review Request")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canApprove,
                            expression: "canApprove"
                          }
                        ],
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.reviewDocument(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Approve Review")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.document.reviewed_at,
                            expression: "document.reviewed_at"
                          }
                        ],
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.cancelReview(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Cancel Review")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "py-1" }, [
                _vm.document.show_client
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.hideDocument(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Hide From Client")]
                    )
                  : _c(
                      "a",
                      {
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showDocument(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Show To Client")]
                    )
              ]),
              _vm._v(" "),
              _vm.signableFileType &&
              _vm.currentDocument &&
              _vm.document.requires_signing
                ? _c("div", { staticClass: "py-1", attrs: { role: "none" } }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openSigningModal(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Send For Signing")]
                    ),
                    _vm._v(" "),
                    _vm.resendLoading
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-white bg-gray-700 block px-4 py-2 text-sm"
                          },
                          [_vm._v("Resending...")]
                        )
                      : _vm.resendCompleted
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-white bg-green-500 block px-4 py-2 text-sm"
                          },
                          [_vm._v("Resend Completed")]
                        )
                      : _vm.document.status === "Sent For Signing"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                            attrs: {
                              id: "resend-signing",
                              href: "#",
                              role: "menuitem",
                              tabindex: "-1"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.resendSigning(_vm.document.id)
                              }
                            }
                          },
                          [_vm._v("Re-send For Signing")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.document.status === "Sent For Signing",
                            expression: "document.status === 'Sent For Signing'"
                          }
                        ],
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openRetractModal(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Withdraw Signing")]
                    ),
                    _vm._v(" "),
                    _vm.document.status === "Manually Signed"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                            attrs: {
                              href: "#",
                              role: "menuitem",
                              tabindex: "-1"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openManuallySigningModal(
                                  _vm.document.id
                                )
                              }
                            }
                          },
                          [_vm._v("Undo Manual Signing")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                            attrs: {
                              href: "#",
                              role: "menuitem",
                              tabindex: "-1"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openManuallySigningModal(
                                  _vm.document.id
                                )
                              }
                            }
                          },
                          [_vm._v("Sign Manually")]
                        )
                  ])
                : _vm.currentDocument && !_vm.signableFileType
                ? _c("div", { staticClass: "py-1 bg-gray-100" }, [
                    _c(
                      "p",
                      { staticClass: "text-gray-500 block text-sm px-4 py-2" },
                      [_vm._v("Cannot Sign This File Type")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.document.requires_signing
                ? _c(
                    "a",
                    {
                      staticClass:
                        "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark bg-red-50",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.disableSigning(_vm.document.id)
                        }
                      }
                    },
                    [_vm._v("Disable Signing")]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark bg-green-50",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.enableSigning(_vm.document.id)
                        }
                      }
                    },
                    [_vm._v("Enable Signing")]
                  ),
              _vm._v(" "),
              !_vm.document.current && !_vm.document.multiple_category
                ? _c("div", { staticClass: "py-1" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.makeActive(_vm.document.id)
                          }
                        }
                      },
                      [_vm._v("Make Active Document")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "py-1 bg-gray-50 text-center text-red-500 hover:text-white hover:bg-red-500",
                  attrs: { role: "none" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "block px-4 py-2 text-sm",
                      attrs: { href: "#", role: "menuitem", tabindex: "-1" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirmDelete($event)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }