import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
static values = { url: String }

    async doubleClick(event) {
        event.preventDefault();

        fetch(this.urlValue)
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
    }


}