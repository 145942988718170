var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "sub-task-" + _vm.subTask.id } },
    [
      _c("tippy", { attrs: { to: "subtask-trigger-" + _vm.subTask.id } }, [
        _c("div", { staticClass: "flex space-x-2" }, [
          _c("h4", { staticClass: "font-semibold" }, [_vm._v("Actioned By:")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.subTask.actioned_by || "NA"))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex space-x-2" }, [
          _c("h4", { staticClass: "font-semibold" }, [_vm._v("Completed By:")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.subTask.completed_by || "NA"))])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "relative overflow-auto pb-1" }, [
        _c(
          "div",
          {
            staticClass:
              "grid rounded-lg h-12 grid-cols-12 border bg-white items-center ml-16"
          },
          [
            _c("div", { staticClass: " col-span-1 flex justify-center" }, [
              _c("span", {
                staticClass: "w-6 h-6 mr-2 flex cursor-pointer",
                attrs: { name: "subtask-trigger-" + _vm.subTask.id },
                domProps: { innerHTML: _vm._s(_vm.subTaskIcon()) },
                on: {
                  click: function($event) {
                    return _vm.updateSubTaskStatus(
                      _vm.stageId,
                      _vm.taskIndex,
                      _vm.subTaskIndex
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-span-3" }, [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("h4", { staticClass: "text-gray-700" }, [
                    _vm._v(
                      _vm._s(_vm.subTask.code) + " " + _vm._s(_vm.subTask.name)
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.subTask.managers, function(user) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-dark"
                      },
                      [
                        _c("span", {
                          staticClass: "font-semibold text-xs",
                          domProps: {
                            innerHTML: _vm._s(_vm.makeInitials(user.name))
                          }
                        })
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.subTask.staff, function(user) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-light"
                      },
                      [
                        _c("span", {
                          staticClass: "font-semibold text-xs",
                          domProps: {
                            innerHTML: _vm._s(_vm.makeInitials(user.name))
                          }
                        })
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-span-5" }, [
              _c(
                "span",
                {
                  staticClass: "ml-4 flex items-center gap-1",
                  class: _vm.subTask.result ? "text-gray-600" : "text-gray-400"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.subTask.resultExpanded = !_vm.subTask
                            .resultExpanded
                        }
                      }
                    },
                    [
                      !_vm.subTask.resultExpanded
                        ? _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-blue-500",
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.subTask.result
                          ? _vm.subTask.result.length > 33
                            ? _vm.subTask.result.slice(0, 36) + "..."
                            : _vm.subTask.result
                          : "No Result Recorded Yet"
                      ) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-span-2" }, [
              _vm.subTask.completed_at
                ? _c("span", { staticClass: "text-gray-400 text-sm" }, [
                    _vm._v("Completed " + _vm._s(_vm.subTask.completed_at))
                  ])
                : _c("span", {})
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-span-1 flex justify-center pr-4" }, [
              _c("span", { staticClass: "text-right" }, [
                _vm.subTask.learning_topic_id ||
                _vm.subTask.learning_sub_topic_id
                  ? _c(
                      "a",
                      {
                        staticClass: "text-blue-500",
                        attrs: {
                          href:
                            "/client/" +
                            _vm.opportunityId +
                            "/" +
                            (_vm.subTask.learning_sub_topic_id
                              ? "learning_sub_topics"
                              : "learning_topics") +
                            "/" +
                            (_vm.subTask.learning_sub_topic_id
                              ? _vm.subTask.learning_sub_topic_id
                              : _vm.subTask.learning_topic_id),
                          target: "_blank"
                        }
                      },
                      [_vm._v("\n              Learn More\n            ")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.subTask.description
                ? _c(
                    "div",
                    {
                      staticClass: "flex items-center flex-shrink-0",
                      on: {
                        click: function($event) {
                          return _vm.updateSubTask(
                            _vm.stageId,
                            _vm.taskIndex,
                            _vm.subTaskIndex,
                            "showDescription",
                            !_vm.subTask.showDescription
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-6 h-6 text-blue-500 cursor-pointer",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d:
                                "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _vm.subTask.resultExpanded
          ? _c(
              "div",
              {
                staticClass:
                  "p-3 ml-16 bg-gray-200 text-gray-800 rounded-bl rounded-br"
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subTask.result,
                      expression: "subTask.result"
                    }
                  ],
                  staticClass: "w-full py-2 px-4",
                  attrs: {
                    autofocus: "true",
                    rows: "4",
                    placeholder: "Sub-task result"
                  },
                  domProps: { value: _vm.subTask.result },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.subTask, "result", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "flex justify-end space-x-4" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 text-brand-dark cursor-pointer",
                      attrs: {
                        fill: "none",
                        stroke: "currentColor",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateSubTask(
                            _vm.stageId,
                            _vm.taskIndex,
                            _vm.subTaskIndex,
                            "result",
                            _vm.subTask.result
                          )
                        }
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M5 13l4 4L19 7"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 text-red-400",
                      attrs: {
                        fill: "none",
                        stroke: "currentColor",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateSubTask(
                            _vm.stageId,
                            _vm.taskIndex,
                            _vm.subTaskIndex,
                            "resultExpanded",
                            false
                          )
                        }
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M6 18L18 6M6 6l12 12"
                        }
                      })
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subTask.showDescription
          ? _c(
              "div",
              {
                staticClass:
                  "p-3 ml-16 bg-gray-700 text-white rounded-bl rounded-br"
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.subTask.description) + "\n      "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subTask.bookings.length > 0
          ? _c(
              "div",
              _vm._l(_vm.subTask.bookings, function(booking) {
                return _c(
                  "div",
                  {
                    key: booking.id,
                    staticClass:
                      "grid grid-cols-12 border-b text-start bg-blue-50 ml-24"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-2 border-l col-span-1 flex justify-center"
                      },
                      [
                        _c("span", {
                          staticClass: "w-6 h-6 mr-2 flex cursor-pointer",
                          domProps: {
                            innerHTML: _vm._s(_vm.bookingIcon(booking.status))
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-2 col-span-8" }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            id:
                              "process_sub_task_booking_" +
                              booking.id +
                              "_title"
                          }
                        },
                        [_vm._v(_vm._s(booking.title + " Booking"))]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "p-2 col-span-2 text-gray-400 text-sm" },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                "Schedule date: " +
                                  _vm.formatDate(booking.start)
                              ) +
                              " "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-2 border-r col-span-1 flex justify-right justify-center text-sm"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "text-blue-500",
                            attrs: {
                              "data-turbo-frame": "modal",
                              href:
                                "/staff/opportunities/" +
                                _vm.id +
                                "/bookings/" +
                                booking.id +
                                "/edit"
                            }
                          },
                          [_vm._v("\n              View\n            ")]
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }