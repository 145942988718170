var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "duration-300",
        "leave-active-class": "duration-200"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.manuallySigningModalOpen,
              expression: "manuallySigningModalOpen"
            }
          ],
          staticClass: "relative z-10",
          attrs: {
            "aria-labelledby": "modal-title",
            role: "dialog",
            "aria-modal": "true"
          }
        },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "duration-300 ease-out",
                "enter-from-class": "opacity-0",
                "enter-to-class": "opacity-100",
                "leave-active-class": "duration-200 ease-in",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.manuallySigningModalOpen,
                    expression: "manuallySigningModalOpen"
                  }
                ],
                staticClass:
                  "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fixed z-10 inset-0 overflow-y-auto" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
              },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      "enter-active-class": "duration-300 ease-out",
                      "enter-from-class":
                        "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                      "enter-to-class":
                        "opacity-100 translate-y-0 sm:scale-100",
                      "leave-active-class": "ease-in duration-200",
                      "leave-from-class":
                        "opacity-100 translate-y-0 sm:scale-100",
                      "leave-to-class":
                        "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.manuallySigningModalOpen,
                            expression: "manuallySigningModalOpen"
                          }
                        ],
                        staticClass:
                          "relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-green-500",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-6 h-6",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.undoSign
                            ? _c("div", { staticClass: "mt-3 sm:mt-5" }, [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "text-lg leading-6 font-medium text-gray-900",
                                      attrs: { id: "modal-title" }
                                    },
                                    [_vm._v("Undo Manual Document Signing")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _c("p", { staticClass: "text-gray-500" }, [
                                      _vm._v(
                                        "Please confirm that you would like to UNDO the manual signing of this document"
                                      )
                                    ])
                                  ])
                                ])
                              ])
                            : _c("div", { staticClass: "mt-3 sm:mt-5" }, [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "text-lg leading-6 font-medium text-gray-900",
                                      attrs: { id: "modal-title" }
                                    },
                                    [_vm._v("Manual Document Signing")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _c("p", { staticClass: "text-gray-500" }, [
                                      _vm._v(
                                        "Please confirm that you would like to nominate this document as being manually signed."
                                      )
                                    ])
                                  ])
                                ])
                              ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                          },
                          [
                            _vm.undoSign
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-white bg-brand-dark w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.undoManuallySign }
                                  },
                                  [_vm._v("Undo Manual Signing")]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-white bg-brand-dark w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.manuallySign }
                                  },
                                  [_vm._v("Sign Manually")]
                                ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:mt-0 sm:col-start-1 sm:text-sm",
                                attrs: { type: "button" },
                                on: { click: _vm.closeManuallySigningModal }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }