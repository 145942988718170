var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-4 bg-gray-50 rounded" }, [
    _c(
      "a",
      {
        staticClass: "text-brand-dark font-semibold mr-4",
        attrs: { href: "/staff/purchase_orders/" + _vm.po.id }
      },
      [
        _vm.po.name || _vm.po.request_date
          ? _c("span", [
              _vm._v(
                _vm._s(_vm.po.name ? _vm.po.name + " - " : "") +
                  _vm._s(_vm.po.request_date || "No Date")
              )
            ])
          : _c("span", [
              _vm._v(
                _vm._s(_vm.po.id) +
                  " - " +
                  _vm._s(_vm.doc_type_labels[_vm.po.doc_type])
              )
            ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center flex-auto space-x-4 mt-2" }, [
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Quote Status")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quote_status,
                expression: "quote_status"
              }
            ],
            staticClass: "form-select w-full",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.quote_status = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.quote_statuses, function(val, key) {
            return _c(
              "option",
              { key: "quote-status-" + key, domProps: { value: key } },
              [_vm._v("\n          " + _vm._s(val) + "\n        ")]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("TSH Estimate")
        ]),
        _vm._v(" "),
        _vm.po.estimated_total
          ? _c("p", [_vm._v("$" + _vm._s(_vm.po.estimated_total))])
          : _c("p", [_vm._v("No estimates")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Quote Total")
        ]),
        _vm._v(" "),
        _vm.po.quote_total
          ? _c("p", [
              _vm._v("$" + _vm._s(_vm.po.quote_total)),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.quoteDiff) } })
            ])
          : _c("p", [_vm._v("No quotes")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Actual Price")
        ]),
        _vm._v(" "),
        _vm.po.actual_total
          ? _c("p", [
              _vm._v("$" + _vm._s(_vm.po.actual_total)),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.estimatedDiff) } })
            ])
          : _c("p", [_vm._v("No invoices/actuals")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Job Status")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.job_status,
                expression: "job_status"
              }
            ],
            staticClass: "form-select w-full",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.job_status = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.job_statuses, function(val, key) {
            return _c(
              "option",
              { key: "quote-status-" + key, domProps: { value: key } },
              [_vm._v("\n          " + _vm._s(val) + "\n        ")]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("h4", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Invoice Status")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoice_status,
                expression: "invoice_status"
              }
            ],
            staticClass: "form-select w-full",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.invoice_status = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.invoice_statuses, function(val, key) {
            return _c(
              "option",
              { key: "quote-status-" + key, domProps: { value: key } },
              [_vm._v("\n          " + _vm._s(val) + "\n        ")]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }