import Vue from 'vue'
import App from './learning_steps/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const templateBuilder = document.getElementById('learning-step-builder');

  if (templateBuilder) {
    new Vue({
      el: '#learning-step-builder',
      render: h => h(App)
    }).$mount();
  }
});
