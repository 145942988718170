import axios from 'axios';

document.addEventListener('turbo:load', function() {
    const prelimVideoSeenButton = document.getElementById('prelim-watched');
    const watchPrelimVideo = document.getElementById('watch-prelim-video');

    const welcomeVideoSeenButton = document.getElementById('welcome-watched');
    const watchWelcomeVideo = document.getElementById('watch-welcome-video');

    if(prelimVideoSeenButton) {
        prelimVideoSeenButton.addEventListener('click', function () {
            const url = `/client_progress/${this.dataset.id}`;
            const csrf = document.getElementsByName('csrf-token')[0].content;
            const headers = { 'x-csrf-token': csrf };

            axios.patch(url, { type: 'videos_seen', value: { prelim: true } }, { headers }).then(resp => {
                if (resp.data.success) {
                    const prelimVideoModal = document.getElementById('prelim-video-modal');
                    prelimVideoModal.classList.add('hidden');

                    const prelimVideo = document.getElementById('prelim-video');
                    prelimVideo.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
                }
            });
        });
    }
    if(watchPrelimVideo) {
        watchPrelimVideo.addEventListener('click', function (e) {
            e.preventDefault();
            const prelimVideoModal = document.getElementById('prelim-video-modal');
            prelimVideoModal.classList.remove('hidden');
        })
    }

    if(welcomeVideoSeenButton) {
        welcomeVideoSeenButton.addEventListener('click', function () {
            const url = `/client_progress/${this.dataset.id}`;
            const csrf = document.getElementsByName('csrf-token')[0].content;
            const headers = { 'x-csrf-token': csrf };

            axios.patch(url, { type: 'videos_seen', value: { welcome: true } }, { headers }).then(resp => {
                if (resp.data.success) {
                    const welcomeVideoModal = document.getElementById('welcome-video-modal');
                    welcomeVideoModal.classList.add('hidden');

                    const welcomeVideo = document.getElementById('welcome-video');
                    welcomeVideo.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
                }
            });
        });
    }
    if(watchWelcomeVideo) {
        watchWelcomeVideo.addEventListener('click', function (e) {
            e.preventDefault();
            const welcomeVideoModal = document.getElementById('welcome-video-modal');
            welcomeVideoModal.classList.remove('hidden');
        })
    }
});
