var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full bg-white rounded-md shadow divide-y divide-gray-200"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "divide-y divide-gray-200" },
        [
          _c(
            "draggable",
            {
              staticClass: "divide-y divide-gray-200",
              on: {
                start: function($event) {
                  _vm.drag = true
                },
                end: function($event) {
                  _vm.drag = false
                  _vm.saveSelections()
                }
              },
              model: {
                value: _vm.selections,
                callback: function($$v) {
                  _vm.selections = $$v
                },
                expression: "selections"
              }
            },
            _vm._l(_vm.selections, function(s) {
              return _c("div", { key: s.id, staticClass: "flex" }, [
                _c("div", { staticClass: "p-3 whitespace-no-wrap w-1/2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "font-semibold text-gray-800",
                      attrs: { href: "/staff/selections/" + s.id + "/edit" }
                    },
                    [_vm._v(_vm._s(s.name))]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "p-3 whitespace-no-wrap w-1/4" }, [
                  _c(
                    "a",
                    {
                      staticClass: "font-semibold text-gray-800",
                      attrs: { href: "/staff/selections/" + s.id + "/edit" }
                    },
                    [_vm._v(_vm._s(s.options_count))]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "p-3 whitespace-no-wrap text-gray-500 w-1/4" },
                  [
                    _vm.archived
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: "/staff/selections/" + s.id + "/unarchive",
                              "data-method": "patch",
                              "data-confirm":
                                "Are you sure you want to restore this selection?"
                            }
                          },
                          [_vm._v("Restore")]
                        )
                      : _c(
                          "a",
                          {
                            attrs: {
                              href: "/staff/selections/" + s.id,
                              "data-method": "delete",
                              "data-confirm":
                                "Are you sure you want to archive this selection?"
                            }
                          },
                          [_vm._v("Archive")]
                        )
                  ]
                )
              ])
            }),
            0
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "border-b-gray-200 flex" }, [
      _c(
        "div",
        {
          staticClass:
            "p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md w-1/2"
        },
        [_vm._v("Selection")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 w-1/4"
        },
        [_vm._v("Options Count")]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md w-1/4"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }