var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded-md border-4 border-dashed border-brand-light flex items-center justify-center h-24",
      class: { dragging: _vm.isDragging },
      on: {
        dragover: function($event) {
          $event.preventDefault()
          return _vm.dragover($event)
        },
        dragenter: function($event) {
          $event.preventDefault()
          return _vm.dragover($event)
        },
        drop: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onDrop($event)
        },
        dragleave: function($event) {
          $event.preventDefault()
          return _vm.dragleave($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          class: { hidden: _vm.uploadInProgress },
          on: { click: _vm.openFileBrowser }
        },
        [
          _vm._t("default", [
            _c("i", { staticClass: "text-gray-500 text-sm" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: _vm.multiple }
      }),
      _vm._v(" "),
      _vm.uploads.length > 0
        ? _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(_vm.uploads, function(file) {
              return _c(
                "div",
                { key: file.file.filename, staticClass: "list-group-item" },
                [
                  _vm._t(
                    "progress",
                    [
                      _c("i", [
                        _vm._v("Uploading: " + _vm._s(file.file.filename))
                      ])
                    ],
                    { file: file, progress: _vm.progress }
                  )
                ],
                2
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }