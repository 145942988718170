import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "inputField", "label", "inputGroup", "labelGroup", "destroyElement", "taskFormInputGroup", "taskFormLabelGroup" ];
    static values = {
        checklistId: {type: String, default: ""}
    };

    connect() {
        this.editingValue ? this.startEditing() : this.finishEditing();
    }

    startEditing(e) {
        this.labelGroupTarget.classList.add('hidden');
        this.inputGroupTarget.classList.remove('hidden');
        this.inputFieldTarget.focus();
        this.inputFieldTarget.select();
    }

    finishEditing(e) {
            this.inputGroupTarget.classList.add('hidden');
            this.labelGroupTarget.classList.remove('hidden');
    }

    cancel(e) {
        this.inputFieldTarget.value = this.labelTarget.innerText;
        this.finishEditing();
    }

    loseFocus() {
        setTimeout(()=>{
            this.finishEditing();
        }, 100)
    }

    save() {
        this.labelTarget.innerHTML = this.inputFieldTarget.value;
        this.finishEditing();
    }

    destroy() {
        this.element.classList.add('hidden');
        this.destroyElementTarget.value = true;
    }

}
