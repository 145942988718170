import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";
import Gantt from "../custom/frappe-gantt";
import moment from "moment";

let gantt = null;
let tasks = null;
let openTaskIds = [];
let allTaskIds = [];
let get_data_url = '/staff/opportunities/opportunity_id/bookings/get_data';
let update_nav_url = '/staff/opportunities/opportunity_id/bookings/update_gantt_nav';
let filter = false;
let draggable = true;

async function updateGanttNav(refreshNav) {
    if (!refreshNav) {return true;}

    const request = new FetchRequest("POST", `${update_nav_url}?filter=${filter}`,{responseKind: "turbo-stream", body: JSON.stringify({open_task_ids: openTaskIds}),});
    const response = await request.perform();

    if (response.ok) {
        return true;
    }
    return false;
}

function loadData(refreshNav = false) {
    updateGanttNav(refreshNav).then(r => {
        const csrf = document.getElementsByName('csrf-token')[0].content;
        fetch(get_data_url + `?filter=${filter}`, {
            method: 'POST',
            body: JSON.stringify({open_task_ids: openTaskIds}),
            headers: { accept: 'application/json', 'x-csrf-token': csrf, "Content-Type": "application/json"}
        }).then((   response) => response.json())
            .then(data => {
                tasks = data;
                if (gantt === null) {
                    createGantt();
                } else {
                    gantt.refresh(tasks);
                }
                setLoading(false);
            });
    });
}

function setLoading(b_loading) {
    if(b_loading) {
        document.body.classList.add('loading');
    } else {
        document.body.classList.remove('loading');
    }
}

function createGantt() {
    gantt = new Gantt("#gantt", tasks, {
        header_height: 50,
        column_width: 30,
        step: 24,
            view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
        bar_height: 20,
        bar_corner_radius: 3,
        arrow_curve: 5,
        padding: 18,
        view_mode: 'Day',
        date_format: 'DD-MM-YYYY',
        language: 'en', // or 'es', 'it', 'ru', 'ptBr', 'fr', 'tr', 'zh', 'de', 'hu',
        draggable: draggable,
        custom_popup_html: function(task) {
            return `
            <div style="width: 300px;">
                <div class="title">${task.name}</div>
                <div class="subtitle">${moment(task.start).format('lll')} - ${moment(task.end).format('lll')}</div>
            </div>`

        },

        on_click: function (task) {
        },
        on_date_change: async function(task, start, end) {
            setLoading(true);

            let ignoreCalendar = false;

            if (!task.children) {
                if (start.getDay() == 0 || start.getDay() == 6 || end.getDay() == 0 || end.getDay() == 6) {
                    ignoreCalendar = confirm("Allow this event to start / end on a weekend?");
                }
            }

            const request = new FetchRequest("POST", `/staff/opportunities/1559/bookings/update_gantt_dates?id=${task.id}&start=${start}&end=${end}&ignore=${ignoreCalendar}`, {responseKind: "turbo-stream"});
            const response = await request.perform();


            loadData();

        },
        on_progress_change: function(task, progress) {
            /*
            const request = new FetchRequest("POST", `/staff/opportunities/1559/bookings/update_gantt_progress?id=${task.id}&progress=${progress}`,{responseKind: "turbo-stream"});
            const response = request.perform();
            if (response.ok) {
                setTimeout(() => this.loading = false, 200)
            }
            */
        },
        on_view_change: function(mode) {
        }
    });

}


export default class extends Controller {

    static values = {
        opportunityId: {
            type: String,
            default: 'hello' },
        draggable: {
            type: Boolean,
            default: true } };

    static targets = [ "task" ];

    connect() {
        this.taskTargets.forEach(el => allTaskIds.push(el.id));

        draggable = this.draggableValue;
        get_data_url = get_data_url.replace('opportunity_id', this.opportunityIdValue);
        update_nav_url = update_nav_url.replace('opportunity_id', this.opportunityIdValue);
        loadData();

        // MUTATION OBSERVER TO UPDATE GANTT AFTER TURBO-STREAM UPDATE
        const targetNode = document.getElementById('turbo-update');
        const observer = new MutationObserver(() => {
            loadData();
        }).observe(targetNode, { childList: true});
    }

    changeView(event) {
        gantt.change_view_mode(event.target.dataset.view);
    }

    filterGantt(event) {
        setLoading(true);
        let selected = event.target.selectedOptions[0].value;
        if (selected == "bookings") {
            filter = true;
            openTaskIds = [...allTaskIds];
        } else {
            filter = false;
            openTaskIds = [];
        }

        loadData(true);
    }

    expandStage(event) {
        setLoading(true);
        let taskContainerElement = document.getElementById(`container_${event.currentTarget.id}`);

        if (openTaskIds.includes(event.currentTarget.id)) {
        } else {
            openTaskIds.push(event.currentTarget.id);
            taskContainerElement.classList.remove('hidden');
        }

        loadData();
    }

    toggleStage(event) {
        setLoading(true);
        let stageIndex = openTaskIds.indexOf(event.currentTarget.id);
        let taskContainerElement = document.getElementById(`container_${event.currentTarget.id}`);

        if (openTaskIds.includes(event.currentTarget.id)) {
            openTaskIds.splice(stageIndex, 1);
            taskContainerElement.classList.add('hidden');
            event.currentTarget.innerText = '+' + event.currentTarget.innerText.substring(1)
        } else {
            openTaskIds.push(event.target.id);
            taskContainerElement.classList.remove('hidden');
            event.currentTarget.innerText = '-' + event.currentTarget.innerText.substring(1)
        }

        loadData();
    }

    expandAll(event) {
        setLoading(true);
        openTaskIds = [...allTaskIds];
        openTaskIds.forEach(id => {
            try {
                let taskName = document.getElementById(`${id}`);
                if(taskName.innerText[0] == '+') { taskName.innerText = '-' + taskName.innerText.substring(1) }
                let taskContainerElement = document.getElementById(`container_${id}`);
                taskContainerElement.classList.remove('hidden');
            } catch(error) { }

        });

        loadData();
    }

    contractAll(event) {
        setLoading(true);
        openTaskIds = [];
        allTaskIds.forEach(id => {
            try {
                let taskName = document.getElementById(`${id}`);
                if(taskName.innerText[0] == '-') { taskName.innerText = '+' + taskName.innerText.substring(1) }
                let taskContainerElement = document.getElementById(`container_${id}`);
                taskContainerElement.classList.add('hidden');
            } catch(error) { }
        });

        loadData();
    }

    disconnect() {
    }
}
