document.addEventListener('turbo:load', () => {
  const clientDocsEl = document.getElementById('client-documents');

  if (clientDocsEl) {
    const prevVersionLinks = document.getElementsByClassName('previous-versions');
    const currentVersionLinks = document.getElementsByClassName('current-version');

    [].forEach.call(prevVersionLinks, el => {
      el.addEventListener('click', e => {
        e.preventDefault();

        // Find all documents for this category or sub-category and show them
        const parentCategory = document.getElementById(el.dataset.target);
        const allDocuments = parentCategory.getElementsByClassName('hidden');
        const showActive = parentCategory.getElementsByClassName('current-version')[0];

        [].forEach.call(allDocuments, doc => {
          doc.classList.remove('hidden');
        });

        el.classList.add('hidden');
        showActive.classList.remove('hidden');
      });
    });

    [].forEach.call(currentVersionLinks, el => {
      el.addEventListener('click', e => {
        e.preventDefault();

        // Hide all non-current documents for this category
        const parentCategory = document.getElementById(el.dataset.target);
        const oldDocuments = parentCategory.getElementsByClassName('old-doc');
        const showPrevious = parentCategory.getElementsByClassName('previous-versions')[0];

        [].forEach.call(oldDocuments, el => {
          el.classList.add('hidden');
        });

        el.classList.add('hidden');
        showPrevious.classList.remove('hidden');
      });
    });
  }
});