<template>
  <transition enter-active-class="duration-300" leave-active-class="duration-200">
    <div v-show="signingModalOpen" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="signingModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </transition>
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div v-show="signingModalOpen" class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <div slot="reference" class="text-green-500">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>
                  </div>
                </div>
                <div class="mt-3 sm:mt-5">
                  <div class="text-center">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Request Document Signing</h3>
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">{{ documentSummary }}</h3>
                    <div class="mt-2">
                      <p class="text-gray-500">Send this document for electronic signing to both clients and The Shed House team using DocuSign.</p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="label">The Shed House Signers<sup>*</sup></label>
                    <select @change="selectStaff" :value="staffSelected" class="input full sm:text-sm sm:leading-5 form-select">
                      <option :value="null">Leave Blank If Not Required</option>
                      <option v-for="user in staffUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                    </select>
                    <div v-for="staff in signingStaffContacts" :key="staff.id" class="w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center">
                      <h4>{{ staff.name }}</h4>
                      <a href="#" @click.prevent="removeStaffSigner(staff.id)" class="text-white">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                      </a>
                    </div>
                     <p class="mt-1 text-sm text-gray-400">Remove all staff signers (if any) above if no staff signing is required for this document.</p>
                  </div>
                  <div v-if="signingBuilderContacts.length == 0" class="mt-4">
                    <label class="label">Client Signers<sup>*</sup></label>
                    <select @change="selectClient" :value="clientSelected" class="input full sm:text-sm sm:leading-5 form-select">
                      <option :value="null">Nominate clients for signing</option>
                      <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                    </select>
                    <div v-for="client in signingClientContacts" :key="client.id" class="w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center">
                      <h4>{{ client.name }}</h4>
                      <a href="#" @click.prevent="removeClientSigner(client.id)" class="text-white">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                      </a>
                    </div>
                    <p class="mt-1 text-sm text-gray-400">Only client contacts with emails are shown. Please edit the project's client contact list to see more options.</p>
                  </div>
                  <div v-if="signingClientContacts.length == 0" class="mt-4">
                    <label class="label">Builder Signers<sup>*</sup></label>
                    <select @change="selectBuilder" :value="builderSelected" class="input full sm:text-sm sm:leading-5 form-select">
                      <option :value="null">Nominate builders for signing</option>
                      <option v-for="builder in builders" :key="builder.id" :value="builder.id">{{ builder.name }}</option>
                    </select>
                    <div v-for="builder in signingBuilderContacts" :key="builder.id" class="w-full bg-brand-dark text-white rounded-lg px-4 py-1 flex justify-between mt-1 items-center">
                      <h4>{{ builder.name }}</h4>
                      <a href="#" @click.prevent="removeBuilderSigner(builder.id)" class="text-white">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                      </a>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="label">Email Subject<sup>*</sup></label>
                    <input :value="emailSubject" @change="e => setEmailSubject(e.target.value)" type="text" class="input w-full" placeholder="Email subject line sent to all recipients">
                  </div>
                  <div class="mt-4">
                    <label class="label">Email Body Blurb</label>
                    <textarea :value="emailBody" @change="e => setEmailBody(e.target.value)" class="input w-full" placeholder="Optional blurb added to the signing request emails"></textarea>
                  </div>
                  <div class="mt-4">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input :checked="signingDocumentFinalised" @change="e => setDocumentFinalised(e.target.checked)" id="confirm-for-signing" name="confirm_for_signing" type="checkbox" class="focus:brand-dark h-4 w-4 text-brand-dark border-gray-300 rounded" />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="confirm-for-signing" class="font-medium text-gray-700">Document Is Finalised</label>
                        <p id="confirm-for-signing-description" class="text-gray-500">Document is approved and ready for e-signing with client and staff signing areas correctly nominated</p>
                      </div>
                    </div>
                  </div>
                  <div v-show="outstandingReview" class="mt-4">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input :checked="signingReviewNotRequired" @change="e => setReviewNotRequired(e.target.checked)" id="confirm-no-review" name="confirm_for_signing" type="checkbox" class="focus:brand-dark h-4 w-4 text-brand-dark border-gray-300 rounded" />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="confirm-no-review" class="font-medium text-gray-700">Document Does NOT Require Review</label>
                        <p id="confirm-no-review-description" class="text-gray-500">This document has not been reviewed. Are you sure you want to send it for signing?</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button v-if="signingLoading" disabled type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-light text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Sending...</button>
                <button v-else-if="signingCompleted" disabled type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Request Sent!</button>
                <button v-else @click="sendSigningRequest" :disabled="!canSend" :class="canSend ? 'text-white bg-brand-dark' : 'text-gray-500 bg-gray-300 cursor-not-allowed'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Send For Signing</button>
                <button @click="closeSigningModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      staffSelected: null,
      clientSelected: null,
      builderSelected: null,
    }
  },
  computed: {
    ...mapState([
      'signingModalOpen',
      'staffUsers',
      'clients',
      'builders',
      'signingLoading',
      'signingCompleted',
      'signingClientContacts',
      'signingBuilderContacts',
      'signingStaffContacts',
      'signingReviewNotRequired',
      'signingDocumentFinalised',
      'emailSubject',
      'emailBody'
    ]),
    ...mapGetters([
      'documentForSigning'
    ]),
    outstandingReview() {
      if (this.documentForSigning) {
        return this.documentForSigning.review_status !== 'Reviewed';
      }
    },
    documentSummary() {
      if (this.documentForSigning) {
        return this.documentForSigning.file_name;
      }
    },
    canSend() {
      return (this.signingReviewNotRequired || !this.outstandingReview) &&
        this.signingDocumentFinalised &&
          (this.signingClientContacts.length > 0 || this.signingBuilderContacts.length > 0) &&
        this.emailSubject.length > 0;
    }
  },
  methods: {
    ...mapMutations([
      'closeSigningModal',
      'addStaffSigner',
      'removeStaffSigner',
      'addClientSigner',
      'addBuilderSigner',
      'removeClientSigner',
      'removeBuilderSigner',
      'setReviewNotRequired',
      'setDocumentFinalised',
      'setEmailSubject',
      'setEmailBody'
    ]),
    ...mapActions([
      'sendSigningRequest'
    ]),
    selectClient(e) {
      this.addClientSigner(e.target.value);
    },
    selectBuilder(e) {
      this.addBuilderSigner(e.target.value);
    },
    selectStaff(e) {
      this.addStaffSigner(e.target.value);
    }
  }
}
</script>
