import Vue from 'vue'
import App from './opp_learning_centre/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const templateBuilder = document.getElementById('opp-learning-centre-app');

  if (templateBuilder) {
    new Vue({
      el: '#opp-learning-centre-app',
      render: h => h(App)
    }).$mount();
  }
});