var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isNotRestricted,
          expression: "isNotRestricted"
        }
      ],
      staticClass: "bg-white pt-2"
    },
    [
      _c("div", { staticClass: "flex justify-between items-center" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between  w-full px-6" },
          [
            _c(
              "h3",
              {
                class: [
                  _vm.isNotRequired
                    ? ["text-xl", "font-semibold", "text-brand-dark"]
                    : [
                        "text-xl",
                        "font-semibold",
                        "text-brand-dark",
                        "line-through"
                      ]
                ]
              },
              [_vm._v(_vm._s(_vm.subCategory.name))]
            ),
            _vm._v(" "),
            _c("DocumentDropzone", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isNotRequired && _vm.userPermissions.canEdit,
                  expression: "isNotRequired && userPermissions.canEdit"
                }
              ],
              attrs: {
                id: _vm.subCategory.id,
                "category-type": "sub",
                "requires-signing": _vm.subCategory.requires_signing
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userPermissions.canEdit,
                    expression: "userPermissions.canEdit"
                  }
                ],
                staticClass: "flex items-center mt-6"
              },
              [
                _c(
                  "button",
                  {
                    class: [
                      _vm.isNotRequired ? "bg-brand-dark" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    ],
                    attrs: {
                      type: "button",
                      role: "switch",
                      "aria-checked": "false",
                      "aria-labelledby": "annual-billing-label"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleNotRequired(_vm.subCategory.id)
                      }
                    }
                  },
                  [
                    _c("span", {
                      class: [
                        _vm.isNotRequired ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      ],
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "flex flex-col ml-3 text-lg",
                    attrs: { id: "annual-billing-label" }
                  },
                  [
                    _c(
                      "span",
                      {
                        class: [_vm.isNotRequired ? "text-lg" : "text-gray-400"]
                      },
                      [_vm._v("Is Required?")]
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "my-2",
          attrs: { id: "sub-category-documents-<%= sub_cat.id %>" }
        },
        [
          _c("draggable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.subCategory.documents.length === 0,
                expression: "subCategory.documents.length === 0"
              }
            ],
            attrs: { list: [], group: "documents" },
            on: { change: _vm.handleDrop }
          }),
          _vm._v(" "),
          _c("DocumentTable", {
            attrs: {
              documents: _vm.subCategory.documents,
              "sub-category-id": _vm.subCategory.id,
              "show-all": _vm.showAll
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.subCategory.allow_multiple_documents &&
                    _vm.subCategory.documents.length > 1,
                  expression:
                    "!subCategory.allow_multiple_documents && subCategory.documents.length > 1"
                }
              ],
              staticClass: "flex justify-center py-1"
            },
            [
              _vm.showAll
                ? _c(
                    "a",
                    {
                      staticClass:
                        "text-brand-dark flex space-x-1 items-center",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-4 h-4",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d:
                                "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Hide Previous Versions")])
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "text-brand-dark flex space-x-1 items-center",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-4 h-4",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d:
                                "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Show Previous Versions")])
                    ]
                  )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }