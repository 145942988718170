<template>
  <div class="flex-1">
    <vue-dropzone
      class="client-document-dropzone"
      :id="`doc-dropzone-${categoryType}-${id}`"
      :options="dropzoneOptions"
      :duplicateCheck="true"
      :useCustomSlot="true"
    >
      <div class="dropzone-custom-content">
        <div class="drag-text">
          <h4 class="text-gray-300 text-left pl-4">Drag and Drop Documents Or Click Here</h4>
        </div>
      </div>
    </vue-dropzone>
    <div class="d-flex flex-column align-items-center mb-1 w-100" id="documents-preview"></div>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import { DirectUpload } from '@rails/activestorage';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  props: ['categoryType', 'id', 'requiresSigning'],
  data() {
    return {
      dropzoneOptions: {
        url: '/rails/active_storage/direct_uploads',
        autoQueue: false,
        timeout: null,
        acceptedFiles: '',
        maxFilesize: 256,
        dataType: 'json',
        accept: (file, done) => {
          return this.processFileUpload(file, done);
        },
        previewTemplate: this.previewTemplate()
      },
    }
  },
  computed: {
    ...mapState([
      'opportunityId'
    ])
  },
  methods: {
    ...mapMutations([
      'addDocument'
    ]),
    processFileUpload(file, done) {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

      upload.create((err, blob) => {
        if (err) {
          console.log(err);
        } else {
          // Use the signed ID to save the new file to the DB
          const url = `/staff/opportunities/${this.opportunityId}/client_documents.json`;
          const csrfToken = document.getElementsByName('csrf-token')[0].content;
          const headers = new Headers({
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken
          });
          let body = {
            client_document: {
              document: blob.signed_id,
              opportunity_id: this.opportunityId,
              requires_signing: this.requiresSigning
            }
          }
          if (this.categoryType === 'sub') {
            body['client_document']['client_document_sub_category_id'] = this.id;
          } else {
            body['client_document']['client_document_category_id'] = this.id;
          }

          fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .then(res => {
            // Remove the loading indicator
            const filePreview = `//span[text()='${file.name}']`;
            const matchingElement = document.evaluate(filePreview, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            matchingElement.closest('.client-document__loading').remove();
            const zone = document.getElementById(`doc-dropzone-${this.categoryType}-${this.id}`);
            zone.classList.remove('dz-started');
            zone.classList.add('dz-completed');

            // Add to the document
            this.addDocument(res);

            // Keep Dropbox happy
            return done();
          });
        }
      });
    },
    previewTemplate() {
      return `<div class="client-document__loading dz-preview dz-file-preview dz-processing dz-success dz-complete mb-2">
        <div class="row document-item ml-0 mt-0 mr-0 p-0 w-100">
          <div class="col-md-11 m-0 p-0">
            <div class="dz-details d-flex align-items-center justify-content-start h-100 w-100">
              <i class="text-info fas fa-spinner fa-spin mr-2"></i>
              <span data-dz-name></span>
            </div>
          </div>
        </div>
      </div>`;
    }
  }
}
</script>