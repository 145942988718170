var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex items-center" }, [
    _c(
      "a",
      {
        staticClass: "text-blue font-semibold",
        attrs: { href: "javascript:;" },
        on: { click: _vm.openFilePicker }
      },
      [_vm._v("\n    Upload Document\n  ")]
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "hidden",
      attrs: {
        id: "filePicker-" + _vm.taskIndex + "-" + _vm.subTaskIndex,
        type: "file"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }