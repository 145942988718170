import Vue from 'vue';
import App from './trades/app.vue';
import store from './trades/store';
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const trades = document.getElementById('opportunity-trades-vue');

  if (trades) {
    new Vue({
      el: '#opportunity-trades-vue',
      store: store,
      render: h => h(App)
    }).$mount();
  }
});