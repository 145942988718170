import Vue from 'vue'
import App from './client_documents/App.vue'
import TurbolinksAdapter from 'vue-turbolinks';
import Vue2ClickOutside from 'vue2-click-outside';
import store from './client_documents/store';

Vue.use(TurbolinksAdapter);
Vue.use(Vue2ClickOutside);

document.addEventListener('turbo:load', () => {
  const calendar = document.getElementById('client-documents-app');

  if (calendar) {
    new Vue({
      el: '#client-documents-app',
      store: store,
      render: h => h(App)
    }).$mount();
  }
});