
import Vue from 'vue'
import App from './client_document_category_order/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const documentBuilder = document.getElementById('client-document-index');

  if (documentBuilder) {
    new Vue({
      el: '#client-document-index',
      render: h => h(App)
    }).$mount();
  }
});