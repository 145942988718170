var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("tippy", { attrs: { to: "subtask-trigger-" + _vm.subTask.id } }, [
        _c("div", { staticClass: "flex space-x-2" }, [
          _c("h4", { staticClass: "font-semibold" }, [_vm._v("Actioned By:")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.subTask.actioned_by || "NA"))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex space-x-2" }, [
          _c("h4", { staticClass: "font-semibold" }, [_vm._v("Completed By:")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.subTask.completed_by || "NA"))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "p-2 bg-white ml-6 mt-2 rounded shadow-xl hover:shadow transition-shadow duration-100 ease-in-out hover:bg-blue-100 hover:text-gray-100"
        },
        [
          _c("div", { staticClass: "flex items-center justify-between" }, [
            _c("div", { staticClass: "flex items-center w-full mr-2" }, [
              _c("span", {
                staticClass: "w-6 h-6 mr-2 flex cursor-pointer",
                attrs: { name: "subtask-trigger-" + _vm.subTask.id },
                domProps: { innerHTML: _vm._s(_vm.subTaskIcon()) },
                on: {
                  click: function($event) {
                    return _vm.updateSubTaskStatus(
                      _vm.stageId,
                      _vm.taskIndex,
                      _vm.subTaskIndex
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("h4", { staticClass: "text-gray-700 w-1/4" }, [
                _vm._v(_vm._s(_vm.subTask.name))
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ml-4 flex items-center gap-1 w-2/4",
                  class: _vm.subTask.result ? "text-gray-600" : "text-gray-400"
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.subTask.result
                          ? _vm.subTask.result.length > 33
                            ? _vm.subTask.result.slice(0, 36) + "..."
                            : _vm.subTask.result
                          : "No Result Recorded Yet"
                      ) +
                      "\n            "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.subTask.resultExpanded = !_vm.subTask
                            .resultExpanded
                        }
                      }
                    },
                    [
                      !_vm.subTask.resultExpanded
                        ? _c(
                            "svg",
                            {
                              staticClass: "w-5 h-5 text-blue-500",
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.subTask.completed_at
                ? _c(
                    "span",
                    { staticClass: "text-gray-400 text-smtext-right" },
                    [_vm._v("Completed " + _vm._s(_vm.subTask.completed_at))]
                  )
                : _c("span", { staticClass: "w-1/4" })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex items-center flex-shrink-0 justify-end space-x-2"
              },
              [
                _vm.subTask.description
                  ? _c(
                      "div",
                      {
                        staticClass: "flex items-center flex-shrink-0",
                        on: {
                          click: function($event) {
                            _vm.subTask.showDescription = !_vm.subTask
                              .showDescription
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "w-6 h-6 text-blue-500 cursor-pointer",
                            attrs: {
                              fill: "none",
                              stroke: "currentColor",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d:
                                  "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex items-center ml-2 tooltip",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.copyURLToClipboard($event)
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    ref: "copyURLTooltip",
                    staticClass: "tooltiptext text-xs",
                    staticStyle: {
                      "min-width": "100px",
                      "margin-left": "-50px",
                      padding: "4px"
                    }
                  },
                  [_vm._v("copy link")]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "w-6 h-6 text-green-500 cursor-pointer",
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      "stroke-width": "1.5",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d:
                          "M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.subTask.resultExpanded
        ? _c(
            "div",
            {
              staticClass: "p-3 bg-gray-200 text-gray-800 rounded-bl rounded-br"
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subTask.result,
                    expression: "subTask.result"
                  }
                ],
                staticClass: "w-full py-2 px-4",
                attrs: {
                  autofocus: "true",
                  rows: "4",
                  placeholder: "Sub-task result"
                },
                domProps: { value: _vm.subTask.result },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.subTask, "result", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex justify-end space-x-4" }, [
                _c(
                  "svg",
                  {
                    staticClass: "w-5 h-5 text-brand-dark cursor-pointer",
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    },
                    on: {
                      click: function($event) {
                        return _vm.updateSubTask(
                          _vm.stageId,
                          _vm.taskIndex,
                          _vm.subTaskIndex,
                          "result",
                          _vm.subTask.result
                        )
                      }
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M5 13l4 4L19 7"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "w-5 h-5 text-red-400",
                    attrs: {
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    },
                    on: {
                      click: function($event) {
                        return _vm.updateSubTask(
                          _vm.stageId,
                          _vm.taskIndex,
                          _vm.subTaskIndex,
                          "resultExpanded",
                          false
                        )
                      }
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M6 18L18 6M6 6l12 12"
                      }
                    })
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.subTask.showDescription
        ? _c(
            "div",
            {
              staticClass:
                "p-3 ml-6 bg-gray-700 text-white rounded-bl rounded-br"
            },
            [_vm._v("\n      " + _vm._s(_vm.subTask.description) + "\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }