import { Controller } from "@hotwired/stimulus";
import { debounce } from '../pages/utilities';

export default class extends Controller {
    initialize() {
        this.submit = debounce(this.submit.bind(this), 250)
    }

    submit() {
        this.element.requestSubmit();
    }
}