import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js"

const URL = "/staff/template_project_rooms"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            sort: false,
            animation: 150,
            handle: '.item-handle',
            group: {
                name: 'shared',
                pull: 'clone',
                put: false
            }
        })
    }

    end(event) {
        let roomContainer = event.item.closest('.template-project-template-room-container');
        if(roomContainer === null) return;

        let id = event.item.dataset.id;
        event.item.id = "new_template_project_room"; //we change this so we can target it with a turbo_stream

        let data = new FormData();
        data.append('template_project_room[position]', event.newIndex + 1);
        data.append('template_project_room[template_project_id]', roomContainer.dataset.id);
        data.append('template_project_room[template_room_id]', id)

        post(URL, {body: data, responseKind: 'turbo-stream'});

    }

}