var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex space-x-4" }, [
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Category Name*")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
          attrs: { type: "text", placeholder: "Learning category name" },
          domProps: { value: _vm.name },
          on: {
            change: function($event) {
              _vm.name = $event.target.value
              _vm.saveLearningCategory()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Available For")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass:
              "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select",
            domProps: { value: _vm.category_type },
            on: {
              change: function($event) {
                _vm.category_type = $event.target.value
                _vm.saveLearningCategory()
              }
            }
          },
          [
            _c("option", { attrs: { value: "all_users" } }, [_vm._v("All")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "clients_only" } }, [
              _vm._v("Clients Only")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "staff_only" } }, [
              _vm._v("Staff Only")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between my-4" }, [
      _c("h3", { staticClass: "font-bold text-xl text-gray-800" }, [
        _vm._v("Learning Steps")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
          attrs: {
            href:
              "/staff/learning_categories/" + this.id + "/learning_steps/new"
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-6 h-6 mr-1",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          ),
          _vm._v("\n      Add New Learning Step\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "table",
        {
          staticClass:
            "table-auto w-full table-collapse bg-white rounded-md shadow divide-y divide-gray-200"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            { staticClass: "divide-y divide-gray-200" },
            [
              _c(
                "draggable",
                {
                  staticClass: "flex flex-col",
                  on: {
                    start: function($event) {
                      _vm.drag = true
                    },
                    end: function($event) {
                      _vm.drag = false
                      _vm.saveLearningCategory()
                    }
                  },
                  model: {
                    value: _vm.learning_steps,
                    callback: function($$v) {
                      _vm.learning_steps = $$v
                    },
                    expression: "learning_steps"
                  }
                },
                _vm._l(_vm.shownSteps, function(step) {
                  return _c(
                    "tr",
                    {
                      key: step.id,
                      staticClass: "flex flex-auto justify-between"
                    },
                    [
                      _c("td", { staticClass: "p-3 whitespace-no-wrap" }, [
                        _c(
                          "a",
                          {
                            staticClass: "font-semibold text-gray-800",
                            attrs: {
                              href: "/staff/learning_steps/" + step.id + "/edit"
                            }
                          },
                          [_vm._v(_vm._s(step.name))]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "p-3 whitespace-no-wrap text-gray-500" },
                        [
                          step.discarded_at
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/staff/learning_steps/" +
                                      step.id +
                                      "/unarchive",
                                    "data-method": "patch",
                                    "data-confirm":
                                      "Are you sure you want to restore this learning step?"
                                  }
                                },
                                [_vm._v("Restore")]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/staff/learning_steps/" + step.id,
                                    "data-method": "delete",
                                    "data-confirm":
                                      "Are you sure you want to archive this learning step?"
                                  }
                                },
                                [_vm._v("Archive")]
                              )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex items-center justify-center mt-8 text-blue-500" },
        [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.showKept = !_vm.showKept
                }
              }
            },
            [_vm._v("Toggle Archived Learning Steps")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "border-b-gray-200" }, [
        _c(
          "th",
          {
            staticClass:
              "p-3 text-left sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tl-md"
          },
          [_vm._v("Name")]
        ),
        _vm._v(" "),
        _c("th", {
          staticClass:
            "p-3 sticky top-0 font-semibold text-gray-500 bg-gray-200 p-0 rounded-tr-md"
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }