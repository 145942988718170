<template>
  <div>
    <tippy :to="`task-trigger-${task.id}`" v-if="task.sub_tasks.length === 0">
      <div class="flex space-x-2">
        <h4 class="font-semibold">Actioned By:</h4>
        <p>{{ task.actioned_by || 'NA' }}</p>
      </div>
      <div class="flex space-x-2">
        <h4 class="font-semibold">Completed By:</h4>
        <p>{{ task.completed_by || 'NA' }}</p>
      </div>
    </tippy>
    <div class="p-2 bg-white rounded mt-2 shadow-xl rounded cursor-pointer text-gray-800 flex items-center justify-between px-4 py-2 hover:shadow transition-shadow duration-100 ease-in-out hover:bg-blue-100 hover:text-gray-100">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center w-full mr-2">
          <span :name="`task-trigger-${task.id}`"
                class="w-6 h-6 mr-2 flex cursor-pointer"
                v-html="taskIcon()"
                @click="updateTaskStatus(stageId, taskIndex)"
                >
          </span>
          <h4 class="font-semibold text-gray-700 cursor-pointer w-1/4" @click.prevent="task.toggled = !task.toggled">{{ task.name }}</h4>
          <span class="flex items-center gap-1 w-2/4" :class="task.result ? 'text-gray-600' : 'text-gray-400'">
            {{ task.result ? (task.result.length > 33 ? task.result.slice(0, 36) + '...' : task.result) : 'No Result Recorded Yet' }}
            <a href="#" @click.prevent="toggleTask(stageId, taskIndex, 'resultExpanded', !task.resultExpanded)">
              <svg v-if="!task.resultExpanded" class="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
            </a>
          </span>
        </div>
        <div class="flex items-center flex-shrink-0 justify-end space-x-2">
          <!-- <document-upload :task="task" :task-index="taskIndex" :add-document="addDocument" :stage-id="stageId"></document-upload> -->
          <span v-if="task.completed_at" class="text-gray-400 text-sm text-right">Completed {{ task.completed_at }}</span>

<!--      REMOVED AS REQUESTED JB 09.12.22
          <span v-else-if="task.status === 'in_progress' && task.sub_tasks.length === 0" class="ml-4 flex items-center text-right">
            <span class="mr-2 text-sm">Target Date </span>
            <input type="date" :value="task.target_date" class="text-sm" @change.prevent="updateTask(stageId, taskIndex, 'target_date', $event.target.value)">
          </span>
-->
          <span @click.prevent="task.toggled = !task.toggled" class="text-gray-500 text-sm cursor-pointer" :class="subTaskCountColour(task.sub_tasks)">{{ task.toggled ? '-' : '+' }} {{ task.sub_tasks.length }} sub-tasks</span>
          <div class="flex items-center ml-2" v-if="task.description" @click="task.showDescription = !task.showDescription">
            <svg class="w-6 h-6 text-blue-500 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>
        </div>
        <div class="flex items-center ml-2 tooltip" @click.prevent="copyURLToClipboard">
          <span ref="copyURLTooltip" class="tooltiptext text-xs" style="min-width: 100px; margin-left: -50px; padding: 4px;">copy link</span>
          <svg class="w-6 h-6 text-green-500 cursor-pointer" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" /></svg>
        </div>
      </div>
      <!-- <div class="mt-4 space-y-2">
        <document
          v-for="(document, index) in documents"
          :document="document"
          :index="index"
          :taskIndex="taskIndex"
          :stage-id="stageId"
          :key="`document-${document.id}-${index}`"
          :update-documents="updateDocuments"
        >
        </document>
      </div> -->
    </div>
    <div v-if="task.resultExpanded" class="p-3 bg-gray-200 text-gray-800 rounded-bl rounded-br">
      <textarea autofocus="true" rows="4" v-model="task.result" @change="updateTask(stageId, taskIndex, 'result', $event.target.value)" class="w-full py-2 px-4" placeholder="Task result"></textarea>
      <div class="flex justify-end space-x-4">
        <svg class="w-5 h-5 text-brand-dark cursor-pointer" @click="updateTask(stageId, taskIndex, 'result', task.result)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
        <svg class="w-5 h-5 text-red-400" @click="updateTask(stageId, taskIndex, 'resultExpanded', false)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </div>
    </div>
    <div v-if="task.showDescription" class="p-3 bg-gray-700 text-white rounded-bl rounded-br">
      {{ task.description }}
    </div>
    <div v-if="task.toggled" class="mt-2">
      <sub-task
        v-for="(subTask, subTaskIndex) in task.sub_tasks"
        :key="`sub-task-${subTask.id}-${subTask.status}-${task.updated_at}`"
        :sub-task="subTask"
        :sub-task-index="subTaskIndex"
        :update-sub-task-status="updateSubTaskStatus"
        :stage-id="stageId"
        :task-index="taskIndex"
        :update-sub-task="updateSubTask"
        :add-document="addDocument"
        :update-documents="updateDocuments"
        :task-id="task.id"
        :opportunity-id="opportunityId"
      ></sub-task>
    </div>
  </div>
</template>
<script>
import subTask from './sub_task';
import taskDocument from './document.vue';
import documentUpload from './document-upload.vue';
import document from './document.vue';

export default {
  components: { subTask, taskDocument, documentUpload, document },
  props: [
    'task', 'taskIndex', 'updateTaskStatus', 'updateSubTaskStatus',
    'toggleTask', 'stageId', 'updateTask', 'updateSubTask',
    'addDocument', 'updateDocuments', 'documents', 'opportunityId'
  ],
  methods: {
    copyURLToClipboard: function() {
      let url = `https://app.theshedhouse.com.au/client/${this.opportunityId}/process?client_stage=${this.stageId}&client_task=${this.task.id}`;

      navigator.clipboard.writeText(url);
      this.$refs.copyURLTooltip.textContent = "link copied!";
      this.$refs.copyURLTooltip.style.backgroundColor = "#3b82f6";

      setTimeout(()=>{
        this.$refs.copyURLTooltip.textContent = "copy link";
        this.$refs.copyURLTooltip.style.backgroundColor = "#555555";
      }, 1000);
    },

    subTaskCountColour: function(subTasks) {
      let status;

      if (subTasks.length > 0) {
        if (subTasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
          status = 'text-blue-500';
        } else if (subTasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
          status = 'text-green-500';
        } else {
          status = 'text-gray-300';
        }
      } else {
        status = 'text-gray-500';
      }

      return status;
    },

    taskIcon: function() {
      let status = this.task.status;

      if (this.task.sub_tasks.length > 0) {
        // Get the status by the combined statuses of the sub-tasks
        if (this.task.sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
          status = 'completed';
        } else if (this.task.sub_tasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
          status = 'in_progress';
        } else {
          status = 'incomplete';
        }
      }

      switch (status) {
        case 'completed':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        case 'in_progress':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'not_applicable':
          return '<svg class="bg-gray-200 text-gray-500 w-6 h-6 rounded-xl flex items-center justify-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    },
  }
}
</script>
