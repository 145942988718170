var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FullCalendar", {
    ref: "fullCalendar",
    attrs: { options: _vm.calendarOptions }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }