import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { FetchRequest, patch } from "@rails/request.js"

export default class extends Controller {
    static values = {
        model: String,
        put: { type: String, default: 'group' } // allow items to be dropped from this group name
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
           onEnd: this.end.bind(this),
           animation: 150,
           handle: '.item-handle',
           group: {
               name: 'group',
               put: this.putValue
           },
       })
    }

    async end(event) {
        let itemContainer = event.item.closest('.item-container');
        let id = event.item.dataset.id;

        let data = new FormData();
        data.append('position', event.newIndex + 1);
        data.append('inventory_sub_category_id', itemContainer.dataset.id);


        const request = new FetchRequest("patch", `/staff/${this.modelValue}/${id}/move`,{responseKind: "turbo-stream", body: data});
        const response = await request.perform();

    }
}