document.addEventListener('turbo:load', () => {
  const handyContacts = document.getElementById('handy-contacts');


  if (handyContacts) {
    const search = document.getElementById('handy-search');
    const results = document.getElementsByClassName('handy-result');

    search.addEventListener('input', (event) => {
      const searchString = event.target.value.trim().toLowerCase();

      Array.from(results).forEach(el => {
        if (searchString.length === 0) return el.style.display = 'block';

        const contactName = el.dataset.name.trim().toLowerCase();

        if (contactName.indexOf(searchString) > -1) {
          el.style.display = 'block';
        } else {
          el.style.display = 'none';
        }
      });
    });
  }
});