import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import axios from "axios";

document.addEventListener('turbo:load', () => {
  var gallery = document.getElementById('photo-gallery');

  if (gallery) {
    var msnry = new Masonry(gallery, {
      // options
      itemSelector: '.grid-item',
      gutter: '.gutter-sizer',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });

    imagesLoaded(gallery, () => {
      msnry.layout();
    });
  }

  const galleryForms = document.getElementsByClassName('gallery-form');

  if (galleryForms) {
    galleryForms.forEach(form => {
      form.addEventListener('submit', function (e) {
        e.preventDefault();
        const name = this.querySelector('#photo_gallery_name').value;
        const description = this.querySelector('#photo_gallery_description').value;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = {'x-csrf-token': csrf};
        const saveAlert = this.querySelector('.image-save-alert');

        axios.patch(this.action, { photo_gallery: { name: name, description: description } }, { headers }).then(resp => {
          saveAlert.classList.remove('hidden');

          setTimeout(function() {
            saveAlert.classList.add('hidden');
          }, 3000);
        })
      });
    });
  }
});
