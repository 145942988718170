let lastFocusedId = null;

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

document.addEventListener("turbo:request-start", function () {
  const element = document.querySelector(
    "[data-turbo-preserve-focus]:focus"
  );

  if (element) {
    lastFocusedId = element.id;
  }
});

document.addEventListener("turbo:load", function () {
  if (!lastFocusedId) return;

  const element = document.getElementById(lastFocusedId);
  lastFocusedId = null;

  if (!element) return;

  element.focus();
});