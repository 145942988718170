import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

// Connects to data-controller="popper"
export default class extends Controller {
    static targets = ["button", "menu"];
    static values = {
        placement: { type: String, default: "bottom" },
        offset: { type: Array, default: [0, 8] },
    };

    connect() {
        this.popperInstance = createPopper(this.buttonTarget, this.menuTarget, {
            placement: this.placementValue,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: this.offsetValue,
                    },
                },
            ],
        });
    }

    toggleMenu() {
        this.menuTarget.classList.toggle("hidden");
        this.popperInstance.update();
    }

    hide(event) {
        this.menuTarget.classList.add("hidden")
    }

    hideMenu(e) {
        const buttonClicked = this.buttonTarget.contains(e.target)

        if(!buttonClicked) {
            this.menuTarget.classList.add('hidden')
        }
    }

}


