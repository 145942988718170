import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {FetchRequest} from "@rails/request.js";

const URL = "/staff/inventory_sub_categories/:id/move?record_type=:record_type"

export default class extends Controller {
    static values = {
        recordType: String,
    }

    connect() {
       this.sortable = Sortable.create(this.element, {
           onEnd: this.end.bind(this),
           animation: 150,
           handle: '.group-handle',
           group: {
               name: 'category',
               put: 'category'
           },
       })
    }

    async end(event) {
        let groupContainer = event.item.closest('.group-container');
        let id = event.item.dataset.id;

        let data = new FormData();
        data.append('position', event.newIndex + 1);
        data.append('inventory_category_id', groupContainer.dataset.id);

        URL.replace(':id',id)

        const request = new FetchRequest("patch", URL.replace(':id',id).replace(':record_type', this.recordTypeValue) ,{responseKind: "turbo-stream", body: data});
        const response = await request.perform();

    }
}