var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex" }, [
    _c(
      "div",
      { staticClass: "flex-auto" },
      [
        _c(
          "div",
          {
            staticClass:
              "p-4 rounded bg-gray-100 items-center mb-6 flex justify-between"
          },
          [
            _vm.workflow_stage != "construction"
              ? _c(
                  "div",
                  { staticClass: "flex space-x-4 items-center flex-wrap" },
                  _vm._l(_vm.pinnedResults, function(result) {
                    return _c(
                      "div",
                      { key: result.key, staticClass: "text-center" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "font-semibold text-sm text-gray-500"
                          },
                          [_vm._v(_vm._s(result.name))]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-gray-800" }, [
                          _vm._v(
                            _vm._s(result.result ? result.result : "Not Set")
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-col" }, [
              _c("label", { staticClass: "font-semibold text-gray-800" }, [
                _vm._v("Show Tasks For")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.workflow_stage_id,
                      expression: "workflow_stage_id"
                    }
                  ],
                  staticClass: "form-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.workflow_stage_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.workflow_stages, function(stage) {
                  return _c(
                    "option",
                    { key: stage.id, domProps: { value: stage.id } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(stage.name) + "\n          "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.shownStages, function(stage) {
          return _c(
            "div",
            { key: stage.id, staticClass: "bg-gray-100 rounded mt-4" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "rounded cursor-pointer text-gray-800 flex items-center justify-between px-4 py-2 shadow-xl hover:shadow transition-shadow duration-100 ease-in-out hover:bg-blue-100",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      stage.toggled = !stage.toggled
                    }
                  }
                },
                [
                  _c("h3", { staticClass: "font-semibold text-xl" }, [
                    _vm._v(_vm._s(stage.name))
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.stageIcon(stage)) }
                  })
                ]
              ),
              _vm._v(" "),
              stage.toggled
                ? _c(
                    "div",
                    { staticClass: "p-4" },
                    _vm._l(stage.tasks, function(task, taskIndex) {
                      return _c("task", {
                        key:
                          "task-" +
                          task.id +
                          "-" +
                          task.status +
                          "-" +
                          task.resultExpanded,
                        attrs: {
                          task: task,
                          documents: task.client_documents,
                          "stage-id": stage.id,
                          "task-index": taskIndex,
                          "toggle-task": _vm.toggleTask,
                          "update-task-status": _vm.updateTaskStatus,
                          "update-sub-task-status": _vm.updateSubTaskStatus,
                          "update-task": _vm.updateTask,
                          "update-sub-task": _vm.updateSubTask,
                          "add-document": _vm.addDocumentToTask,
                          "update-documents": _vm.updateDocuments,
                          "opportunity-id": _vm.id
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }