<template>
  <div class="relative">
    <div class="absolute top-2 right-2 flex flex-col items-center">
      <div v-if="document_type === 'proposal'" @click="toggleCurrent" title="Set as current proposal" class="current-proposal text-white p-1 cursor-pointer rounded-full" :class="document.active ? ' bg-yellow-300' : ' bg-brand-dark'">
        <svg class="w-6 h-6" :fill="document.active ? 'white' : 'none'" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
      </div>
      <a :href="`/staff/documents/${document.id}/edit`" title="Edit document" class="text-white bg-blue-500 rounded-full p-1 cursor-pointer mt-1">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
      </a>
    </div>
    <div class="rounded shadow-xl">
      <div v-if="document.file_type.includes('pdf')" class="p-2 rounded text-center">
        <div class="bg-red-700 rounded h-40 flex items-center justify-center text-white">
          <svg aria-hidden="true" focusable="false" class="h-16 w-16 text-white" width="25" height="25" viewBox="0 0 25 25">
            <path d="M9.766 8.295c-.691-1.843-.539-3.401.747-3.726 1.643-.414 2.505.938 2.39 3.299-.039.79-.194 1.662-.537 3.148.324.49.66.967 1.055 1.51.17.231.382.488.629.757 1.866-.128 3.653.114 4.918.655 1.487.635 2.192 1.685 1.614 2.84-.566 1.133-1.839 1.084-3.416.249-1.141-.604-2.457-1.634-3.51-2.707a13.467 13.467 0 0 0-2.238.426c-1.392 4.051-4.534 6.453-5.707 4.572-.986-1.58 1.38-4.206 4.914-5.375.097-.322.185-.656.264-1.001.08-.353.306-1.31.407-1.737-.678-1.059-1.2-2.031-1.53-2.91zm2.098 4.87c-.033.144-.068.287-.104.427l.033-.01-.012.038a14.065 14.065 0 0 1 1.02-.197l-.032-.033.052-.004a7.902 7.902 0 0 1-.208-.271c-.197-.27-.38-.526-.555-.775l-.006.028-.002-.003c-.076.323-.148.632-.186.8zm5.77 2.978c1.143.605 1.832.632 2.054.187.26-.519-.087-1.034-1.113-1.473-.911-.39-2.175-.608-3.55-.608.845.766 1.787 1.459 2.609 1.894zM6.559 18.789c.14.223.693.16 1.425-.413.827-.648 1.61-1.747 2.208-3.206-2.563 1.064-4.102 2.867-3.633 3.62zm5.345-10.97c.088-1.793-.351-2.48-1.146-2.28-.473.119-.564 1.05-.056 2.405.213.566.52 1.188.908 1.859.18-.858.268-1.453.294-1.984z" fill="white" fill-rule="nonzero" />
          </svg>
        </div>
        <a :href="document.download_url" class="block text-md text-gray-800 font-medium mt-1 hover:text-blue-500">{{ document.name }}</a>
      </div>
      <div v-else-if="document.file_type.includes('image')" class="p-2 text-center">
        <img :src="document.image_url">
        <a :href="document.download_url" class="block text-md text-gray-800 font-medium mt-1 hover:text-blue-500">{{ document.name }}</a>
      </div>
      <div v-else class="p-2 rounded text-center">
        <div class="bg-blue-400 rounded h-40 flex items-center justify-center text-white">
          <svg aria-hidden="true" focusable="false" class="h-16 w-16 text-white" width="25" height="25" viewBox="0 0 25 25">
            <path d="M4.5 7h13a.5.5 0 1 1 0 1h-13a.5.5 0 0 1 0-1zm0 3h15a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1zm0 3h15a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1zm0 3h10a.5.5 0 1 1 0 1h-10a.5.5 0 1 1 0-1z" fill="white" fill-rule="nonzero" />
          </svg>
        </div>
        <a :href="document.download_url" class="block text-md text-gray-800 font-medium mt-1 hover:text-blue-500">{{ document.name }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['document', 'document_type', 'updateDocument'],
  methods: {
    toggleCurrent: function() {
      this.updateDocument(this.document, { active: !this.document.active });
    }
  }
}
</script>