var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex items-center justify-between pb-4 mb-12 border-b border-gray-300"
      },
      [
        _c("div", [
          _c(
            "h1",
            {
              staticClass:
                "text-3xl font-extrabold text-gray-900 tracking-tight"
            },
            [_vm._v(_vm._s(_vm.purchase_order.supplier_name))]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "text-gray-600 mb-4" }, [
            _vm._v(_vm._s(_vm.purchase_order.supplier_address))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex space-x-2 items-center" }, [
            _c("input", {
              staticClass: "input",
              attrs: { type: "text", placeholder: "Name or Description" },
              domProps: { value: _vm.purchase_order.name },
              on: {
                change: function($event) {
                  return _vm.updateOrder("name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input",
              attrs: { type: "text", placeholder: "Project Address" },
              domProps: { value: _vm.purchase_order.address },
              on: {
                change: function($event) {
                  return _vm.updateOrder("address", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "select",
              {
                staticClass: "form-select",
                domProps: { value: _vm.purchase_order.doc_type },
                on: {
                  change: function($event) {
                    return _vm.updateOrder("doc_type", $event.target.value)
                  }
                }
              },
              [
                _c("option", { attrs: { value: "purchase_order" } }, [
                  _vm._v("Purchase Order")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "quote_request" } }, [
                  _vm._v("Quote Request")
                ])
              ]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "input",
              attrs: { type: "date" },
              domProps: { value: _vm.purchase_order.request_date },
              on: {
                change: function($event) {
                  return _vm.updateOrder("request_date", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "a",
            {
              staticClass:
                "text-brand-dark font-semibold flex items-center space-x-2",
              attrs: {
                href: "/staff/purchase_orders/" + _vm.id + "/printable",
                target: "_blank"
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-5 h-5",
                  attrs: {
                    fill: "none",
                    stroke: "currentColor",
                    viewBox: "0 0 24 24",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d:
                        "M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("Print Version")])
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "text-red-500 font-semibold flex items-center space-x-2 mt-4",
              attrs: {
                href: "/staff/purchase_orders/" + this.id,
                "data-method": "DELETE",
                "data-confirm":
                  "Are you sure you want to delete this purchase order?"
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "w-5 h-5",
                  attrs: {
                    fill: "none",
                    stroke: "currentColor",
                    viewBox: "0 0 24 24",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d:
                        "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("Delete")])
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "w-full" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "draggable",
          {
            attrs: { tag: "tbody", handle: ".drag" },
            on: { end: _vm.endDrag },
            model: {
              value: _vm.purchase_order.line_items,
              callback: function($$v) {
                _vm.$set(_vm.purchase_order, "line_items", $$v)
              },
              expression: "purchase_order.line_items"
            }
          },
          [
            _vm._l(_vm.purchase_order.line_items, function(item, index) {
              return _c(
                "tr",
                { key: item.id + "-" + item.index, staticClass: "drag" },
                [
                  _c("td", [
                    _c("span", { staticClass: "text-gray-300" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "h-6 w-6",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            stroke: "currentColor"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M4 6h16M4 10h16M4 14h16M4 18h16"
                            }
                          })
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "text" },
                      domProps: { value: item.item_code },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "item_code",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "text" },
                      domProps: { value: item.label },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "label",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "select",
                      {
                        staticClass: "form-select w-full",
                        domProps: { value: item.unit },
                        on: {
                          change: function($event) {
                            return _vm.updateLineItem(
                              index,
                              "unit",
                              $event.target.value
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "ea" } }, [
                          _vm._v("Ea")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "sqm" } }, [
                          _vm._v("Sqm")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "lm" } }, [
                          _vm._v("Lm")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "hours" } }, [
                          _vm._v("Hours")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.estimate_quantity },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "estimate_quantity",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.estimate_unit_price },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "estimate_unit_price",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("$" + _vm._s(_vm.estimateTotal(item)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.quote_quantity },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "quote_quantity",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.quote_unit_price },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "quote_unit_price",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("$" + _vm._s(_vm.quotedTotal(item)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.quantity },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "quantity",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      staticClass: "input full sm:text-sm sm:leading-5",
                      attrs: { type: "number", step: "2", min: "0" },
                      domProps: { value: item.unit_price },
                      on: {
                        change: function($event) {
                          return _vm.updateLineItem(
                            index,
                            "unit_price",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v("$" + _vm._s(_vm.actualTotal(item)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(_vm.estMinusActual(item)) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(_vm.quoteMinusActual(item)) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(_vm.estMinusQuote(item)) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "text-red-500",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeLine(item)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "h-6 w-6",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d:
                                  "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pt-2" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-lg font-semibold text-gray-800 col-span-6"
                  },
                  [_vm._v("Totals")]
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2", attrs: { colspan: "3" } }),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v(_vm._s(_vm.estimateQuantityTotal))
                ])
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v("$" + _vm._s(_vm.estimatedTotal))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v(_vm._s(_vm.quoteQuantityTotal))
                ])
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v("$" + _vm._s(_vm.quoteTotal))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v(_vm._s(_vm.actualQuantityTotal))
                ])
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", { staticClass: "text-lg font-semibold text-center" }, [
                  _vm._v("$" + _vm._s(_vm.total))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", {
                  staticClass: "text-lg font-semibold text-center",
                  domProps: { innerHTML: _vm._s(_vm.totalEstimateDiff) }
                })
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", {
                  staticClass: "text-lg font-semibold text-center",
                  domProps: { innerHTML: _vm._s(_vm.totalQuoteDiff) }
                })
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "pt-2" }, [
                _c("p", {
                  staticClass: "text-lg font-semibold text-center",
                  domProps: { innerHTML: _vm._s(_vm.totalEstQuoteDiff) }
                })
              ])
            ])
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "inline-block mt-4 text-brand-dark font-semibold",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.addLineItem($event)
          }
        }
      },
      [_vm._v("\n    Add Line Item\n  ")]
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "text-gray-800 font-bold mt-8" }, [
      _vm._v("Notes")
    ]),
    _vm._v(" "),
    _c("textarea", {
      staticClass:
        "mt-1 p-2 shadow-sm block w-full focus:border-brand-dark sm:text-sm border border-gray-300 rounded-md",
      attrs: { rows: "4" },
      domProps: { value: _vm.purchase_order.notes },
      on: {
        change: function($event) {
          return _vm.updateOrder("notes", $event.target.value)
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Code")]),
        _vm._v(" "),
        _c("th", [_vm._v("Item Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Unit")]),
        _vm._v(" "),
        _c("th", [_vm._v("Est. Qty")]),
        _vm._v(" "),
        _c("th", [_vm._v("Est. Unit Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Est. Total")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quote Qty")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quote Unit Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quote Total")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actual Qty")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actual Unit Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actual Total")]),
        _vm._v(" "),
        _c("th", [_vm._v("Est. minus actual")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quote minus actual")]),
        _vm._v(" "),
        _c("th", [_vm._v("Est. minus quote")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }