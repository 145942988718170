import Vue from 'vue'
import App from './opp_client_progress/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(TurbolinksAdapter)
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

document.addEventListener('turbo:load', () => {
  const opportunity = document.getElementById('opp-client-progress-vue');

  if (opportunity) {
    new Vue({
      el: '#opp-client-progress-vue',
      render: h => h(App)
    }).$mount();
  }
});