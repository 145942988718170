import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

const URL = "/staff/part_inventory_items/:id"

export default class extends Controller {

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            animation: 150,
            handle: '.item-handle',
            group: {
                name: 'shared',
                put: 'shared'
            },
        })
    }

    end() {
        return;
    }
}