import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "destroyElement"];

    connect() {
    }


    destroy() {
        this.element.classList.add('hidden');
        this.destroyElementTarget.value = true;
    }

}
