<template>
  <div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import momentPlugin, { toMoment } from '@fullcalendar/moment';
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    let urlParams = new URLSearchParams(window.location.search);
    const selected_user = urlParams.get('user_id');

    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        initialView: 'dayGridDay',
        dateClick: this.handleDateClick,
        eventContent: this.handleEventContent,
        events: (selected_user === null) ? "/staff/calendar.json" : `/staff/calendar.json?user_id=${selected_user}`
      }
    }
  },
  methods: {
    handleDateClick: function(e) {
      return;
      const path = `/staff/events/new?start=${e.dateStr}`;
      Turbo.visit(path);
    },
    handleEventContent: function(args) {
      let elem = document.createElement('div');
      elem.classList.add('w-full')
      elem.classList.add('calendar-event')

      const start = moment(args.event.start).format('h:mma');
      const end = moment(args.event.end).format('h:mma');

      switch(args.event._def.extendedProps.type)
      {
        case 'event':
          elem.innerHTML = `
            <a href="/staff/events/${args.event.id}" class="flex flex-col bg-brand-light p-2 rounded hover:w-full">
              <div class="flex items-center overflow-x-hidden">
                <span class="text-gray-500 text-sm mr-1">${start}</span>
                <span class="font-semibold text-gray-800">${args.event.title}</div>
              </div>
              <div class="calendar-event__hidden">
                <p class="text-gray-600 text-sm mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-sm mt-1">
                <span class="font-semibold text-gray-800">Time: </span>${start} - ${end}<br />
                  <span class="font-semibold text-gray-800">Host: </span>${args.event._def.extendedProps.created_by ? args.event._def.extendedProps.created_by : 'None'}<br />
                  <span class="font-semibold text-gray-800">Guests: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                  <span class="font-semibold text-gray-800">Location: </span>${args.event._def.extendedProps.location ? args.event._def.extendedProps.location : 'None Set'}<br />
                </p>
              </div>
            </div>
          `
          break;
        case 'booking':
          elem.innerHTML = `
            <a href="/staff/opportunities/${args.event._def.extendedProps.opportunity_id}/bookings/" class="flex flex-col bg-green-200 p-2 rounded hover:w-full">
              <div class="flex items-center overflow-x-hidden">
                <span class="text-gray-500 text-sm mr-1">${start}</span>
                <span class="font-semibold text-gray-800">${args.event.title}</div>
              </div>
              <div class="calendar-event__hidden">
                <p class="text-gray-600 text-sm mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-sm mt-1">
                <span class="font-semibold text-gray-800">Time: </span>${start} - ${end}<br />
                  <span class="font-semibold text-gray-800">Supplier: </span>${args.event._def.extendedProps.supplier ? args.event._def.extendedProps.supplier : 'None'}<br />
                  <span class="font-semibold text-gray-800">Users: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                </p>
              </div>
            </div>
          `
          break;
        case 'action_item':
          elem.innerHTML = `
            <a href="" class="flex flex-col bg-pink-200 p-2 rounded hover:w-full">
              <div class="flex items-center overflow-x-hidden">
                <span class="text-gray-500 text-sm mr-1">${start}</span>
                <span class="font-semibold text-gray-800">${args.event.extendedProps.description}</div>
              </div>
              <div class="calendar-event__hidden">
                <p class="text-gray-600 text-sm mt-1" style="white-space: pre-wrap;">${args.event._def.extendedProps.description}</p>
                <p class="text-gray-600 text-sm mt-1">
                <span class="font-semibold text-gray-800">Date: </span>${start}<br />
                  <span class="font-semibold text-gray-800">Supplier: </span>${args.event._def.extendedProps.supplier ? args.event._def.extendedProps.supplier : 'None'}<br />
                  <span class="font-semibold text-gray-800">Users: </span>${args.event._def.extendedProps.users.length > 0 ? args.event._def.extendedProps.users.map(u => u.name).join(', ') : 'None'}<br />
                </p>
              </div>
            </div>
          `
          break;
      }

      return { domNodes: [ elem ] };
    }
  }
}
</script>
