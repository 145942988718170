import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    addingTask = false;
    static targets = [ "inputField", "label", "inputGroup", "labelGroup"];
    static values = {
        checklistId: {type: String, default: ""},
    };

    connect() {
        this.inputFieldTarget.focus();
        this.inputFieldTarget.select();
    }

    toggleInputOn() {
        this.labelGroupTarget.classList.add('hidden');
        this.inputGroupTarget.classList.remove('hidden');
        this.inputFieldTarget.focus();
        this.inputFieldTarget.select();
    }

    toggleInputOff() {
        if(this.addingTask) { this.addingTask = false; return; } // don't change focus if add task button was pushed
        this.labelGroupTarget.classList.remove('hidden');;
        this.inputGroupTarget.classList.add('hidden');
    }

    cancel() {
        this.addingTask = false;
        this.toggleInputOff();
    }

    loseFocus() {
        setTimeout(()=>{
            this.toggleInputOff();
        }, 100)
    }

    async addChecklistTask() {
        this.addingTask = true; // used so we dont cancel after losing focus
        let tempTaskDescription = this.inputFieldTarget.value
        this.inputFieldTarget.focus();
        this.inputFieldTarget.value = "";

        const request = new FetchRequest("POST", `/staff/action_items/add_checklist_task?action_item_checklist_id=${this.checklistIdValue}&task_description=${tempTaskDescription}`,{responseKind: "turbo-stream"});
        const response = await request.perform();
        if (response.ok) {
            setTimeout(() => this.loading = false, 200)
        }
    }

}
