import { Sortable } from '@shopify/draggable';
import axios from 'axios';
document.addEventListener('turbo:load', () => {
    makeDraggableDocuments();
});

function makeDraggableDocuments() {
    let sortable = new Sortable(document.querySelectorAll('.multiple-draggable-container'), {
        draggable: '.draggable-item',
        handle: '.draggable-handle'
    });

    sortable.on('sortable:start', (event) => {
    });
    sortable.on('sortable:sort', () => console.log('sortable:sort'));
    sortable.on('sortable:sorted', (event) => console.log('sortable:sorted', event));
    sortable.on('sortable:stop', (event) => {
        var opportunityId = document.getElementById('staff-opportunity-management').dataset.opportunityId;
        const csrfToken = document.getElementsByName('csrf-token')[0].content;
        let url = `/staff/opportunities/${opportunityId}/client_documents/${event.dragEvent.data.originalSource.dataset.clientDocumentId}/update_client_category.js`
        let data = {
            client_document:
                {
                    client_document_category_id: event.newContainer.dataset.categoryId || null,
                    client_document_sub_category_id: event.newContainer.dataset.subCategoryId  || null,
                    position: event.newIndex + 1
                }
        }
        axios.put(url, data, { headers: { 'x-csrf-token': csrfToken } }).then(async resp => {
            await eval(resp.data);
            makeDraggableDocuments();
        })
    });
}
