import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js"

const URL = "/staff/recipe_ingredients"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            sort: false,
            animation: 150,
            handle: '.item-handle',
            group: {
                name: 'shared',
                pull: 'clone',
                put: false
            }
        })
    }

    end(event) {
        let recipeIngredientContainer = event.item.closest('.recipe-ingredient-container'); // we get the recipe_id from the container we are dropping the ingredient into
        if(recipeIngredientContainer === null) return;

        let id = event.item.dataset.id;
        event.item.id = "new_recipe_ingredient"; // we change this so we can target it with a turbo_stream

        let data = new FormData();
        data.append('recipe_ingredient[position]', event.newIndex + 1);
        data.append('recipe_ingredient[recipe_id]', recipeIngredientContainer.dataset.id);
        data.append('recipe_ingredient[ingredient_id]', id)

        post(URL, {body: data, responseKind: 'turbo-stream'});
    }

}