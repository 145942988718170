document.addEventListener('turbo:load', function() {
  const actionItemsIndex = document.getElementById('action-items-index');

  const filterToQueryString = (filterObj, overrides) => {
    const copy = Object.assign(filterObj, overrides || {}, );
    const searchParams = new URLSearchParams();

    Object.entries(copy).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length) {
        value.forEach(v => searchParams.append(key, v));
      } else if (value) {
        searchParams.set(key, value)
      }
    });

    searchParams.sort()
    return searchParams.toString()
  }

  const getFilters = () => {
    let filters = {};
    let permittedFilters = ['order', 'user_id'];
    var url = new URL(window.location);
    var searchParams = new URLSearchParams(url.search);

    permittedFilters.forEach(f => {
      const activeFilter = searchParams.get(f);

      if (activeFilter) filters[f] = activeFilter;
    });

    return filters;
  }

  if (actionItemsIndex) {
    // Table heading orders
    const sorts = ['opportunity_reference_number', 'status', 'name', 'target_date', 'created_at', 'type', 'user_id'];
    const staffSelect = document.getElementById('user_id');

    sorts.forEach(s => {
      const el = document.getElementById(`${s}_sort`);

      if (!el) return;
      
      el.addEventListener('click', function(e) {
        const filters = getFilters();
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        let order = searchParams.get('order');
        let orderParts = order ? order.split('_') : null;
        const orderDirection = order && orderParts[orderParts.length - 1] === 'asc' ? 'desc' : 'asc';
      
        let newSearch = { order: `${s}_${orderDirection}` };

        var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, newSearch)}`;
        order === 'asc' ? order = 'desc' : order = 'asc';
        Turbo.visit(location, { action: 'replace' });
      });
    });

    if (staffSelect) {
      staffSelect.addEventListener('change', function(e) {
        var filters = getFilters();
        var location = `${window.location.origin}${window.location.pathname}?${filterToQueryString(filters, { user_id: e.target.value })}`;
      
        Turbo.visit(location, { action: 'replace' });
      });
    }
  }
});
