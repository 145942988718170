import Vue from 'vue'
import App from './client_note_types/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const clientNoteBuilder = document.getElementById('client-note-type-index');

  if (clientNoteBuilder) {
    new Vue({
      el: '#client-note-type-index',
      render: h => h(App)
    }).$mount();
  }
});