import axios from 'axios';

document.addEventListener('turbo:load', () => {
  const learning = document.getElementById('post-content');

  if (learning) {
    learning.style.display = 'none';

    const links = learning.getElementsByClassName('trix-content')[0].getElementsByTagName('a');
    const regExp = /(https:?\/\/)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;

    [...links].forEach(link => {
      const match = link.href.match(regExp);

      if (match && (link.href.includes('youtu.be') || link.href.includes('youtube'))) {
        axios.get(`https://www.youtube.com/oembed?url=${link.href}&format=json`).then(res => {
          link.outerHTML = `
            <div class="video-wrapper">
              ${res.data.html}
            </div>
          `;
        });
      } else if (match && link.href.includes('vimeo')) {
        axios.get(`https://vimeo.com/api/oembed.json?url=${link.href}`).then(res => {
          link.outerHTML = `
            <div class="video-wrapper">
              ${res.data.html}
            </div>
          `;
        });
      } else {
        link.classList.add('text-blue-500');
        link.classList.add('font-medium');
        link.setAttribute('target', '_blank');
      }
    });

    learning.style.display = '';
  };
});