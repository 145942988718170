import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = {
    answer: String,
    message: String,
    url: String
  }

  connect() {
    //default answer to 'yes' if not specified
    if(!this.answerValue) { this.answerValue = 'yes' }
  }

  click(event) {
    let userInput = prompt(`Are you ABSOLUTELY sure? This Action CANNOT be undone.\n${this.messageValue}\n\nType: '${this.answerValue}' to confirm.`);
    if (userInput == this.answerValue) {
      this.requestDelete(event);
    } else {
      alert('User input did not match what was expected. Aborting...')
    }
  }

  async requestDelete(event) {
    const request = new FetchRequest("DELETE", this.urlValue, {responseKind: 'json'});
    const response = await request.perform();
    if (response.ok) {
      const json = await response.json;
      Turbo.visit(json.url);
    }
  }
}
