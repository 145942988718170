import Vue from 'vue'
import App from './learning_centres/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const templateBuilder = document.getElementById('learning-centre-builder');

  if (templateBuilder) {
    new Vue({
      el: '#learning-centre-builder',
      render: h => h(App)
    }).$mount();
  }
});
