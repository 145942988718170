var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex space-x-4" }, [
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Template Name")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
          attrs: { type: "text", placeholder: "Schedule template name" },
          domProps: { value: _vm.name },
          on: {
            change: function($event) {
              _vm.name = $event.target.value
              _vm.saveTemplate()
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between mt-4" }, [
      _c("h3", { staticClass: "font-bold text-xl text-gray-800" }, [
        _vm._v("Template Bookings")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addtemplateBooking($event)
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-6 h-6 mr-1",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          ),
          _vm._v("\n      Add New Template Booking\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "draggable",
          {
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
                _vm.saveTemplate()
              }
            },
            model: {
              value: _vm.templateBookings,
              callback: function($$v) {
                _vm.templateBookings = $$v
              },
              expression: "templateBookings"
            }
          },
          _vm._l(_vm.templateBookings, function(templateBooking) {
            return _c(
              "div",
              { key: "template-booking-" + templateBooking.id },
              [
                templateBooking.editing
                  ? _c(
                      "div",
                      { staticClass: "bg-gray-200 rounded px-5 py-3 mt-4" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c("div", { staticClass: "flex-1 flex space-x-2" }, [
                            _c("div", { staticClass: "flex-1" }, [
                              _c(
                                "label",
                                { staticClass: "font-semibold text-gray-800" },
                                [
                                  _vm._v("Booking Name"),
                                  _c("sup", [_vm._v("*")])
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: templateBooking.name,
                                    expression: "templateBooking.name"
                                  }
                                ],
                                staticClass:
                                  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                attrs: {
                                  type: "text",
                                  placeholder: "Booking name"
                                },
                                domProps: { value: templateBooking.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      templateBooking,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "label",
                            { staticClass: "font-semibold text-gray-800" },
                            [_vm._v("Description")]
                          ),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: templateBooking.description,
                                expression: "templateBooking.description"
                              }
                            ],
                            staticClass:
                              "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                            attrs: {
                              placeholder: "Optional booking description"
                            },
                            domProps: { value: templateBooking.description },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  templateBooking,
                                  "description",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass: "block font-semibold text-gray-800"
                            },
                            [_vm._v("Supplier")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: templateBooking.supplier_id,
                                  expression: "templateBooking.supplier_id"
                                }
                              ],
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    templateBooking,
                                    "supplier_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("Nominate the supplier")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.suppliers, function(s) {
                                return _c(
                                  "option",
                                  { key: s.id, domProps: { value: s.id } },
                                  [_vm._v(_vm._s(s.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "flex items-center space-x-2" },
                            [
                              _c("div", { staticClass: "flex-1" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "block font-semibold text-gray-800"
                                  },
                                  [_vm._v("Task Nomination")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: templateBooking.chosen_task_id,
                                        expression:
                                          "templateBooking.chosen_task_id"
                                      }
                                    ],
                                    staticClass: "form-select w-full",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            templateBooking,
                                            "chosen_task_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          templateBooking.chosen_task_id =
                                            $event.target.value
                                          templateBooking.task_type =
                                            "FunnelTask"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("Nominate the connected task")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.tasks, function(t) {
                                      return _c(
                                        "option",
                                        {
                                          key: t.id,
                                          domProps: { value: t.id }
                                        },
                                        [_vm._v(_vm._s(t.name))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-1" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "block font-semibold text-gray-800"
                                  },
                                  [_vm._v("Sub-Task Nomination")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          templateBooking.chosen_sub_task_id,
                                        expression:
                                          "templateBooking.chosen_sub_task_id"
                                      }
                                    ],
                                    staticClass: "form-select w-full",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            templateBooking,
                                            "chosen_sub_task_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          templateBooking.task_type =
                                            "FunnelSubTask"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    !templateBooking.chosen_task_id
                                      ? _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [
                                            _vm._v(
                                              "Nominate a task to view sub-tasks"
                                            )
                                          ]
                                        )
                                      : _vm.subTasks(
                                          templateBooking.chosen_task_id
                                        ).length === 0
                                      ? _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [
                                            _vm._v(
                                              "No sub-tasks in chosen task for scheduler"
                                            )
                                          ]
                                        )
                                      : _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [
                                            _vm._v(
                                              "Nominate the connected sub-task"
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.subTasks(
                                        templateBooking.chosen_task_id
                                      ),
                                      function(st) {
                                        return _c(
                                          "option",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  templateBooking.chosen_task_id,
                                                expression:
                                                  "templateBooking.chosen_task_id"
                                              }
                                            ],
                                            key: st.id,
                                            domProps: { value: st.id }
                                          },
                                          [_vm._v(_vm._s(st.name))]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                templateBooking.editing = false
                                _vm.saveTemplate()
                              }
                            }
                          },
                          [_vm._v("\n            Save Booking\n          ")]
                        )
                      ]
                    )
                  : (_vm.showKept && !templateBooking.discarded_at) ||
                    !_vm.showKept
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between",
                        class: templateBooking.discarded_at ? "bg-red-100" : ""
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "w-4 h-4 mr-2 text-gray-600",
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "font-bold text-gray-800 mr-6" },
                            [_vm._v(_vm._s(templateBooking.name))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-blue-500 mr-4",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  templateBooking.editing = true
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "w-4 h-4",
                                  attrs: {
                                    fill: "none",
                                    stroke: "currentColor",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d:
                                        "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          templateBooking.discarded_at
                            ? _c(
                                "a",
                                {
                                  staticClass: "text-blue-500",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.undiscardtemplateBooking(
                                        templateBooking.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "text-red-500",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.discardtemplateBooking(
                                        templateBooking.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex items-center justify-center mt-8 text-blue-500"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showKept = !_vm.showKept
                  }
                }
              },
              [_vm._v("Toggle Archived Template Bookings")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }