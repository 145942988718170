var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex justify-between items-center" }, [
      _vm.exportAvailable
        ? _c(
            "a",
            {
              staticClass: "text-blue-500",
              attrs: {
                href:
                  "/staff/opportunities/" +
                  _vm.opportunityId +
                  "/trades/export",
                target: "_blank"
              }
            },
            [_vm._v("Export Trade Data")]
          )
        : _c("p", { staticClass: "text-gray-500" }, [
            _vm._v(
              "Financial reporting available once at least one quote is approved."
            )
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Filter by Quote Status")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select",
            domProps: { value: _vm.filter },
            on: {
              change: function($event) {
                return _vm.updateFilter($event.target.value)
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("Quote Status")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "na" } }, [_vm._v("Not Requested")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "requested" } }, [
              _vm._v("Requested")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "received" } }, [
              _vm._v("Received")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "approved" } }, [
              _vm._v("Approved")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "declined" } }, [_vm._v("Declined")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.tradesWithSuppliers, function(trade) {
        return _c(
          "div",
          {
            key: trade.id,
            staticClass: "bg-gray-100 rounded mt-4",
            class: { "bg-green-100": _vm.hasSuppliers(trade) && !trade.toggled }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "rounded text-gray-800 flex items-center justify-between px-4 py-2"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "cursor-pointer flex items-center justify-start space-x-2 flex-1",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleTrade(trade.id)
                      }
                    }
                  },
                  [
                    _c("h3", { staticClass: "font-semibold text-xl" }, [
                      _vm._v(_vm._s(trade.name))
                    ]),
                    _vm._v(" "),
                    _vm.hasSuppliers(trade)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "p-2 rounded-full h-6 w-6 text-xs bg-green-500 text-white flex items-center justify-center"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(trade.activeSuppliers.length) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex items-center space-x-2" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-select",
                      domProps: { value: trade.selectedSupplier },
                      on: {
                        change: function($event) {
                          return _vm.updateTradeSupplier({
                            trade: trade,
                            event: $event
                          })
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("Choose supplier")
                      ]),
                      _vm._v(" "),
                      _vm._l(trade.suppliers, function(supplier) {
                        return _c(
                          "option",
                          {
                            key: "supplier-" + supplier.id,
                            domProps: { value: supplier.id }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(supplier.name) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "text-brand-dark font-semibold",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addSupplier(trade)
                        }
                      }
                    },
                    [_vm._v("Add Supplier")]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            trade.toggled
              ? _c(
                  "div",
                  { staticClass: "p-4" },
                  _vm._l(_vm.tradeSuppliers(trade.id), function(s, index) {
                    return _c("trade", {
                      key: s.id,
                      attrs: {
                        index: index,
                        supplier: s,
                        "trade-name": trade.name
                      }
                    })
                  }),
                  1
                )
              : _vm._e()
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }