// Add safe='true' attribute to targets you wish to not trigger the check
import { Controller } from "@hotwired/stimulus"
import { debounce } from '../pages/utilities';
const MESSAGE = "You still have unsent text. Are you sure you want to leave?"

export default class extends Controller {
    listenersAdded = false;

    initialize() {
        this.textInput = debounce(this.textInput, 500).bind(this)
    }

    navigateAway(event) {
        if (event.type == "turbo:before-visit") {
            if (!window.confirm(MESSAGE)) {
                event.preventDefault()
            }
        } else {
            event.returnValue = MESSAGE;
            return event.returnValue;
        }
    }

    disconnect() {
        this.allowNavigation();
    }

    textInput(event)
    {
        setTimeout(() => {
            if(event.target.value !== "" && !this.listenersAdded) {
                this.preventNavigation();
            } else if (event.target.value === "" && this.listenersAdded) {
                this.allowNavigation();
            }
        }, 100);
    }

    preventNavigation() {
        window.addEventListener("beforeunload", this.navigateAway);
        document.addEventListener('turbo:before-visit', this.navigateAway);
        this.listenersAdded = true;
    }

    allowNavigation() {
        window.removeEventListener("beforeunload", this.navigateAway);
        document.removeEventListener('turbo:before-visit', this.navigateAway);
        this.listenersAdded = false;
    }

    sendMessage(e) {
        this.allowNavigation();
        e.target.form.requestSubmit();
    }
}
