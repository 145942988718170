var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "learning-step-editor" }, [
    _c("div", { staticClass: "flex space-x-4" }, [
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Learning Step Name*")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
          attrs: { type: "text", placeholder: "Learning category name" },
          domProps: { value: _vm.name },
          on: {
            change: function($event) {
              _vm.name = $event.target.value
              _vm.saveStep()
            }
          }
        }),
        _vm._v(" "),
        Object.keys(this.errors).length > 0
          ? _c(
              "p",
              { staticClass: "p-2 bg-red-500 text-white rounded text-center" },
              [_vm._v(_vm._s(this.errors["name"].join(", ")))]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between mt-4" }, [
      _c("h3", { staticClass: "font-bold text-xl text-gray-800" }, [
        _vm._v("Learning Topics")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addLearningTopic($event)
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-6 h-6 mr-1",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          ),
          _vm._v("\n      Add New Learning Topic\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "draggable",
          {
            staticClass: "flex flex-col",
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
                _vm.saveStep()
              }
            },
            model: {
              value: _vm.learning_topics,
              callback: function($$v) {
                _vm.learning_topics = $$v
              },
              expression: "learning_topics"
            }
          },
          _vm._l(_vm.learning_topics, function(topic, topicIndex) {
            return _c(
              "div",
              { key: "learning-topic-" + topic.id },
              [
                topic.editing
                  ? _c(
                      "div",
                      { staticClass: "bg-gray-200 rounded px-5 py-3 mt-4" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "div",
                            { staticClass: "flex-1 flex flex-col space-y-2" },
                            [
                              _c("div", { staticClass: "flex-1" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-semibold text-gray-800"
                                  },
                                  [
                                    _vm._v("Learning Topic Name"),
                                    _c("sup", [_vm._v("*")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: topic.name,
                                      expression: "topic.name"
                                    }
                                  ],
                                  staticClass:
                                    "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Learning topic name"
                                  },
                                  domProps: { value: topic.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        topic,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "font-semibold text-gray-800"
                                    },
                                    [
                                      _vm._v("Training Content"),
                                      _c("sup", [_vm._v("*")])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("VueTrix", {
                                    attrs: {
                                      placeholder:
                                        "Enter topic training content",
                                      config: _vm.trixConfig
                                    },
                                    on: {
                                      "trix-attachment-add": function(file) {
                                        return _vm.handleTopicFile(
                                          file,
                                          topicIndex
                                        )
                                      }
                                    },
                                    model: {
                                      value: topic.content,
                                      callback: function($$v) {
                                        _vm.$set(topic, "content", $$v)
                                      },
                                      expression: "topic.content"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                topic.editing = false
                                _vm.saveTopic(topic.id)
                              }
                            }
                          },
                          [_vm._v("\n            Save Topic\n          ")]
                        )
                      ]
                    )
                  : (_vm.showKept && !topic.discarded_at) || !_vm.showKept
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between",
                        class: topic.discarded_at ? "bg-red-100" : ""
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "w-4 h-4 mr-2 text-gray-600",
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "font-bold text-gray-800 mr-6" },
                            [_vm._v(_vm._s(topic.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-blue-500 inline-flex items-center",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addSubTopic(topicIndex)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "w-4 h-4 mr-1",
                                  attrs: {
                                    fill: "none",
                                    stroke: "currentColor",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n              Add Sub-Topic\n            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-blue-500 mr-4",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  topic.editing = true
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "w-4 h-4",
                                  attrs: {
                                    fill: "none",
                                    stroke: "currentColor",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d:
                                        "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          topic.discarded_at
                            ? _c(
                                "a",
                                {
                                  staticClass: "text-blue-500",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.undiscardLearningTopic(
                                        topic.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "text-red-500",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.discardLearningTopic(topic.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    on: {
                      start: function($event) {
                        _vm.drag = true
                      },
                      end: function($event) {
                        _vm.drag = false
                        _vm.saveStep()
                      }
                    },
                    model: {
                      value: topic.sub_topics,
                      callback: function($$v) {
                        _vm.$set(topic, "sub_topics", $$v)
                      },
                      expression: "topic.sub_topics"
                    }
                  },
                  _vm._l(topic.sub_topics, function(subTopic) {
                    return _c(
                      "div",
                      {
                        key: "sub-topic-" + subTopic.id,
                        staticClass: "ml-8 mt-2"
                      },
                      [
                        subTopic.editing
                          ? _c(
                              "div",
                              { staticClass: "bg-gray-300 rounded px-5 py-3" },
                              [
                                _c("div", { staticClass: "flex-col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-col flex-1 gap-y-2 mr-4"
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-semibold text-gray-800"
                                              },
                                              [_vm._v("Sub-Topic Name")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: subTopic.name,
                                                  expression: "subTopic.name"
                                                }
                                              ],
                                              staticClass:
                                                "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                              attrs: {
                                                type: "text",
                                                placeholder: "Sub-topic name"
                                              },
                                              domProps: {
                                                value: subTopic.name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    subTopic,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-1" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-semibold text-gray-800"
                                                },
                                                [
                                                  _vm._v("Training Content"),
                                                  _c("sup", [_vm._v("*")])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("VueTrix", {
                                                attrs: {
                                                  placeholder:
                                                    "Enter sub-topic training content",
                                                  config: _vm.trixConfig
                                                },
                                                on: {
                                                  "trix-attachment-add": function(
                                                    file
                                                  ) {
                                                    return _vm.handleTopicFile(
                                                      file,
                                                      topicIndex
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: subTopic.content,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      subTopic,
                                                      "content",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "subTopic.content"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        subTopic.editing = false
                                        _vm.saveSubtopic(topic.id, subTopic.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Save Sub-Topic\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : (_vm.showKept && !subTopic.discarded_at) ||
                            !_vm.showKept
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-gray-200 rounded px-5 py-3 mt-3 flex items-center justify-between",
                                class: subTopic.discarded_at ? "bg-red-100" : ""
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "w-4 h-4 mr-2 text-gray-600",
                                        attrs: {
                                          fill: "none",
                                          stroke: "currentColor",
                                          viewBox: "0 0 24 24",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2",
                                            d: "M5 13l4 4L19 7"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "font-bold text-gray-800 mr-6"
                                      },
                                      [_vm._v(_vm._s(subTopic.name))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-blue-500 mr-4",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            subTopic.editing = true
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "w-4 h-4",
                                            attrs: {
                                              fill: "none",
                                              stroke: "currentColor",
                                              viewBox: "0 0 24 24",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d:
                                                  "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    subTopic.discarded_at
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-blue-500",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.undiscardSubTopic(
                                                  topic.id,
                                                  subTopic.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "w-4 h-4",
                                                attrs: {
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  viewBox: "0 0 24 24",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "text-red-500",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.discardSubTopic(
                                                  topic.id,
                                                  subTopic.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "w-4 h-4",
                                                attrs: {
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  viewBox: "0 0 24 24",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex items-center justify-center mt-8 text-blue-500"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showKept = !_vm.showKept
                  }
                }
              },
              [_vm._v("Toggle Archived Learning Topics")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }