<template>
  <div class="flex items-center">
    <a @click="openFilePicker" href="javascript:;" class="text-blue font-semibold">
      Upload Document
    </a>
    <input :id="`filePicker-${taskIndex}-${subTaskIndex}`" type="file" class="hidden">
  </div>
</template>
<script>
export default {
  props: ['task', 'stageId', 'taskIndex', 'subTaskIndex', 'addDocument'],
  data() {
    return {

    }
  },
  mounted() {
    let picker = document.getElementById(`filePicker-${this.taskIndex}-${this.subTaskIndex}`);

    picker.addEventListener('change', this.uploadFile);
  },
  methods: {
    openFilePicker(e) {
      document.getElementById(`filePicker-${this.taskIndex}-${this.subTaskIndex}`).click();
    },
    uploadFile(e) {
      this.addDocument(e.target.files[0], this.stageId, this.taskIndex, this.subTaskIndex);
    }
  }
}
</script>