var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-2 bg-white rounded mt-2" }, [
    _c("div", { staticClass: "flex items-center space-x-2" }, [
      _c(
        "div",
        { staticClass: "flex flex-auto items-center justify-between" },
        [
          _c(
            "h4",
            { staticClass: "font-semibold text-gray-800 text-lg mb-2" },
            [_vm._v(_vm._s(_vm.supplier.name))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-red-500 flex space-x-2 items-center",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.deleteSupplier(_vm.supplier.id)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "h-6 w-6",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d:
                        "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("Delete")])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-col space-y-2" },
      _vm._l(_vm.supplier.purchase_orders, function(po) {
        return _c("purchase-order", {
          key: po.id,
          attrs: {
            po: po,
            "supplier-index": _vm.index,
            "supplier-id": _vm.supplier.id
          }
        })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-start flex-auto space-x-4 mt-4" }, [
      _c(
        "div",
        { staticClass: "flex-1" },
        [
          _c("h4", { staticClass: "font-semibold text-gray-800" }, [
            _vm._v("Quotes")
          ]),
          _vm._v(" "),
          _vm._l(_vm.quoteAttachments, function(attachment) {
            return _c(
              "div",
              {
                key: attachment.id,
                staticClass: "rounded-xl p-1 bg-gray-50 mb-1"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center space-x-2",
                    attrs: {
                      href: attachment.google_drive_link,
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "w-4 h-4",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(attachment.name))
                    ])
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("direct-upload", {
            attrs: {
              "upload-url": "/rails/active_storage/direct_uploads",
              multiple: true,
              category: "quote",
              label: "Drop files into this area to start upload"
            },
            on: { upload: _vm.uploadCallback }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-1" },
        [
          _c("h4", { staticClass: "font-semibold text-gray-800" }, [
            _vm._v("Purchase Orders")
          ]),
          _vm._v(" "),
          _vm._l(_vm.poAttachments, function(attachment) {
            return _c(
              "div",
              {
                key: attachment.id,
                staticClass: "rounded-xl p-1 bg-gray-50 mb-1"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center space-x-2",
                    attrs: {
                      href: attachment.google_drive_link,
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "w-4 h-4",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(attachment.name))
                    ])
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("direct-upload", {
            attrs: {
              "upload-url": "/rails/active_storage/direct_uploads",
              multiple: true,
              category: "purchase_order",
              label: "Drop files into this area to start upload"
            },
            on: { upload: _vm.uploadCallback }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-1" },
        [
          _c("h4", { staticClass: "font-semibold text-gray-800" }, [
            _vm._v("Invoices")
          ]),
          _vm._v(" "),
          _vm._l(_vm.invoiceAttachments, function(attachment) {
            return _c(
              "div",
              {
                key: attachment.id,
                staticClass: "rounded-xl p-1 bg-gray-50 mb-1"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center space-x-2",
                    attrs: {
                      href: attachment.google_drive_link,
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "w-4 h-4",
                        attrs: {
                          fill: "none",
                          stroke: "currentColor",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(attachment.name))
                    ])
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("direct-upload", {
            attrs: {
              "upload-url": "/rails/active_storage/direct_uploads",
              multiple: true,
              category: "invoice",
              label: "Drop files into this area to start upload"
            },
            on: { upload: _vm.uploadCallback }
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center space-x-2 mt-6 mb-4" }, [
      _c("div", { staticClass: "flex-1" }, [
        _c(
          "a",
          {
            staticClass:
              "bg-brand-light px-4 py-2 mr-2 text-brand-dark rounded-lg font-semibold",
            attrs: {
              href:
                "/staff/opportunities/" +
                _vm.opportunityId +
                "/trades/" +
                _vm.supplier.id +
                "/purchase_orders",
              "data-method": "POST"
            }
          },
          [_vm._v("\n        Create Quote Request / Purchase Order\n      ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "bg-brand-light px-4 py-2 text-brand-dark rounded-lg font-semibold",
            attrs: {
              href:
                "/staff/opportunities/" +
                _vm.opportunityId +
                "/trades/" +
                _vm.supplier.id +
                "/purchase_orders?po_type=all",
              "data-method": "POST"
            }
          },
          [
            _vm._v(
              "\n        Create PO With All " +
                _vm._s(_vm.tradeName) +
                " Items\n      "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }