import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";
import {fabric} from "fabric";

const Mode = {
    Default: -1,
    Pan: 0,
    Line: 1,
    Rectangle: 2,
    Polygon: 3,
    Point: 4,
    Scale: 5
};

let snapping = false;

let canvas = null;
let image = null;

export default class extends Controller {
    selectedButtonClass = 'bg-brand-light';
    buttonClass = 'bg-brand-dark';
    get_data_url = '/staff/opportunities/:opportunity_id/takeoff/:id/get_data';
    send_data_url = '/staff/opportunities/:opportunity_id/takeoff/:id/update';
    strokeColour = '#ff0000';
    fillColour = '#00ff00';
    exclusionStrokeColour = '#000000';
    exclusionFillColour = '#000000';
    quantity = 0.000;
    scale = 0;
    planRotation = 0;
    takeoff = true; // if take-off mode or scaling mode
    dirty = false;
    exclusionMode = false;
    pointer = null;
    
    unit = null;
    currentMode = Mode.Default;
    lastMode = Mode.Default;
    isDragging = false;
    wasDragging = false;
    lastPosX = null;
    lastPosY = null;
    newObject = null;
    newObjects = [];
    textObjects = [];
    tempObjects = [];

    startX = 0;
    startY = 0;
    pts = [];
    lastPt = 0;
    polyline = null;
    mouse = null;
    id = -1;

    static targets = ["canvas", "rotate", "canvasSizer", "image", "scale", "planRotation", "quantity", "toolButton", "sort"];
    static values = {
        takeoff: { type: Boolean, default: true },
        takeoffId: { type: String, default: '' },
        opportunityId: { type: String, default: '' },
        unit: { type: String, default: 'lm' },
        partName: { type: String, default: 'lm' },
        imageUrl: { type: String, default: ''}

    };

    disconnect() {
        //reset globals
        canvas = null;
        image = null;
        snapping = null;

        if (this.dirty && this.takeoff) {
            if (confirm("Save changes?")) {
                this.saveObjects();
            }
        }
    }

    connect() {
        this.currentMode = Mode.Default;
        this.lastMode = Mode.Default;
        this.takeoff = this.takeoffValue;
        this.unit = this.unitValue;

        this.get_data_url = this.get_data_url.replace(':opportunity_id', this.opportunityIdValue);
        this.get_data_url = this.get_data_url.replace(':id', this.takeoffIdValue);
        this.send_data_url = this.get_data_url.replace('get_data', 'save_data');

        this.currentMode = Mode.Edit;

        canvas = new fabric.Canvas('theCanvas');
        canvas.selection = false;

        this.loadData();


        canvas.on('mouse:down', option => this.handleMouseDown(option));
        canvas.on('mouse:up:before', option => this.handleMouseUp(option));
        canvas.on('mouse:move', option => this.handleMouseMove(option));
        canvas.on('mouse:wheel', option => this.handleMouseWheel(option));
        canvas.on('mouse:dblclick', option => this.handleDoubleClick(option));
        this.setKeyEvents();

    }

    loadData()
    {
        if (this.scaleTarget.value > 0) { this.scale = this.scaleTarget.value; }
        fabric.Image.fromURL(this.imageUrlValue, img => loadImage(img, this.canvasSizerTarget, this.planRotation));
        if(!this.takeoff) {return;}

        // Load objects from server
        const csrf = document.getElementsByName('csrf-token')[0].content;
        fetch(this.get_data_url, {
            method: 'POST',
            headers: { accept: 'application/json', 'x-csrf-token': csrf, "Content-Type": "application/json"}
        }).then((response) => response.json())
            .then(data => {
                if(data != null) {
                    data.forEach(obj => {
                        fabric.util.enlivenObjects([obj], enlivenedObjects => addEnlivenedObjectsToCanvas(enlivenedObjects, canvas, this.newObjects));
                    });
                    this.updateQuantity(false);
                }
            });
    }

// MOUSE HANDLERS
    handleMouseDown(option) {
        switch(this.currentMode) {
            case Mode.Pan:
                this.startPan(option);
                break;
        }
    }

    handleDoubleClick(option) {
        if (!this.isDragging) { return; }

        switch(this.currentMode)
        {
            case Mode.Polygon:
                this.stopPolygon(option);
                this.polygonTool();
                this.updateQuantity();
                break;
        }
    }
    handleMouseUp(option) {
        this.mouse = canvas.getPointer(option.e);

        if(!this.isDragging) {
            switch(this.currentMode)
            {
                case Mode.Rectangle:
                    this.startRectangle(option);
                    break;
                case Mode.Line:
                case Mode.Scale:
                    this.startLine(option)
                    break;
                case Mode.Polygon:
                    this.startPolygon(option);
                    break;
                case Mode.Point:
                    this.addPoint(option);
                    this.updateQuantity();
                    break;
            }
        } else {
            this.isDragging = false;
            switch(this.currentMode)
            {
                case Mode.Pan:
                    this.stopPan(option);
                    return;
                    break;
                case Mode.Rectangle:
                    this.stopRectangle(option);
                    break;
                case Mode.Line:
                case Mode.Scale:
                    this.stopLine(option);
                    break;
                case Mode.Polygon:
                    this.startPolygon(option);
                    break;

            }
            this.updateQuantity();
        }

    }
    handleMouseMove(option) {
        if (!this.isDragging) { return; }
        switch(this.currentMode) {
            case Mode.Pan:
                this.pan(option);
                break;
            case Mode.Rectangle:
                this.drawRectangle(option);
                break;
            case Mode.Line:
            case Mode.Scale:
                this.drawLine(option);
                break;
            case Mode.Polygon:
                this.drawPolygon(option);
                break;
        }

    }
    handleMouseWheel(option) {
        this.zoom(option);

    }

// METHODS!
    startPolygon(option) {
        this.isDragging = true;
        if (this.pts.length > 1) {this.pts.splice(-1,1);} //remove duplicate start points
        canvas.remove(this.polyline);

        this.polyline = new fabric.Polyline(this.pts, {
            objectCaching: false,
            name: 'temp',
            fill: this.getFillColour(),
            stroke: this.getStrokeColour(),
            strokeWidth: 2,
            opacity: 0.75,
            originX: 'center',
            originY: 'center',
            selectable: false,

            hoverCursor: 'crosshair',
            operator: this.exclusionMode ? -1 : 1
        });


        if (this.lastPt == 0) {this.pointer = { x: this.mouse.x, y: this.mouse.y };}
        this.polyline.points.push(this.pointer);

        this.tempObjects.push(this.polyline);
        canvas.add(this.polyline);
        this.lastPt = this.polyline.points.length;
    }
    drawPolygon(option) {
        canvas.zoomToPoint({ x: option.e.offsetX, y: option.e.offsetY }, canvas.getZoom()); // fix not rendering bug

        this.mouse = canvas.getPointer(option.e);
        this.pointer = {x: this.mouse.x, y: this.mouse.y};
        if(snapping) {
            let point = {x: this.polyline.points[this.lastPt-1].x, y: this.polyline.points[this.lastPt-1].y}
            this.pointer = this.getSnappedPoint(this.pointer, point);
        }
        this.polyline.points[this.lastPt] = this.pointer;

        fabric.Polyline.prototype._setPositionDimensions.call(this.polyline,[]);  // fix not rendering bug

        canvas.renderAll();
    }
    stopPolygon(option) {
        this.tempObjects.forEach(obj => {
            canvas.remove(obj);
        });

        this.pts.push(this.pts[0]);

        this.newObject = new fabric.Polyline(this.pts,{
            objectCaching: false,
            id: this.id++,
            fill: this.getFillColour(),
            stroke: this.getStrokeColour(),
            strokeWidth: 2,
            opacity: 0.75,
            originX: 'center',
            originY: 'center',
            hoverCursor: 'crosshair',
            selectable: false,
            operator: this.exclusionMode ? -1 : 1
        });

        canvas.add(this.newObject);
        this.newObjects.push(this.newObject);

        this.lastPt=0;
        this.isDragging=false;
        this.pts=[];
    }
    startLine(option) {
        this.isDragging = true;
        let pointer = canvas.getPointer(option.e);
        let points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
        this.newObject = new fabric.Line(points, {
            strokeWidth: 2,
            fill: this.getFillColour(),
            stroke: this.getStrokeColour(),
            originX: 'center',
            originY: 'center',
            operator: this.exclusionMode ? -1 : 1,
            selectable: false
        });

        canvas.add(this.newObject);
    }
    drawLine(option) {
        let pointer = canvas.getPointer(option.e);
        if(snapping) {
            let point = {x: this.newObject.x1, y: this.newObject.y1}
            pointer = this.getSnappedPoint(pointer, point);
        }
        this.newObject.set({ x2: pointer.x, y2: pointer.y });
        canvas.renderAll();
    }

    getSnappedPoint(pointer, previousPoint) {
        let xDiff = Math.abs(pointer.x - previousPoint.x);
        let yDiff = Math.abs(pointer.y - previousPoint.y);

        if(xDiff < yDiff) {
            pointer.x = previousPoint.x;
        } else {
            pointer.y = previousPoint.y;
        }
        return pointer;
    }
    stopLine(option) {
        this.newObjects.push(this.newObject);
        this.isDragging = false;

        // Set Scale
        if (this.currentMode == Mode.Scale) {
            let userInput = prompt("Enter the known length in (mm)");
            snapping = false;

            this.lineTool();
            this.removeNewObjects();
            if (userInput === null) {return;}

            this.scale = userInput / this.calcScaleLength(this.newObject);
            this.scaleTarget.value = this.scale;

        } else if (!this.takeoff) {
            alert(`${ Math.round(this.calcLength(this.newObject)* 1000) }mm`);
            snapping = false;
            this.removeNewObjects();
        }
    }
    addPoint(option) {
        let pointer = canvas.getPointer(option.e);
        this.newObject = new fabric.Circle({
            radius: 10,
            fill: this.fillColour,
            stroke: this.strokeColour,
            strokeWidth: 2,
            top: pointer.y - 10,
            left: pointer.x - 10,
            operator: this.exclusionMode ? -1 : 1});

        this.newObjects.push(this.newObject);
        canvas.add(this.newObject);
        canvas.renderAll();
    }
    startRectangle(option) {
        this.isDragging = true;
        let pointer = canvas.getPointer(option.e);

        this.startX = pointer.x;
        this.startY = pointer.y;

        this.newObject = new fabric.Rect({
            top : pointer.y,
            left : pointer.x,
            width : 0,
            height : 0,
            fill: this.getFillColour(),
            stroke: this.getStrokeColour(),
            opacity: 0.75,
            strokeWidth: 2,
            hoverCursor: 'crosshair',
            selectable: false,
            operator: this.exclusionMode ? -1 : 1
        });

        canvas.add(this.newObject);
    }
    drawRectangle(option) {
        let pointer = canvas.getPointer(option.e);

        if(this.startX > pointer.x){
            this.newObject.set({ left: Math.abs(pointer.x) });
        }
        if(this.startY > pointer.y){
            this.newObject.set({ top: Math.abs(pointer.y) });
        }

        this.newObject.set({ width: Math.abs(this.startX - pointer.x) });
        this.newObject.set({ height: Math.abs(this.startY - pointer.y) });

        canvas.renderAll();
    }
    stopRectangle(option) {
        this.newObjects.push(this.newObject);
        this.isDragging = false;

        const height = Math.floor(this.newObject.height / this.scale);
        const width = Math.floor(this.newObject.width / this.scale);
        const area = (height * width) / 1000000;
    }
    startPan(option) {
        const event = option.e;
        this.isDragging = true;
        this.lastPosX = event.clientX;
        this.lastPosY = event.clientY;
    }
    stopPan(option) {
        canvas.setViewportTransform(canvas.viewportTransform);
        this.isDragging = false;
    }
    pan(option) {
        if (this.isDragging) {
            const event = option.e;
            const viewportTransform = canvas.viewportTransform;
            viewportTransform[4] += event.clientX - this.lastPosX;
            viewportTransform[5] += event.clientY - this.lastPosY;
            canvas.requestRenderAll();
            this.lastPosX = event.clientX;
            this.lastPosY = event.clientY;
        }
    }
    zoom(option) {
        const event = option.e;
        let delta = event.deltaY;
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas.zoomToPoint({ x: event.offsetX, y: event.offsetY }, zoom);
        event.preventDefault();
        event.stopPropagation();
    }
    rotateCanvas() {
        const new_w = this.canvasSizerTarget.offsetHeight;
        const new_h = this.canvasSizerTarget.offsetWidth;
        this.canvasSizerTarget.style.width = new_w+"px";
        this.canvasSizerTarget.style.height = new_h+"px";
        canvas.setDimensions({ width: new_w, height: new_h });

        const angleChange = 90;

        let objs = canvas.getObjects();
        objs.forEach(obj => {
            var val = obj.angle + angleChange;
            if(val == 360) { val = 0; }
            obj.angle = val;

            var newleft = canvas.width - obj.top;
            var newtop = obj.left;

            obj.top = newtop;
            obj.left = newleft;
            obj.setCoords();
        });

        this.planRotation += angleChange;
        if(this.planRotation == 360) { this.planRotation = 0; }
        this.planRotationTarget.value = this.planRotation;
    }

    setKeyEvents() {
        document.onkeydown = e => {
            switch(e.key) {
                case 'Shift':
                    snapping = true;
                    break;
                case 'Alt':
                    this.lastMode = this.currentMode;
                    this.currentMode = Mode.Pan;

                    this.wasDragging = this.isDragging;
                    this.isDragging = false;
                    break;
                default:
            }
        }
        document.onkeyup = e => {
            switch(e.key) {
                case 'Shift':
                    snapping = false;
                    break;
                case 'Alt':
                    this.currentMode = this.lastMode;
                    this.isDragging = this.wasDragging;
                    break;
                default:
            }
        }
    }

// TOOLBAR
    async saveObjects(complete) {
        let data = { objects:[] };

        this.newObjects.forEach(obj => {
            let tempObj = JSON.parse(JSON.stringify(obj));
            tempObj.operator = obj.operator;
            data.objects.push(tempObj);
        });


        const csrf = document.getElementsByName('csrf-token')[0].content;

        const request = new FetchRequest("POST", `${this.send_data_url}?quantity=${this.quantity}&complete=${complete}&sort=${this.sortTarget.value}` ,{ responseKind: "turbo-stream", body: data });
        const response = await request.perform();

        this.dirty = false;
    }

    saveAndComplete() {
        this.saveObjects(true);
    }

    save(e) {
        this.saveObjects(false);
    }

    setStrokeColour(event) {
        this.strokeColour = event.target.value;
    }

    setFillColour(event) {
        this.fillColour = event.target.value;
    }

    undo() {
        if (this.newObjects.length > 0) {
            canvas.remove(this.newObjects.pop());
        }
        this.updateQuantity();
    }

    clearAll() {
        while (this.newObjects.length > 0) {
            canvas.remove(this.newObjects.pop());
            canvas.remove(this.tempObjects.pop());
        }
        this.updateQuantity();
    }

    panTool(event) {
        if(event != null) {this.unselectAllButtons(event.currentTarget);}
        this.currentMode = Mode.Pan;
        image.set('hoverCursor', 'grab');
    }

    lineTool(event) {
        if(event != null) {this.unselectAllButtons(event.currentTarget);}
        this.currentMode = Mode.Line;
        image.set('hoverCursor', 'crosshair');
    }

    pointTool(event) {
        if(event != null) {this.unselectAllButtons(event.currentTarget);}
        this.currentMode = Mode.Point;
        image.set('hoverCursor', 'crosshair');
    }

    rectangleTool(event) {
        if(event != null) {this.unselectAllButtons(event.currentTarget);}
        this.currentMode = Mode.Rectangle;
        image.set('hoverCursor', 'crosshair');
    }

    polygonTool(event) {
        if(event != null) {this.unselectAllButtons(event.currentTarget);}
        this.currentMode = Mode.Polygon
        image.set('hoverCursor', 'crosshair');
    }

    toggleExclusionMode(event) {
        this.exclusionMode = !this.exclusionMode;
        this.toggleButton(event.currentTarget, this.exclusionMode);
    }

    scaleWizard() {
        if(!confirm("Draw a known distance")) { return;}

        this.newObjects.forEach(obj =>{
            canvas.remove(obj);
        });

        this.currentMode = Mode.Scale;
        image.set('hoverCursor', 'crosshair');
    }

    getStrokeColour() {
        if (this.exclusionMode) { return this.exclusionStrokeColour; }
        return this.strokeColour;
    }

    getFillColour() {
        if (this.exclusionMode) {return this.exclusionFillColour }
        if (this.unit == 'm2') {
            return this.fillColour;
        } else {
            return null;
        }
    }

    unselectAllButtons(el) {
        this.toolButtonTargets.forEach(el =>{
            el.classList.remove(this.selectedButtonClass);
            el.classList.add(this.buttonClass);
        });

        this.selectButton(el);
    }

    selectButton(el) {
        el.classList.remove(this.buttonClass);
        el.classList.add(this.selectedButtonClass);
    }

    toggleButton(el, selected) {
        if (selected) {
            el.classList.remove(this.buttonClass);
            el.classList.add(this.selectedButtonClass);
        }
        else {
            el.classList.remove(this.selectedButtonClass);
            el.classList.add(this.buttonClass);
        }
    }

    calcScaleLength(obj) {
        return Math.sqrt(Math.pow(obj.x2 - obj.x1, 2) + Math.pow(obj.y2 - obj.y1, 2));
    }

    calcLength(obj) {
        let distance = 0;
        switch(obj.type) {
            case 'line':
                return Math.sqrt(Math.pow(obj.x2 - obj.x1, 2) + Math.pow(obj.y2 - obj.y1, 2)) * this.scale / 1000;
                break;
            case 'rect':
                return ((obj.width * 2) + (obj.height * 2)) * this.scale / 1000;
                break;
            case 'polyline':
                for (let i=0; i < obj.points.length - 1; i++) {
                    let p1 = obj.points[i];
                    let p2 = obj.points[i + 1];
                    distance += Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)) * this.scale / 1000;
                }
                return distance;
                break;
            default:
        }
    }

    calcArea(obj) {
        switch(obj.type) {
            case 'rect':
                let height_m = obj.width * this.scale / 1000;
                let width_m = obj.height * this.scale / 1000;
                return (height_m * width_m)
                break;
            case 'polyline':
                let area = 0.0;

                let j = 1;
                for (let i = 0; i < obj.points.length; i++, j++) {
                    j = j % obj.points.length;
                    area += ((obj.points[j].x - obj.points[i].x) * this.scale /1000 )*((obj.points[j].y + obj.points[i].y) * this.scale /1000 );
                }
                return area / 2.0;
                break;
            default:
        }
    }

    removeNewObjects() {
        this.newObjects.forEach(obj =>{
            canvas.remove(obj);
        });
    }

    updateQuantity(setDirty = true) {
        if(setDirty) { this.dirty = true;}
        if (!this.takeoff) {return;} // if in scale mode

        while (this.textObjects.length > 0) {
            canvas.remove(this.textObjects.pop());
        }

       let totalQuantity = 0;
       this.newObjects.forEach(obj => {
           let objQuantity = 0;

           switch (this.unit) {
               case 'lm':
                   objQuantity = Math.abs(this.calcLength(obj));
                   break;
               case 'm2':
                   objQuantity = Math.abs(this.calcArea(obj));
                   break;
               case 'ea':
                   objQuantity = 1;
                   break;
               default:
                   break;
           }
           totalQuantity += objQuantity * obj.operator;

           this.quantityTarget.innerText = totalQuantity.toFixed(3);
           this.quantity = totalQuantity.toFixed(3);


           if (this.unit == 'ea') {
               return;
           }

           let text = new fabric.Text(`${(objQuantity * obj.operator).toFixed(3).toString() + ' ' + this.unit}`, {
               fontSize: 10,
               fill: obj.operator == -1 ? 'white' : 'black',
               originX: 'center',
               originY: 'center',
               backgroundColor: obj.operator == -1 ? 'red' : 'white',
               borderColor: 'red',
               hasBorders: false,
               selectable: false,
               hoverCursor: 'crosshair'
           });

           let bg = new fabric.Rect({
               width : 55,
               height : 20,
               fill: obj.operator == -1 ? 'red' : 'white',
               stroke: obj.operator == -1 ? 'white' : obj.stroke,
               opacity: 1,
               strokeWidth: 1,
               selectable: false,
               originX: 'center',
               originY: 'center'
           });

           var group = new fabric.Group([ bg, text ], {
               left: obj.type == 'rect' ? (obj.left + obj.width / 2)  - 25 : obj.left - 25,
               top: obj.type == 'rect' ? (obj.top + obj.height / 2) - 10 : obj.top - 10,
               selectable: false,
               hoverCursor: 'crosshair'
       });
           canvas.add(group);
           this.textObjects.push(group);
        });

        this.quantityTarget.innerText = totalQuantity.toFixed(3);
        this.quantity = totalQuantity.toFixed(3);

    }
}

function addEnlivenedObjectsToCanvas (enlivenedObjects, canvas, newObjects) {
    enlivenedObjects[0].selectable = false;
    enlivenedObjects[0].hoverCursor = 'default';
    canvas.add(enlivenedObjects[0]);
    newObjects.push(enlivenedObjects[0]);
    canvas.renderAll();
}

function loadImage(img, canvasSizer, planRotation ) {
    canvas.add(img);
    image = img;

    image.set('selectable', false);
    image.set('hoverCursor', 'default');

    image.scaleToWidth(canvasSizer.clientWidth);
    image.rotate(planRotation);

    canvas.setDimensions({ width: image.getScaledWidth(), height: image.getScaledHeight() });
    canvasSizer.style.width = image.getScaledWidth()+"px";
    canvasSizer.style.height = image.getScaledHeight()+"px";
}