import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {suppliers: Array};
    static targets = ["itemCode", "supplierId"]

    supplierItemCodeMap = null;

    connect() {
        this.supplierItemCodeMap = new Map(this.suppliersValue.map(([k, v]) => [k, v])); // map of supplier_id: item_code
    }

    getItemCode() {
        let itemCode = this.supplierItemCodeMap.get( parseInt( this.supplierIdTarget.value ) ) // read value from supplier text field (supplier_id)
        this.itemCodeTarget.value = itemCode == undefined? '' : itemCode
    }
}