import { Controller } from "@hotwired/stimulus"

// data-controller="select-show"
// data-select-show-element-ids-value="Day, Week" this will look for elements with id="Day" and id="Week" and have them hide/show when the selectTarget value is the same.

export default class extends Controller {
    static targets = ["select"];
    static values = {
        elementIds: String
    }
    // read in a a string of comma seperated values which correspond to the id of elements we want to show/hide and also the value in a select input
    showValues = this.elementIdsValue.split(',').map(i=>i.trim());
    elements = new Map();


    connect() {
        for (let i = 0; i < this.showValues.length; i++) {
            this.elements.set(this.showValues[i], document.getElementById(this.showValues[i]));
        }

        this.toggleHide();
    }

    toggleHide() {
        this.elements.forEach((el,key) =>{
            if (key == this.selectTarget.value) {
                el.classList.remove('hidden');
            } else {
                el.classList.add('hidden');
            }

        })
    }
}
