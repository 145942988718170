<template>
  <tr class="draggable-item">
      <td class="td flex items-center space-x-2">
        <div class="flex-shrink-0 text-gray-300 draggable-handle">
          <svg class="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
        </div>
        <div class="rounded-full flex items-center flex-shrink-0 justify-center h-7 w-7 my-2 bg-brand-dark text-white">
          <span class="font-semibold text-sm">{{ document.uploaded_by_initials }}</span>
        </div>
        <a :id="document.id" style="scroll-margin: 125px;" :href="document.file_path" target="_blank" class="flex space-x-1 items-center">
          {{ document.file_name }}
          <span v-show="showingAll" class="text-gray-500 ml-2">{{ document.current ? 'Current' : `Superceded - V${document.version}` }}</span>
        </a>
      </td>
    <td class="td">{{ document.created_at }}</td>
    <td class="td flex items-center space-x-2">
      <popper :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
        <div class="bg-white px-4 py-2 shadow-lg rounded-full">
          <p>{{ document.show_client ? 'Shown to Client' : 'Hidden from Client' }}</p>
        </div>
        <div slot="reference" :class="shownClientClass">
          <svg v-if="document.show_client" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
          <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
        </div>
      </popper>
      <popper :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
        <div class="bg-white px-4 py-2 shadow-lg rounded-full">
          <p>{{ document.review_status }}</p>
        </div>
        <div slot="reference" :class="reviewStatusClass">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>
        </div>
      </popper>
      <popper :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
        <div class="bg-white px-4 py-2 shadow-lg rounded-full">
          <p>{{ signingStatus }}</p>
        </div>
        <div slot="reference" :class="signStatusClass">
          <svg class="w-6 h-6" fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.404 2.998c-.757-.754-2.077-.751-2.828.005l-1.784 1.791L11.586 7H7a.998.998 0 0 0-.939.658l-4 11c-.133.365-.042.774.232 1.049l2 2a.997.997 0 0 0 1.049.232l11-4A.998.998 0 0 0 17 17v-4.586l2.207-2.207v-.001h.001L21 8.409c.378-.378.586-.881.585-1.415 0-.535-.209-1.038-.588-1.415l-2.593-2.581zm-3.111 8.295A.996.996 0 0 0 15 12v4.3l-9.249 3.363 4.671-4.671c.026.001.052.008.078.008A1.5 1.5 0 1 0 9 13.5c0 .026.007.052.008.078l-4.671 4.671L7.7 9H12c.266 0 .52-.105.707-.293L14.5 6.914 17.086 9.5l-1.793 1.793zm3.206-3.208-2.586-2.586 1.079-1.084 2.593 2.581-1.086 1.089z"></path></svg>
        </div>
      </popper>
    </td>
    <td class="td">
      <popper :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
        <div class="bg-white px-4 py-2 shadow-lg rounded space-y-1 border">
          <p v-for="(log, index) in signingUsers" :key="index">
            {{ log  }}
          </p>
          <hr/>
          <p v-for="(log, index) in signingEvents" :key="index">{{ log }}</p>
        </div>
        <div slot="reference">{{ signingStatus }} </div>
      </popper>
    </td>
    <td class="td">
        <div v-show="userPermissions.canEdit" v-click-outside="clickOutsideMenu" class="relative inline-block text-left">
        <div>
          <button @click="open = !open" type="button" class="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 p-0.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-dark" id="menu-button" aria-expanded="true" aria-haspopup="true">
            <span class="sr-only">Open options</span>
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
            </button>
        </div>
        <div v-show="open" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 divide-y divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div v-if="currentDocument" class="py-1" role="none">
            <a href="#" @click.prevent="openReviewModal(document.id)" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Send Review Request</a>
            <a v-show="canApprove" @click.prevent="reviewDocument(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Approve Review</a>
            <a v-show="document.reviewed_at" @click.prevent="cancelReview(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Cancel Review</a>
          </div>
          <div class="py-1">
            <a v-if="document.show_client" @click.prevent="hideDocument(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark">Hide From Client</a>
            <a v-else @click.prevent="showDocument(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark">Show To Client</a>
          </div>

          <div v-if="signableFileType && currentDocument && document.requires_signing" class="py-1" role="none">
            <a @click.prevent="openSigningModal(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Send For Signing</a>
            <p v-if="resendLoading" class="text-white bg-gray-700 block px-4 py-2 text-sm">Resending...</p>
            <p v-else-if="resendCompleted" class="text-white bg-green-500 block px-4 py-2 text-sm">Resend Completed</p>
            <a @click.prevent="resendSigning(document.id)" v-else-if="document.status === 'Sent For Signing'" id="resend-signing" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Re-send For Signing</a>
            <a @click.prevent="openRetractModal(document.id)" v-show="document.status === 'Sent For Signing'" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Withdraw Signing</a>
            <a v-if="document.status === 'Manually Signed'" @click.prevent="openManuallySigningModal(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Undo Manual Signing</a>
            <a v-else @click.prevent="openManuallySigningModal(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark" role="menuitem" tabindex="-1">Sign Manually</a>
          </div>
          <div v-else-if="currentDocument && !signableFileType" class="py-1 bg-gray-100">
            <p class="text-gray-500 block text-sm px-4 py-2">Cannot Sign This File Type</p>
          </div>

          <a v-if="document.requires_signing" @click.prevent="disableSigning(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark bg-red-50">Disable Signing</a>
          <a v-else @click.prevent="enableSigning(document.id)" href="#" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark bg-green-50">Enable Signing</a>

          <div class="py-1" v-if="!document.current && !document.multiple_category">
            <a href="#" @click.prevent="makeActive(document.id)" class="text-gray-700 block px-4 py-2 text-sm hover:text-brand-dark">Make Active Document</a>
          </div>
          <div class="py-1 bg-gray-50 text-center text-red-500 hover:text-white hover:bg-red-500" role="none">
            <a href="#" @click.prevent="confirmDelete" class='block px-4 py-2 text-sm' role="menuitem" tabindex="-1">Delete</a>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Popper from 'vue-popperjs';
import moment from "moment";

export default {
  props: ['document', 'toggleVersions', 'hasVersions', 'showingAll'],
  components: {
    'popper': Popper
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    ...mapState([
      'userId',
      'userRole',
      'resendLoading',
      'resendCompleted',
      'userPermissions'
    ]),
    signingStatus() {
      let status = this.document.status;

      switch(status) {
        case 'Sent For Signing':
          return `Sent ${this.document.docusign_sent}`;
        case 'Fully Signed':
          return `Completed / Signed ${this.document.docusign_signed}`;
        case 'Revoked':
          return `Revoked ${this.document.docusign_voided}`;
        case 'Declined':
          return `Declined ${this.document.docusign_voided}`;
        case 'Manually Signed':
          return `Manually Signed ${this.document.docusign_signed}`;
        case 'Draft':
          if(this.document.requires_signing)
            return `To Be Reviewed`;
          else
            return 'Not Required'
      }
    },
    signStatusClass() {
      if (this.document.requires_signing && this.document.docusign_signed) {
        return 'text-green-500';
      } else if (this.document.requires_signing) {
        return 'text-yellow-500';
      } else {
        return 'text-gray-300';
      }
    },
    shownClientClass() {
      if (this.document.show_client) {
        return 'text-green-500';
      } else {
        return 'text-gray-300';
      }
    },
    signingEvents() {
      return this.document.docusign.events;
    },
    signingUsers() {
      return Object.keys(this.document.docusign.signed).map(key => {
        return `${this.document.docusign.signed[key].name} updated status to ${this.document.docusign.signed[key].status} on ${this.document.docusign.signed[key].date}`
      });
    },
    signableFileType() {
      const fileTypes = ['.docx', '.pdf', '.png', '.doc', '.txt', '.rtf'];
      return fileTypes.some(ft => {
        return this.document.file_name.includes(ft);
      });
    },
    currentDocument() {
      return this.document.current || this.document.multiple_category;
    },
    canApprove() {
      return (this.userId == this.document.reviewer_id || this.userRole === 'admin') &&
        !this.document.reviewed_at;
    },
    reviewStatusText() {
      if (this.document.review_request_date) {
        return `Reviewed by ${this.document.reviewed_date} by ${this.document.reviewed_by}`;
      } else if (this.document.review_request_date) {
        return 'Review Required';
      } else {
        return 'No review required';
      }
    },
    reviewStatusClass() {
      if (this.document.reviewed_date) {
        return 'text-green-500';
      } else if (this.document.review_request_date) {
        return 'text-yellow-500';
      } else {
        return 'text-gray-300';
      }
    }
  },
  methods: {
    ...mapMutations([
      'openReviewModal',
      'openSigningModal',
      'openRetractModal',
      'openManuallySigningModal'
    ]),
    ...mapActions([
      'deleteDocument',
      'approveDocument',
      'unapproveDocument',
      'reviewDocument',
      'cancelReview',
      'resendSigning',
      'makeActive',
      'hideDocument',
      'showDocument',
      'enableSigning',
      'disableSigning'
    ]),
    clickOutsideMenu(e) {
      if (e.target.id !== 'resend-signing') {
        this.open = false;
      }
    },
    confirmDelete() {
      if (confirm('Are you sure you want to delete this document? This cannot be undone.')) {
        this.deleteDocument(this.document.id);
      }
    }
  }
}
</script>