var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-1" },
    [
      _c(
        "vue-dropzone",
        {
          staticClass: "client-document-dropzone",
          attrs: {
            id: "doc-dropzone-" + _vm.categoryType + "-" + _vm.id,
            options: _vm.dropzoneOptions,
            duplicateCheck: true,
            useCustomSlot: true
          }
        },
        [
          _c("div", { staticClass: "dropzone-custom-content" }, [
            _c("div", { staticClass: "drag-text" }, [
              _c("h4", { staticClass: "text-gray-300 text-left pl-4" }, [
                _vm._v("Drag and Drop Documents Or Click Here")
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "d-flex flex-column align-items-center mb-1 w-100",
        attrs: { id: "documents-preview" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }