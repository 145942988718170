var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4 rounded" }, [
    _c(
      "div",
      { staticClass: "rounded-t py-1 flex justify-between items-center" },
      [
        _c("h3", { staticClass: "text-xl text-gray-800 font-bold" }, [
          _vm._v(_vm._s(_vm.category.name))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "space-y-2" },
      [
        _vm._l(_vm.category.sub_categories, function(subCat) {
          return _c("SubCategory", {
            key: subCat.id,
            attrs: { "sub-category": subCat }
          })
        }),
        _vm._v(" "),
        _vm.category.documents.length > 0
          ? _c("DocumentTable", {
              attrs: { documents: _vm.category.documents }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.category.documents.length > 1,
                expression: "category.documents.length > 1"
              }
            ]
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "previous-versions text-gray-700 text-small my-2 flex space-x-1 items-center hover:text-blue-500 transition duration-150",
                attrs: {
                  href: "#",
                  "data-target": "category-documents-<%= doc_cat.id %>"
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "h-4 w-4",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("View Previous Versions")])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "current-version hidden text-gray-700 text-small my-2 flex space-x-1 items-center hover:text-blue-500 transition duration-150",
                attrs: {
                  href: "#",
                  "data-target": "category-documents-<%= doc_cat.id %>"
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "h-4 w-4",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M18 12H6"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Hide Previous Versions")])
              ]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }