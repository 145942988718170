import axios from 'axios';

document.addEventListener('turbo:load', function() {
  const opportunityBookingsPage = document.getElementById('opportunity-bookings');

  if (opportunityBookingsPage) {
    const templateSelect = document.getElementById('schedule-template-nomination');
    const opportunityData = document.getElementById('opportunity-id');

    templateSelect.addEventListener('change', e => {
      if (confirm('Are you sure you want to apply this template? This will remove all current bookings that have a status of Pending and no start or end date.')) {
        const templateId = e.target.value;
        const opportunityId = opportunityData.dataset.opportunityId;
        const url = `/staff/opportunities/${opportunityId}/bookings/apply_template.json?template_id=${templateId}`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };

        axios({ method: 'POST', url, data: {}, headers }).then(res => {
          window.location = `/staff/opportunities/${opportunityId}/bookings`;
        });
      } else {
        templateSelect.value = '';
      }
    });
  }
});
