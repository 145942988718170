import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "collapsible", "button" ]

    toggle(e) {
        this.collapsibleTarget.classList.toggle('hidden')
        //from_merge this.buttonTarget.innerText = this.collapsibleTarget.classList.contains('hidden')? '+' : '-'
        e.target.innerText = this.collapsibleTarget.classList.contains('hidden')? '+' : '-'
    }

    expand(e) {
        this.collapsibleTarget.classList.remove('hidden')
        this.buttonTarget.innerText = '-'
    }
}