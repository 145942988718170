import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

const URL = "/shared/messages/:id/mark_as_read"

export default class extends Controller {
    static targets = ["message"]
    unseenElements = [...this.messageTargets];

    connect() {
        //check if message is on screen before scroll
        this.onScroll();
    }

    onScroll(e) {
        for (let i = 0; i < this.unseenElements.length; i++) {
            let  rect = this.unseenElements[i].getBoundingClientRect();

            if ( (rect.top - 250 >= 0) ) {
                let temp_id = this.unseenElements[i].id;
                this.unseenElements.splice(i, 1);
                setTimeout(()=>{
                    this.destroy_user_unread_message_record(temp_id);
                    },2000);
            }
        }
    }

    async destroy_user_unread_message_record(id) {
        const request = new FetchRequest("DELETE", URL.replace(':id', id),{responseKind: "turbo-stream"});
        const response = await request.perform();
        if (response.ok) {
            setTimeout(() => this.loading = false, 200)
        }
    }
}
