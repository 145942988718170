<template>
  <transition enter-active-class="duration-300" leave-active-class="duration-200">
    <div v-show="retractModalOpen" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="retractModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </transition>
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div v-show="retractModalOpen" class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <div slot="reference" class="text-green-500">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>
                  </div>
                </div>
                <div class="mt-3 sm:mt-5">
                  <div class="text-center">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Retract the DocuSign Signature Requirements</h3>
                    <div class="mt-2">
                      <p class="text-gray-500">Void the sent document's signature requirement and signatures.</p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label class="label">Retraction Reason</label>
                    <textarea :value="retractText" @input="e => updateRetractText(e.target.value)" class="input full sm:text-sm sm:leading-5" rows="3" placeholder="Why is this document being retracted for signing?"></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button v-if="retractLoading" disabled type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-light text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Sending...</button>
                <button v-else-if="retractCompleted" disabled type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Retraction Confirmed</button>
                <button v-else @click="retractEnvelope" :disabled="!canRetract" :class="canRetract ? 'text-white bg-brand-dark' : 'text-gray-500 bg-gray-300 cursor-not-allowed'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 font-medium text-white hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:col-start-2 sm:text-sm">Retract Signing Request</button>
                <button @click="closeRetractModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState([
      'retractModalOpen',
      'retractLoading',
      'retractCompleted',
      'retractText'
    ]),
    canRetract() {
      return this.retractText.length > 0;
    }
  },
  methods: {
    ...mapMutations([
      'closeRetractModal',
      'updateRetractText'
    ]),
    ...mapActions([
      'retractEnvelope'
    ])
  }
}
</script>
