// this controller attaches to the container where you grab the inventory_items from.
// end event is run when you drop into the ingredient_inventory_items container

import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js"

const URL = "/staff/ingredient_inventory_items" // ingredient_inventory_items_controller#create

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            sort: false,
            animation: 150,
            handle: '.item-handle',
            group: {
                name: 'shared',
                pull: 'clone',
                put: false
            }
        })
    }

    end(event) {
        let ingredientInventoryItemContainer = event.item.closest('.ingredient-inventory-item-container'); // we get the part_id from the container we are dropping the inv_item into
        if(ingredientInventoryItemContainer === null) return;

        let id = event.item.dataset.id;
        event.item.id = "new_ingredient_inventory_item"; // we change this so we can target it with a turbo_stream


        let data = new FormData();
        data.append('ingredient_inventory_item[position]', event.newIndex + 1);
        data.append('ingredient_inventory_item[ingredient_id]', ingredientInventoryItemContainer.dataset.id);
        data.append('ingredient_inventory_item[inventory_item_id]', id)

        post(URL, {body: data, responseKind: 'turbo-stream'});
    }

}