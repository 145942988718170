var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex space-x-4" }, [
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Job Stage Name")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
          attrs: { type: "text", placeholder: "Stage name" },
          domProps: { value: _vm.name },
          on: {
            change: function($event) {
              _vm.name = $event.target.value
              _vm.saveStage()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _c("label", { staticClass: "font-semibold text-gray-800 block" }, [
          _vm._v("For Workflow Stage")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select w-full",
            on: {
              change: function($event) {
                _vm.workflow_stage_id = $event.target.value
                _vm.saveStage()
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("All Stages")]),
            _vm._v(" "),
            _vm._l(_vm.workflow_stages, function(stage) {
              return _c(
                "option",
                {
                  key: stage.id,
                  domProps: {
                    value: stage.id,
                    selected: stage.id == _vm.workflow_stage_id
                  }
                },
                [_vm._v(_vm._s(stage.name))]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between mt-4" }, [
      _c("h3", { staticClass: "font-bold text-xl text-gray-800" }, [
        _vm._v("Funnel Tasks")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addTask($event)
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-6 h-6 mr-1",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          ),
          _vm._v("\n      Add New Funnel Task\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "draggable",
          {
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
                _vm.saveStage()
              }
            },
            model: {
              value: _vm.tasks,
              callback: function($$v) {
                _vm.tasks = $$v
              },
              expression: "tasks"
            }
          },
          _vm._l(_vm.tasks, function(task) {
            return _c(
              "div",
              { key: "task-" + task.id },
              [
                task.editing
                  ? _c(
                      "div",
                      { staticClass: "bg-gray-200 rounded px-5 py-3 mt-4" },
                      [
                        _c("div", { staticClass: "flex-col" }, [
                          _c("div", { staticClass: "flex items-center" }, [
                            _c(
                              "div",
                              {
                                staticClass: "flex-1 mr-4 flex flex-col gap-y-2"
                              },
                              [
                                _c("div", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "font-semibold text-gray-800"
                                    },
                                    [_vm._v("Task Name")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: task.name,
                                        expression: "task.name"
                                      }
                                    ],
                                    staticClass:
                                      "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Task name"
                                    },
                                    domProps: { value: task.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          task,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex flex-col" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "font-semibold text-gray-800"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Hide From Users\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: task.hide_from_users,
                                          expression: "task.hide_from_users"
                                        }
                                      ],
                                      staticClass:
                                        "focus:ring-indigo-500 text-indigo-600 border-gray-300 rounded",
                                      attrs: { multiple: "" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            task,
                                            "hide_from_users",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "nil" } },
                                        [_vm._v("None")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "sales" } },
                                        [_vm._v("Sales")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "project_manager" } },
                                        [_vm._v("Project Manager")]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex flex-col flex-1 gap-y-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-semibold text-gray-800"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: task.show_result_in_header,
                                          expression:
                                            "task.show_result_in_header"
                                        }
                                      ],
                                      staticClass:
                                        "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          task.show_result_in_header
                                        )
                                          ? _vm._i(
                                              task.show_result_in_header,
                                              null
                                            ) > -1
                                          : task.show_result_in_header
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = task.show_result_in_header,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  task,
                                                  "show_result_in_header",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  task,
                                                  "show_result_in_header",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              task,
                                              "show_result_in_header",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Show Result in Header\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-semibold text-gray-800"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: task.include_in_report,
                                          expression: "task.include_in_report"
                                        }
                                      ],
                                      staticClass:
                                        "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          task.include_in_report
                                        )
                                          ? _vm._i(
                                              task.include_in_report,
                                              null
                                            ) > -1
                                          : task.include_in_report
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = task.include_in_report,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  task,
                                                  "include_in_report",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  task,
                                                  "include_in_report",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              task,
                                              "include_in_report",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Show In Report\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-semibold text-gray-800"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: task.show_in_scheduler,
                                          expression: "task.show_in_scheduler"
                                        }
                                      ],
                                      staticClass:
                                        "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          task.show_in_scheduler
                                        )
                                          ? _vm._i(
                                              task.show_in_scheduler,
                                              null
                                            ) > -1
                                          : task.show_in_scheduler
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = task.show_in_scheduler,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  task,
                                                  "show_in_scheduler",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  task,
                                                  "show_in_scheduler",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              task,
                                              "show_in_scheduler",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  Show In Scheduler\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-auto mt-4" }, [
                            _c(
                              "label",
                              { staticClass: "font-semibold text-gray-800" },
                              [_vm._v("Task Description")]
                            ),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: task.description,
                                  expression: "task.description"
                                }
                              ],
                              staticClass:
                                "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                              attrs: { cols: "30", rows: "4" },
                              domProps: { value: task.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    task,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "font-semibold text-gray-800 block"
                            },
                            [_vm._v("Client Learning Centre")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  task.learning_centre_id = $event.target.value
                                  _vm.fetchLearningData(
                                    "learning_centres",
                                    task.learning_centre_id
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Learning Centre")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.learning_centres, function(lc) {
                                return _c(
                                  "option",
                                  {
                                    key: lc.id,
                                    domProps: {
                                      value: lc.id,
                                      selected: lc.id == task.learning_centre_id
                                    }
                                  },
                                  [_vm._v(_vm._s(lc.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "font-semibold text-gray-800 block"
                            },
                            [_vm._v("Client Learning Category")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  task.learning_category_id =
                                    $event.target.value
                                  _vm.fetchLearningData(
                                    "learning_categories",
                                    task.learning_category_id
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Learning Category")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.learning_categories, function(cat) {
                                return _c(
                                  "option",
                                  {
                                    key: cat.id,
                                    domProps: {
                                      value: cat.id,
                                      selected:
                                        cat.id == task.learning_category_id
                                    }
                                  },
                                  [_vm._v(_vm._s(cat.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "font-semibold text-gray-800 block"
                            },
                            [_vm._v("Client Learning Step")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  task.learning_step_id = $event.target.value
                                  _vm.fetchLearningData(
                                    "learning_steps",
                                    task.learning_step_id
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Learning Step")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.learning_steps, function(cat) {
                                return _c(
                                  "option",
                                  {
                                    key: cat.id,
                                    domProps: {
                                      value: cat.id,
                                      selected: cat.id == task.learning_step_id
                                    }
                                  },
                                  [_vm._v(_vm._s(cat.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "font-semibold text-gray-800 block"
                            },
                            [_vm._v("Client Learning Topics")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  task.learning_topic_id = $event.target.value
                                  _vm.loadLearningSubTopic(task)
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Nominate Learning Topic")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.learning_topics, function(cat) {
                                return _c(
                                  "option",
                                  {
                                    key: cat.id,
                                    domProps: {
                                      value: cat.id,
                                      selected: cat.id == task.learning_topic_id
                                    }
                                  },
                                  [_vm._v(_vm._s(cat.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "label",
                            {
                              staticClass: "font-semibold text-gray-800 block"
                            },
                            [_vm._v("Client Learning Sub Topics")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "form-select w-full",
                              on: {
                                change: function($event) {
                                  task.learning_sub_topic_id =
                                    $event.target.value
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Nominate Learning Sub Topic")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.learning_sub_topics, function(cat) {
                                return _c(
                                  "option",
                                  {
                                    key: cat.id,
                                    domProps: {
                                      value: cat.id,
                                      selected:
                                        cat.id == task.learning_sub_topic_id
                                    }
                                  },
                                  [_vm._v(_vm._s(cat.name))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                task.editing = false
                                _vm.saveStage()
                              }
                            }
                          },
                          [_vm._v("\n            Save Task\n          ")]
                        )
                      ]
                    )
                  : (_vm.showKept && !task.discarded_at) || !_vm.showKept
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between",
                        class: task.discarded_at ? "bg-red-100" : ""
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "w-4 h-4 mr-2 text-gray-600",
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d:
                                    "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "font-bold text-gray-800 mr-6" },
                            [_vm._v(_vm._s(task.code + " " + task.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-blue-500 inline-flex items-center",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addSubTask(task)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "w-4 h-4 mr-1",
                                  attrs: {
                                    fill: "none",
                                    stroke: "currentColor",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n              Add Sub-Task\n            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-blue-500 mr-4",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.loadTaskData(task)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "w-4 h-4",
                                  attrs: {
                                    fill: "none",
                                    stroke: "currentColor",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d:
                                        "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          task.discarded_at
                            ? _c(
                                "a",
                                {
                                  staticClass: "text-blue-500",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.undiscardTask(task.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "text-red-500",
                                  attrs: {
                                    href: "#",
                                    "data-confirm":
                                      "Are you sure you want to archive this task and all sub-tasks?"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.discardTask(task.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4",
                                      attrs: {
                                        fill: "none",
                                        stroke: "currentColor",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    on: {
                      start: function($event) {
                        _vm.drag = true
                      },
                      end: function($event) {
                        _vm.drag = false
                        _vm.saveStage()
                      }
                    },
                    model: {
                      value: task.sub_tasks,
                      callback: function($$v) {
                        _vm.$set(task, "sub_tasks", $$v)
                      },
                      expression: "task.sub_tasks"
                    }
                  },
                  _vm._l(task.sub_tasks, function(subTask) {
                    return _c(
                      "div",
                      {
                        key: "subtask-" + subTask.id,
                        staticClass: "ml-8 mt-2"
                      },
                      [
                        subTask.editing
                          ? _c(
                              "div",
                              { staticClass: "bg-gray-300 rounded px-5 py-3" },
                              [
                                _c("div", { staticClass: "flex-col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-col flex-1 gap-y-2 mr-4"
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-semibold text-gray-800"
                                              },
                                              [_vm._v("Sub Task Name")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: subTask.name,
                                                  expression: "subTask.name"
                                                }
                                              ],
                                              staticClass:
                                                "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                              attrs: {
                                                type: "text",
                                                placeholder: "Task name"
                                              },
                                              domProps: { value: subTask.name },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    subTask,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex flex-col" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-semibold text-gray-800"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Hide From Users\n                      "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        subTask.hide_from_users,
                                                      expression:
                                                        "subTask.hide_from_users"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "focus:ring-indigo-500 text-indigo-600 border-gray-300 rounded",
                                                  attrs: { multiple: "" },
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        subTask,
                                                        "hide_from_users",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    { attrs: { value: "nil" } },
                                                    [_vm._v("None")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "sales" }
                                                    },
                                                    [_vm._v("Sales")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "project_manager"
                                                      }
                                                    },
                                                    [_vm._v("Project Manager")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-col flex-1 gap-y-2"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "font-semibold text-gray-800"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      subTask.show_result_in_header,
                                                    expression:
                                                      "subTask.show_result_in_header"
                                                  }
                                                ],
                                                staticClass:
                                                  "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    subTask.show_result_in_header
                                                  )
                                                    ? _vm._i(
                                                        subTask.show_result_in_header,
                                                        null
                                                      ) > -1
                                                    : subTask.show_result_in_header
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        subTask.show_result_in_header,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "show_result_in_header",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "show_result_in_header",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        subTask,
                                                        "show_result_in_header",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n                      Show Result in Header\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "font-semibold text-gray-800"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      subTask.include_in_report,
                                                    expression:
                                                      "subTask.include_in_report"
                                                  }
                                                ],
                                                staticClass:
                                                  "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    subTask.include_in_report
                                                  )
                                                    ? _vm._i(
                                                        subTask.include_in_report,
                                                        null
                                                      ) > -1
                                                    : subTask.include_in_report
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        subTask.include_in_report,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "include_in_report",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "include_in_report",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        subTask,
                                                        "include_in_report",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n                      Include In Report\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "font-semibold text-gray-800"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      subTask.show_in_scheduler,
                                                    expression:
                                                      "subTask.show_in_scheduler"
                                                  }
                                                ],
                                                staticClass:
                                                  "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    subTask.show_in_scheduler
                                                  )
                                                    ? _vm._i(
                                                        subTask.show_in_scheduler,
                                                        null
                                                      ) > -1
                                                    : subTask.show_in_scheduler
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        subTask.show_in_scheduler,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "show_in_scheduler",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            subTask,
                                                            "show_in_scheduler",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        subTask,
                                                        "show_in_scheduler",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n                      Show In Scheduler\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "flex-auto mt-4" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-semibold text-gray-800"
                                      },
                                      [_vm._v("Task Description")]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: subTask.description,
                                          expression: "subTask.description"
                                        }
                                      ],
                                      staticClass:
                                        "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                                      attrs: { cols: "30", rows: "4" },
                                      domProps: { value: subTask.description },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            subTask,
                                            "description",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-800 block"
                                    },
                                    [_vm._v("Client Learning Centre")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-select w-full",
                                      on: {
                                        change: function($event) {
                                          subTask.learning_centre_id =
                                            $event.target.value
                                          _vm.fetchLearningData(
                                            "learning_centres",
                                            subTask.learning_centre_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select Learning Centre")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.learning_centres, function(
                                        lc
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: lc.id,
                                            domProps: {
                                              value: lc.id,
                                              selected:
                                                lc.id ==
                                                subTask.learning_centre_id
                                            }
                                          },
                                          [_vm._v(_vm._s(lc.name))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-800 block"
                                    },
                                    [_vm._v("Client Learning Category")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-select w-full",
                                      on: {
                                        change: function($event) {
                                          subTask.learning_category_id =
                                            $event.target.value
                                          _vm.fetchLearningData(
                                            "learning_categories",
                                            subTask.learning_category_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select Learning Category")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.learning_categories, function(
                                        cat
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: cat.id,
                                            domProps: {
                                              value: cat.id,
                                              selected:
                                                cat.id ==
                                                subTask.learning_category_id
                                            }
                                          },
                                          [_vm._v(_vm._s(cat.name))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-800 block"
                                    },
                                    [_vm._v("Client Learning Step")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-select w-full",
                                      on: {
                                        change: function($event) {
                                          subTask.learning_step_id =
                                            $event.target.value
                                          _vm.fetchLearningData(
                                            "learning_steps",
                                            subTask.learning_step_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select Learning Step")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.learning_steps, function(cat) {
                                        return _c(
                                          "option",
                                          {
                                            key: cat.id,
                                            domProps: {
                                              value: cat.id,
                                              selected:
                                                cat.id ==
                                                subTask.learning_step_id
                                            }
                                          },
                                          [_vm._v(_vm._s(cat.name))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-800 block"
                                    },
                                    [_vm._v("Client Learning Topics")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-select w-full",
                                      on: {
                                        change: function($event) {
                                          subTask.learning_topic_id =
                                            $event.target.value
                                          _vm.loadLearningSubTopic(subTask)
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Nominate Learning Topic")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.learning_topics, function(
                                        cat
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: cat.id,
                                            domProps: {
                                              value: cat.id,
                                              selected:
                                                cat.id ==
                                                subTask.learning_topic_id
                                            }
                                          },
                                          [_vm._v(_vm._s(cat.name))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-semibold text-gray-800 block"
                                    },
                                    [_vm._v("Client Learning Sub Topics")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-select w-full",
                                      on: {
                                        change: function($event) {
                                          subTask.learning_sub_topic_id =
                                            $event.target.value
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Nominate Learning Sub Topic")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.learning_sub_topics, function(
                                        cat
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: cat.id,
                                            domProps: {
                                              value: cat.id,
                                              selected:
                                                cat.id ==
                                                subTask.learning_sub_topic_id
                                            }
                                          },
                                          [_vm._v(_vm._s(cat.name))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        subTask.editing = false
                                        _vm.saveStage()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Save Sub-Task\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : (_vm.showKept && !subTask.discarded_at) ||
                            !_vm.showKept
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-gray-200 rounded px-5 py-3 mt-3 flex items-center justify-between",
                                class: subTask.discarded_at ? "bg-red-100" : ""
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "w-4 h-4 mr-2 text-gray-600",
                                        attrs: {
                                          fill: "none",
                                          stroke: "currentColor",
                                          viewBox: "0 0 24 24",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2",
                                            d: "M5 13l4 4L19 7"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "font-bold text-gray-800 mr-6"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            subTask.code + " " + subTask.name
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-blue-500 mr-4",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.loadTaskData(subTask)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "w-4 h-4",
                                            attrs: {
                                              fill: "none",
                                              stroke: "currentColor",
                                              viewBox: "0 0 24 24",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d:
                                                  "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    subTask.discarded_at
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-blue-500",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.undiscardSubTask(
                                                  task.id,
                                                  subTask.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "w-4 h-4",
                                                attrs: {
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  viewBox: "0 0 24 24",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "text-red-500",
                                            attrs: {
                                              href: "#",
                                              "data-confirm":
                                                "Are you sure you want to archive this sub-task?"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.discardSubTask(
                                                  task.id,
                                                  subTask.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "w-4 h-4",
                                                attrs: {
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  viewBox: "0 0 24 24",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex items-center justify-center mt-8 text-blue-500"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showKept = !_vm.showKept
                  }
                }
              },
              [_vm._v("Toggle Archived Tasks and Sub-Tasks")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }