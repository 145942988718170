import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
            animation: 150
        })
    }

    async end(event) {
        let url = event.item.dataset.url
        let data = new FormData();
        data.append('position', event.newIndex + 1);

        const request = new FetchRequest("patch", url,{responseKind: "turbo-stream", body: data});
        const response = await request.perform();
    }
}