var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.clickAway,
          expression: "clickAway"
        }
      ],
      staticClass: "relative ml-4"
    },
    [
      _c("input", {
        staticClass: "border border-gray-200 rounded leading-5 px-4 py-2",
        attrs: {
          type: "text",
          name: "nav-search",
          id: "nav-search",
          placeholder: "Search for clients or projects"
        },
        domProps: { value: _vm.search },
        on: { click: _vm.clickIn, input: _vm.triggerSearch }
      }),
      _vm._v(" "),
      _vm.showResults
        ? _c(
            "div",
            {
              staticClass:
                "bg-white shadow-lg absolute rounded-b-lg b-0 l-0 w-full p-4 divide-y divide-gray-100"
            },
            _vm._l(_vm.results, function(result) {
              return _c(
                "a",
                {
                  key: result.id,
                  staticClass:
                    "text-sm block py-1 my-1 px-2 text-brand-dark hover:bg-brand-dark hover:text-white rounded-lg",
                  attrs: { href: "/staff/opportunities/" + result.id }
                },
                [
                  _c("h4", { staticClass: "font-semibold" }, [
                    _vm._v(
                      _vm._s(result.address || "No Address") +
                        " - " +
                        _vm._s(result.name)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-xs text-gray-500" }, [
                    _vm._v(_vm._s(result.workflow_stage))
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }