document.addEventListener('turbo:load', () => {
  const uploadForm = document.getElementById('user_document_form');
  const uploadInput = document.getElementById('user_document_document');

  if (uploadForm && uploadInput) {
    uploadInput.addEventListener('change', () => {
      console.log('changed!');
      uploadForm.submit();
    });
  }
});