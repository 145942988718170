import axios from 'axios';

document.addEventListener('turbo:load', function() {
  const selectionRoomEdit = document.getElementById('selection-room-edit');
  const videoSeenButton = document.getElementById('selection-watched');
  const watchSelectionVideo = document.getElementById('watch-selection-video');

  if (selectionRoomEdit) {
    const opportunityId = selectionRoomEdit.dataset.id;

    function updateSelection(selectionId, roomId) {
      const url = `/staff/projects/${opportunityId}/rooms/${roomId}/selections`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };

      axios.patch(url, { selection_id: selectionId }, { headers });
    }

    function updateOption(selectionId, roomId, optionId) {
      const url = `/staff/projects/${opportunityId}/rooms/${roomId}/options`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };

      axios.patch(url, { selection_id: selectionId, option_id: optionId }, { headers });
    }

    document.getElementsByClassName('room-selection').forEach(element => {
      element.addEventListener('change', (event) => {
        event.preventDefault();
        const selectionId = event.target.dataset.id;
        const roomId = event.target.dataset.roomId;

        updateSelection(selectionId, roomId);
      });
    });

    document.getElementsByClassName('room-selection-option').forEach(element => {
      element.addEventListener('change', (event) => {
        event.preventDefault();
        const selectionId = event.target.dataset.id;
        const roomId = event.target.dataset.roomId;
        const optionId = event.target.dataset.optionId;

        updateOption(selectionId, roomId, optionId);
      });
    });
  }
  if(videoSeenButton) {
    videoSeenButton.addEventListener('click', function () {
      const url = `/client_progress/${this.dataset.id}`;
      const csrf = document.getElementsByName('csrf-token')[0].content;
      const headers = { 'x-csrf-token': csrf };

      axios.patch(url, { type: 'videos_seen', value: { selections: true } }, { headers }).then(resp => {
        if (resp.data.success) {
          const selectionModal = document.getElementById('selection-video-modal');
          selectionModal.classList.add('hidden');

          const selectionsVideo = document.getElementById('selections-video');
          selectionsVideo.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
        }
      });
    });
  }
  if(watchSelectionVideo) {
    watchSelectionVideo.addEventListener('click', function (e) {
      e.preventDefault();
      const selectionModal = document.getElementById('selection-video-modal');
      selectionModal.classList.remove('hidden');
    })
  }
});
