import Vue from 'vue'
import App from './client_schedule/app.vue'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', () => {
  const calendar = document.getElementById('client-schedule-calendar');

  if (calendar) {
    new Vue({
      el: '#client-schedule-calendar',
      render: h => h(App)
    }).$mount();
  }
});