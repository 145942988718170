import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "collapsible", "button" ]

    toggle(e) {
        this.collapsibleTarget.classList.toggle('hidden')
    }

    expand(e) {
        this.collapsibleTarget.classList.remove('hidden')
    }
}