var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.document_categories, function(dc) {
      return _c(
        "div",
        { key: "category-" + dc.id },
        [
          _c("div", { staticClass: "flex items-center justify-between mt-4" }, [
            _c("span", { staticClass: "flex items-center" }, [
              _c(
                "h2",
                { staticClass: "font-bold text-2xl text-gray-800 mr-2" },
                [_vm._v(_vm._s(dc.name))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text-gray-500 text-sm" }, [
                _vm._v(_vm._s(dc.visibility) + " default sharing")
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#", "data-behaviour": "uppy-trigger" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleUppy(dc.id)
                  }
                }
              },
              [_vm._v("Upload to section")]
            )
          ]),
          _vm._v(" "),
          _vm.uppyOpen && _vm.activeCategoryId === dc.id
            ? _c("uppy-dashboard", {
                attrs: {
                  uppy: _vm.uppy,
                  props: {
                    proudlyDisplayPoweredByUppy: false,
                    width: "100%",
                    height: 300
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-5 gap-4 mt-4" },
            _vm._l(dc.documents, function(doc) {
              return _c("document", {
                key: "document-" + doc.id,
                attrs: {
                  document: doc,
                  document_type: dc.document_type,
                  updateDocument: _vm.updateDocument
                }
              })
            }),
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }