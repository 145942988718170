// Add to form: action:'turbo:submit-start->submitting#submit'}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        text: {type: String, default: 'Converting...'}
    }
    connect() {
    }

    submit(e) {
        e.detail.formSubmission.submitter.value = this.textValue;
    }
}