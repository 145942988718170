<template>
  <div v-show="isNotRestricted"  class="bg-white pt-2">
    <div class="flex justify-between items-center">
      <div class="flex items-center justify-between  w-full px-6">
        <h3 :class="[isNotRequired ? ['text-xl', 'font-semibold', 'text-brand-dark'] : ['text-xl', 'font-semibold', 'text-brand-dark', 'line-through']]">{{ subCategory.name }}</h3>
        <DocumentDropzone v-show="isNotRequired && userPermissions.canEdit" :id="subCategory.id" :category-type="'sub'" :requires-signing="subCategory.requires_signing"></DocumentDropzone>

        <div class="flex items-center mt-6" v-show="userPermissions.canEdit">
          <button type="button"
                  @click.prevent="toggleNotRequired(subCategory.id)"
                  :class="[isNotRequired ? 'bg-brand-dark' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
            <span aria-hidden="true" :class="[isNotRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </button>
          <span class="flex flex-col ml-3 text-lg" id="annual-billing-label">
            <span :class="[isNotRequired ? 'text-lg' : 'text-gray-400']">Is Required?</span>
          </span>
        </div>

      </div>
    </div>
    <div class="my-2" id="sub-category-documents-<%= sub_cat.id %>">
      <draggable v-show="subCategory.documents.length === 0" :list="[]" group="documents" @change="handleDrop">
      </draggable>
      <DocumentTable :documents="subCategory.documents" :sub-category-id="subCategory.id" :show-all="showAll"></DocumentTable>
      <div v-show="!subCategory.allow_multiple_documents && subCategory.documents.length > 1" class="flex justify-center py-1">
        <a v-if="showAll" @click.prevent="showAll = !showAll" class="text-brand-dark flex space-x-1 items-center">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
          <span>Hide Previous Versions</span>
        </a>
        <a v-else @click.prevent="showAll = !showAll" class="text-brand-dark flex space-x-1 items-center">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
          <span>Show Previous Versions</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import DocumentTable from "./DocumentTable.vue";
import DocumentDropzone from "./DocumentDropzone.vue";
import draggable from 'vuedraggable';
import {mapActions, mapState} from 'vuex';

export default {
  props: ["subCategory"],
  components: { DocumentTable, DocumentDropzone, draggable },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    ...mapState([
        'notRequiredSubCats',
        'userPermissions',
        'restrictedSubCategories'
    ]),
    categoryIds() {
      return {
        categoryId: this.subCategory.client_document_category_id,
        subCategoryId: this.subCategory.id
      }
    },

    isNotRequired() {
      return !this.notRequiredSubCats.includes(this.subCategory.id)
    },
    isNotRestricted() {
      return !this.restrictedSubCategories.includes(this.subCategory.id)
    }
  },
  methods: {
    ...mapActions([
      'dropDocument',
      'moveDocument',
      'toggleNotRequired'
    ]),
    handleDrop(e) {
      if (e.added) {
        this.dropDocument({
          documentId: e.added.element.id,
          categoryIds: this.categoryIds,
          index: e.added.newIndex
        });
      } else {
        this.moveDocument({
          documentId: e.removed.element.id,
          categoryIds: this.categoryIds
        });
      }
    }
  }
}
</script>