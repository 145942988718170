<template>
  <div class="mb-4 rounded">
    <div class="rounded-t py-1 flex justify-between items-center">
      <h3 class="text-xl text-gray-800 font-bold">{{ category.name }}</h3>
    </div>
    <div class="space-y-2">
      <SubCategory
        v-for="subCat in category.sub_categories"
        :key="subCat.id"
        :sub-category="subCat"
      ></SubCategory>
      <!-- Allow for single or multiple document allowance for this category -->
      <DocumentTable
        v-if="category.documents.length > 0"
        :documents="category.documents"
      ></DocumentTable>
      <!-- Allow for previous documents here -->
      <div v-show="category.documents.length > 1">
        <a href="#" data-target='category-documents-<%= doc_cat.id %>' class="previous-versions text-gray-700 text-small my-2 flex space-x-1 items-center hover:text-blue-500 transition duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          <span>View Previous Versions</span>
        </a>
        <a href="#" data-target='category-documents-<%= doc_cat.id %>' class="current-version hidden text-gray-700 text-small my-2 flex space-x-1 items-center hover:text-blue-500 transition duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6"/>
          </svg>
          <span>Hide Previous Versions</span>
        </a>
      </div>
      <!-- Allow for document Dropzone (or make heading / category container the dropzone itself?) -->
      <!-- <DocumentDropzone></DocumentDropzone> -->
    </div>
  </div>

</template>
<script>
import SubCategory from "./SubCategory.vue";
import DocumentTable from "./DocumentTable.vue";
import DocumentDropzone from "./DocumentDropzone.vue";

export default {
    props: ["category"],
    components: { SubCategory, DocumentTable, DocumentDropzone }
}
</script>