var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.alert,
            expression: "alert"
          }
        ],
        staticClass: "rounded-md bg-green-50 p-4 mb-4"
      },
      [
        _c("div", { staticClass: "flex justify-center" }, [
          _c("div", { staticClass: "flex-shrink-0" }, [
            _c(
              "svg",
              {
                staticClass: "h-5 w-5 text-green-400",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 20 20",
                  fill: "currentColor"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d:
                      "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                    "clip-rule": "evenodd"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-3" }, [
            _c("div", { staticClass: "text-sm leading-5 text-green-700" }, [
              _c("p", [_vm._v(_vm._s(_vm.alert))])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex justify-between items-center mt-4 mb-2" }, [
      _c("h4", { staticClass: "font-bold text-lg text-gray-800" }, [
        _vm._v("Project Learning Templates")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addCentre($event)
            }
          }
        },
        [_vm._v("Add Learning Template")]
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "draggable",
          {
            staticClass: "flex flex-col space-y-2",
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
              }
            },
            model: {
              value: _vm.learning_centres,
              callback: function($$v) {
                _vm.learning_centres = $$v
              },
              expression: "learning_centres"
            }
          },
          _vm._l(_vm.learning_centres, function(centre, index) {
            return _c(
              "div",
              {
                key: centre.id,
                staticClass:
                  "p-3 bg-gray-50 rounded flex flex-col space-y-4 border border-gray-200 selection-option",
                class: [centre._destroy ? "bg-red-100" : ""]
              },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center space-x-4 space-between" },
                  [
                    _c("div", { staticClass: "flex-1" }, [
                      _c("label", { staticClass: "font-semibold" }, [
                        _vm._v("Learning Template")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: centre.learning_centre_id,
                              expression: "centre.learning_centre_id"
                            }
                          ],
                          staticClass:
                            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal form-select",
                          attrs: { required: "true" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                centre,
                                "learning_centre_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("Nominate the learning template")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.all_learning_centres, function(
                            learningCentre
                          ) {
                            return _c(
                              "option",
                              {
                                key: learningCentre.id,
                                domProps: { value: learningCentre.id }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(learningCentre.name) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "text-red-500 cursor-pointer",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleDelete(index)
                          }
                        }
                      },
                      [
                        centre._destroy
                          ? _c(
                              "svg",
                              {
                                staticClass: "h-4 h-4",
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d:
                                      "M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                                  }
                                })
                              ]
                            )
                          : _c(
                              "svg",
                              {
                                staticClass: "w-4 h-4",
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d:
                                      "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  }
                                })
                              ]
                            )
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4 flex justify-between" }, [
      _c(
        "a",
        {
          staticClass: "text-blue-500",
          attrs: {
            href: "/staff/opportunities/" + this.id + "/client/learning_centres"
          }
        },
        [_vm._v("\n      Back to Client Management\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "bg-brand-dark hover:bg-brand-light text-white font-bold py-2 px-4 rounded cursor-pointer",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.saveTemplates($event)
            }
          }
        },
        [_vm._v("\n      Update Learning Templates\n    ")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-gray-50 text-center p-4" }, [
      _c("p", [
        _vm._v(
          "If the list below is empty, this project is using the default learning templates for the current workflow stage"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }