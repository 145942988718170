var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.documents.length > 0,
          expression: "documents.length > 0"
        }
      ],
      staticClass: "shadow-xl"
    },
    [
      _c(
        "table",
        { staticClass: "table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "divide-y divide-gray-200",
              attrs: {
                tag: "tbody",
                list: _vm.shownDocs,
                group: "documents",
                handle: ".draggable-handle"
              },
              on: { change: _vm.handleDrop }
            },
            _vm._l(_vm.shownDocs, function(document) {
              return _c("Document", {
                key: document.id,
                attrs: {
                  document: document,
                  toggleVersions: _vm.toggleVersions,
                  "showing-all": _vm.showAll
                }
              })
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "th w-1/3" }, [_vm._v("Document Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "th w-1/6" }, [_vm._v("Date Uploaded")]),
        _vm._v(" "),
        _c("th", { staticClass: "th w-1/6" }, [_vm._v("Internal Status")]),
        _vm._v(" "),
        _c("th", { staticClass: "th w-1/6" }, [_vm._v("Signing Status")]),
        _vm._v(" "),
        _c("th", { staticClass: "th w-1/6" }, [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }