<template>
  <div>
    <tippy :to="`subtask-trigger-${subTask.id}`">
      <div class="flex space-x-2">
        <h4 class="font-semibold">Actioned By:</h4>
        <p>{{ subTask.actioned_by || 'NA' }}</p>
      </div>
      <div class="flex space-x-2">
        <h4 class="font-semibold">Completed By:</h4>
        <p>{{ subTask.completed_by || 'NA' }}</p>
      </div>
    </tippy>
    <div class="p-2 bg-white ml-6 mt-2 rounded shadow-xl hover:shadow transition-shadow duration-100 ease-in-out hover:bg-blue-100 hover:text-gray-100">
      <div class="flex items-center justify-between">
        <div class="flex items-center w-full mr-2">
          <span :name="`subtask-trigger-${subTask.id}`" class="w-6 h-6 mr-2 flex cursor-pointer" v-html="subTaskIcon()" @click="updateSubTaskStatus(stageId, taskIndex, subTaskIndex)"></span>
          <h4 class="text-gray-700 w-1/4">{{ subTask.name }}</h4>
          <span class="ml-4 flex items-center gap-1 w-2/4" :class="subTask.result ? 'text-gray-600' : 'text-gray-400'">
            {{ subTask.result ? (subTask.result.length > 33 ? subTask.result.slice(0, 36) + '...' : subTask.result) : 'No Result Recorded Yet' }}
            <a href="#" @click.prevent="subTask.resultExpanded = !subTask.resultExpanded;">
              <svg v-if="!subTask.resultExpanded" class="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
            </a>
          </span>
          <span v-if="subTask.completed_at" class="text-gray-400 text-smtext-right">Completed {{ subTask.completed_at }}</span>

<!--      REMOVED AS REQUESTED JB 09.12.22
          <span v-else-if="subTask.status === 'in_progress'" class="flex items-center text-right">
            <span class="mr-2 text-sm">Target Date </span>
            <input type="date" :value="subTask.target_date" class="text-sm" @change.prevent="updateSubTask(stageId, taskIndex, subTaskIndex, 'target_date', $event.target.value)">
          </span>
-->
          <span v-else class="w-1/4"></span>
        </div>
        <div class="flex items-center flex-shrink-0 justify-end space-x-2">
          <!-- <document-upload :task="subTask" :task-index="taskIndex" :sub-task-index="subTaskIndex" :add-document="addDocument" :stage-id="stageId"></document-upload> -->
          <div class="flex items-center flex-shrink-0" v-if="subTask.description" @click="subTask.showDescription = !subTask.showDescription">
            <svg class="w-6 h-6 text-blue-500 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>
        </div>
        <div class="flex items-center ml-2 tooltip" @click.prevent="copyURLToClipboard">
          <span ref="copyURLTooltip" class="tooltiptext text-xs" style="min-width: 100px; margin-left: -50px; padding: 4px;">copy link</span>
          <svg class="w-6 h-6 text-green-500 cursor-pointer" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" /></svg>
        </div>
      </div>
    </div>
    <div v-if="subTask.resultExpanded" class="p-3 bg-gray-200 text-gray-800 rounded-bl rounded-br">
      <textarea autofocus="true" rows="4" v-model="subTask.result" class="w-full py-2 px-4" placeholder="Sub-task result"></textarea>
      <div class="flex justify-end space-x-4">
        <svg class="w-5 h-5 text-brand-dark cursor-pointer" @click="updateSubTask(stageId, taskIndex, subTaskIndex, 'result', subTask.result)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
        <svg class="w-5 h-5 text-red-400" @click="updateSubTask(stageId, taskIndex, subTaskIndex, 'resultExpanded', false)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </div>
    </div>
    <div v-if="subTask.showDescription" class="p-3 ml-6 bg-gray-700 text-white rounded-bl rounded-br">
      {{ subTask.description }}
    </div>
  </div>
</template>
<script>
import document from './document.vue';
import documentUpload from './document-upload.vue';

export default {
  components: { document, documentUpload },
  props: ['subTask', 'subTaskIndex', 'updateSubTaskStatus', 'stageId', 'taskIndex', 'updateSubTask', 'addDocument', 'updateDocuments','taskId','opportunityId'],
  methods: {
    copyURLToClipboard: function() {
      let url = `https://app.theshedhouse.com.au/client/${this.opportunityId}/process?client_stage=${this.stageId}&client_task=${this.taskId}&client_sub_task=${this.subTask.id}`;

      navigator.clipboard.writeText(url);
      this.$refs.copyURLTooltip.textContent = "link copied!";
      this.$refs.copyURLTooltip.style.backgroundColor = "#3b82f6";

      setTimeout(()=>{
        this.$refs.copyURLTooltip.textContent = "copy link";
        this.$refs.copyURLTooltip.style.backgroundColor = "#555555";
      }, 1000);
    },
    subTaskIcon: function() {
      switch (this.subTask.status) {
        case 'completed':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        case 'in_progress':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'not_applicable':
          return '<svg class="bg-gray-200 text-gray-500 w-6 h-6 rounded-xl flex items-center justify-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    }
  }
}
</script>