import SlimSelect from 'slim-select/dist/slimselect.es.js';
import { Controller } from "@hotwired/stimulus";
import 'slim-select/dist/slimselect.css';

// Connects to data-controller="slimselect"
export default class extends Controller {
    static values = {
        options: Object
    };

    connect() {
        this.slimselect = new SlimSelect({
            select: this.element,
            ...this.optionsValue
        });
    }

    disconnect() {
        this.slimselect.destroy();
    }
}