var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex space-x-4" }, [
      _c("div", { staticClass: "flex-auto" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("Category Name")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
          attrs: { type: "text", placeholder: "Document category name" },
          domProps: { value: _vm.name },
          on: {
            change: function($event) {
              _vm.name = $event.target.value
              _vm.saveCategory()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("\n        Requires Signing\n      ")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select",
            domProps: { value: _vm.requires_signing },
            on: {
              change: function($event) {
                _vm.requires_signing = $event.target.value
                _vm.saveCategory()
              }
            }
          },
          [
            _c("option", { domProps: { value: false } }, [_vm._v("No")]),
            _vm._v(" "),
            _c("option", { domProps: { value: true } }, [_vm._v("Yes")])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("\n        Allowed Documents\n      ")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select",
            domProps: { value: _vm.allow_multiple_documents },
            on: {
              change: function($event) {
                _vm.allow_multiple_documents = $event.target.value
                _vm.saveCategory()
              }
            }
          },
          [
            _c("option", { domProps: { value: false } }, [_vm._v("Single")]),
            _vm._v(" "),
            _c("option", { domProps: { value: true } }, [_vm._v("Multiple")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex pt-2" },
      [
        _c("label", { staticClass: "font-semibold text-gray-800" }, [
          _vm._v("\n      Disable Visibility For:\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.roles, function(role) {
          return _c("div", [
            _c("div", { staticClass: "flex flex-col pl-5" }, [
              _c("label", { staticClass: "font-semibold text-gray-800" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restricted_roles,
                      expression: "restricted_roles"
                    }
                  ],
                  staticClass:
                    "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: role.value,
                    checked: Array.isArray(_vm.restricted_roles)
                      ? _vm._i(_vm.restricted_roles, role.value) > -1
                      : _vm.restricted_roles
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.restricted_roles,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = role.value,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.restricted_roles = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.restricted_roles = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.restricted_roles = $$c
                        }
                      },
                      function($event) {
                        return _vm.saveCategory()
                      }
                    ]
                  }
                }),
                _vm._v("\n          " + _vm._s(role.key) + "\n        ")
              ])
            ])
          ])
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between mt-4" }, [
      _c("h3", { staticClass: "font-bold text-xl text-gray-800" }, [
        _vm._v("Sub-Categories")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addSubCategory($event)
            }
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "w-6 h-6 mr-1",
              attrs: {
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                }
              })
            ]
          ),
          _vm._v("\n      Add New Document Sub-Category\n    ")
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "draggable",
          {
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
                _vm.saveCategory()
              }
            },
            model: {
              value: _vm.subCategories,
              callback: function($$v) {
                _vm.subCategories = $$v
              },
              expression: "subCategories"
            }
          },
          _vm._l(_vm.subCategories, function(subCategory) {
            return _c("div", { key: "sub-category-" + subCategory.id }, [
              subCategory.editing
                ? _c(
                    "div",
                    { staticClass: "bg-gray-200 rounded px-5 py-3 mt-4" },
                    [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", { staticClass: "flex-1 flex space-x-2" }, [
                          _c("div", { staticClass: "flex-1" }, [
                            _c(
                              "label",
                              { staticClass: "font-semibold text-gray-800" },
                              [_vm._v("Sub-Category Name")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: subCategory.name,
                                  expression: "subCategory.name"
                                }
                              ],
                              staticClass:
                                "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal",
                              attrs: {
                                type: "text",
                                placeholder: "Sub-category name"
                              },
                              domProps: { value: subCategory.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    subCategory,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex flex-1 flex-col" }, [
                            _c(
                              "label",
                              { staticClass: "font-semibold text-gray-800" },
                              [
                                _vm._v(
                                  "\n                  Requires Signing\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: subCategory.requires_signing,
                                    expression: "subCategory.requires_signing"
                                  }
                                ],
                                staticClass: "form-select",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      subCategory,
                                      "requires_signing",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v("No")
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v("Yes")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex flex-1 flex-col" }, [
                            _c(
                              "label",
                              { staticClass: "font-semibold text-gray-800" },
                              [
                                _vm._v(
                                  "\n                  Allowed Documents\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: subCategory.allow_multiple_documents,
                                    expression:
                                      "subCategory.allow_multiple_documents"
                                  }
                                ],
                                staticClass: "form-select",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      subCategory,
                                      "allow_multiple_documents",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v("Single")
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v("Multiple")
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex pt-2" },
                        [
                          _c(
                            "label",
                            { staticClass: "font-semibold text-gray-800" },
                            [
                              _vm._v(
                                "\n              Disable Visibility For:\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.roles, function(role) {
                            return _c("div", [
                              _c("div", { staticClass: "flex flex-col pl-5" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-semibold text-gray-800"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: subCategory.restricted_roles,
                                          expression:
                                            "subCategory.restricted_roles"
                                        }
                                      ],
                                      staticClass:
                                        "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        value: role.value,
                                        checked: Array.isArray(
                                          subCategory.restricted_roles
                                        )
                                          ? _vm._i(
                                              subCategory.restricted_roles,
                                              role.value
                                            ) > -1
                                          : subCategory.restricted_roles
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              subCategory.restricted_roles,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = role.value,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  subCategory,
                                                  "restricted_roles",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  subCategory,
                                                  "restricted_roles",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              subCategory,
                                              "restricted_roles",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(role.key) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "inline-flex items-center justify-center mt-2 px-3 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand-dark hover:bg-brand-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              subCategory.editing = false
                              _vm.saveCategory()
                            }
                          }
                        },
                        [_vm._v("\n            Save Sub-Category\n          ")]
                      )
                    ]
                  )
                : (_vm.showKept && !subCategory.discarded_at) || !_vm.showKept
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bg-gray-100 rounded px-5 py-3 mt-3 flex items-center justify-between",
                      class: subCategory.discarded_at ? "bg-red-100" : ""
                    },
                    [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "w-4 h-4 mr-2 text-gray-600",
                            attrs: {
                              fill: "none",
                              stroke: "currentColor",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d:
                                  "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h4",
                          { staticClass: "font-bold text-gray-800 mr-6" },
                          [_vm._v(_vm._s(subCategory.name))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex items-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-blue-500 mr-4",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                subCategory.editing = true
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "w-4 h-4",
                                attrs: {
                                  fill: "none",
                                  stroke: "currentColor",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d:
                                      "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        subCategory.discarded_at
                          ? _c(
                              "a",
                              {
                                staticClass: "text-blue-500",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.undiscardSubCategory(
                                      subCategory.id
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "w-4 h-4",
                                    attrs: {
                                      fill: "none",
                                      stroke: "currentColor",
                                      viewBox: "0 0 24 24",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        d:
                                          "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "text-red-500",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.discardSubCategory(
                                      subCategory.id
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "w-4 h-4",
                                    attrs: {
                                      fill: "none",
                                      stroke: "currentColor",
                                      viewBox: "0 0 24 24",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        d:
                                          "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex items-center justify-center mt-8 text-blue-500"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showKept = !_vm.showKept
                  }
                }
              },
              [_vm._v("Toggle Archived Sub-Categories")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }